import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import { Pin } from '@groovehq/internal-design-system/lib/assets/icons'
import { usePinnedSearches } from 'ducks/pinnedSearch/hooks'
import { selectAccountCanSeePinnedSearches } from 'selectors/app'
import { useSelector } from 'react-redux'
import { styles } from './styles'
import PinnedSearchItems from '../Item/PinnedSearchItems'

const PinnedSearchesView = () => {
  const { shouldShowStarredSearches, onPinClick, isOpen } = usePinnedSearches()
  if (!shouldShowStarredSearches) return null

  return (
    <Menu.UnmanagedItem
      icon={<Pin className="grui mr-3" />}
      css={styles.pinnedItem}
      className="grui pl-4"
      onClick={onPinClick}
      value="menu-starred"
      open={isOpen}
      subMenu={isOpen && <PinnedSearchItems />}
    >
      Pinned
    </Menu.UnmanagedItem>
  )
}

const PinnedSearchesItem = props => {
  const canSeePinnedSearches = useSelector(selectAccountCanSeePinnedSearches)
  if (!canSeePinnedSearches) return null

  return <PinnedSearchesView {...props} />
}

export default PinnedSearchesItem
