import { useSelector } from 'react-redux'

import { selectIsTwitterTicket } from 'selectors/tickets/type'

import {
  selectIsEditorPinned,
  selectIsEditorVisible,
} from 'ducks/editor/selectors'

import Selector from './Selector'
import FooterForm from './Form'

import styles from './styles.less'

const Footer = ({ className, ticketId }) => {
  const isTwitter = useSelector(selectIsTwitterTicket)
  const editorVisible = useSelector(selectIsEditorVisible)
  const editorPinned = useSelector(selectIsEditorPinned)

  if (isTwitter) return null

  return (
    <>
      {!editorVisible ? (
        <Selector />
      ) : (
        <FooterForm className={className} ticketId={ticketId} />
      )}
      {!editorPinned && <div className={styles.BottomSpacer} />}
    </>
  )
}

export default Footer
