module.exports = {
  appUrl: 'https://mobile.groovehq.docker/',
  widgetUrl: process.env.WIDGET_URL || 'https://widget.groovehq.docker',
  appDomain: 'groovehq.docker',
  kbProxyDomain: 'hosting.groovehq.docker',
  production_api_url: 'https://api.groovehq.com',
  api_url: 'https://api.groovehq.docker',
  graphql_url: 'https://graphql.groovehq.docker/graphql',
  app_graphql_url: 'https://api.groovehq.docker/v2/graphql',
  production_report_url: 'https://graphql.groovehq.com/reports',
  report_url: 'https://graphql.groovehq.docker/reports',
  embeddable_cards_url:
    'https://graphql.groovehq.docker/embeddable_cards/proxy',
  realtime_url: 'realtime.groovehq.docker',
  s3_url: 's3.docker',
  vapidPublicKey:
    'BA0gV6U4JNOvkWkkNF7PsMOL7KrXSVACnxXK26K6iPrQ9YLZRRWiu4lfWzN089fQp1-84hCPhJtnnCjZpfOCty0',
  stripePublicKey: 'pk_test_W2m1TzZq858q0esR0oFeMl0V',
  filestackKey: 'AbnxTnzehQ4KY9hgqQ767z',
  filestackRegion: 'us-east-1',
  filestackBucket: 'groove-filestack-development',
  showReports: true,
  showSettings: true,
  isDevelopment: true,
  api_concurrency: 4,
  gtm_segment: 'Application',
  gtm_version: 'development',
  gtm_containerid: 'GTM-MQ94GV3',
  gtm_auth: 'fG3FotkaRedMYw-ladIp9w',
  gtm_preview: 'env-5',
  google_recaptcha_site_key: '6LfozH0UAAAAALAA2yP-YIJLwLQl6_egriLC7y1K',
  integrationsApiUrl: 'https://integrations.groovehq.docker',
  integrationsApiConcurrency: 4,
  githubAppName: 'groovehq-spence-development',
  chatServerUrl: 'https://chat-server.groovehq.docker',
  facebookMessengerUrl: 'https://business.facebook.com/latest/inbox/all',
  chatIdentityServerUrl: 'https://vector.im',
  chatFileSizeLimits: {
    // in bytes
    matrix: 25 * 1024 * 1024, // 25 MB
    'g.bridge.messenger.facebook': 25 * 1024 * 1024, // 25 MB
    'g.bridge.messenger.instagram': 25 * 1024 * 1024, // 25 MB
  },
  emojiWidth: 280,
  emojiHeight: 396,
  uploaderUrl: 'https://uploader.groovehq.docker',
  trialDays: 90,
  trialShowNoticeBannerDaysLeft: 3,
  sygnalServerUrl: 'http://sygnal.default.svc.cluster.local',
  disableSW: process.env.OFFLINE_RUNTIME === 'off',
  oauthClientId:
    '7e38fbaba365a6754e7b1484922446ad882194a6c69959eeb913a3322c51114b',
  frillWidgets: {
    ideas: {
      key: 'f3249687-32c0-4697-a247-7029260a0535',
    },
    announcements: {
      key: 'ec26475a-3dd8-4012-9106-d1295b6b8719',
    },
    roadmap: {
      key: '4d0a5ca0-2f67-453c-985a-d54e0682d5b4',
    },
  },
  showFrillAnnoucements: true,
  shopify: {
    graphQLApiVersion: '2024-01',
    restApiVersion: '2024-01',
    appStoreGrooveAppUrl: 'https://apps.shopify.com/support-software',
  },
  rechargeClientId:
    'b4ba2d6b12310f7db3c4d27919b7b8e98a87b61cd37f51aa98e9b66cc1bbc009',
  posthog: {
    enabled: false,
    apiKey: 'phc_K6yyzb1xan1e8yaOK9YDgu44kKKfaWTYZfBaUwyRvsy',
    apiHost: 'https://observe-cluster.production.groovehq.com',
  },
  statusPageIOPageId: '73nj5jhb3tk4',
  hotjar: {
    hjid: '3237486',
    hjsv: '7',
  },
}
