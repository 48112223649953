import React from 'react'

import { isCloseable } from 'ducks/tickets/utils/state'
import WithIconState from './WithIconState'
import NormalState from './NormalState'

const StateBadge = ({ variant, ...props }) => {
  switch (variant) {
    case 'withIcon':
      return <WithIconState {...props} closeable={isCloseable(props.state)} />
    default:
      return <NormalState {...props} />
  }
}

export default StateBadge
