import { times } from 'util/arrays'
import { useSelector } from 'react-redux'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import CollapsedChangesetPlaceholder from './CollapsedChangesetPlaceholder'
import ExpandedChangesetPlaceholder from './ExpandedChangesetPlaceholder'

import styles from '../../styles.css'

const TicketChangesetPlaceholder = ({ ticketId }) => {
  const ticket = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )
  const collapsedCount = ticket?.counts?.messages || 0
  const rows = []
  if (collapsedCount > 0) {
    times(collapsedCount, i =>
      rows.push(<CollapsedChangesetPlaceholder key={`tcp-${i}`} />)
    )
  }
  rows.push(<ExpandedChangesetPlaceholder key={`tcp-last`} />)

  return <div className={styles.TicketInspector}>{rows}</div>
}

export default TicketChangesetPlaceholder
