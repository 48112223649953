import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  BLUR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_HIGH,
  FOCUS_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_ENTER_COMMAND,
  INSERT_LINE_BREAK_COMMAND,
  LineBreakNode,
} from 'lexical'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  selectIsReloadingSubmittedSearchQuery,
  selectSearchMailboxIds,
} from 'selectors/search/searchFilters'
import {
  doClearSearch,
  doUpdateSearchByKey,
  doUpdateSearchMailboxIds,
} from 'actions/search'
import { selectCurrentMailbox } from 'ducks/mailboxes/selectors/selectCurrentMailbox'
import { selectCurrentUserPrefersSearchCurrentMailboxByDefault } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersSearchCurrentMailboxByDefault'
import { selectTicketSearchSuggestions } from 'selectors/search_suggestions'
import SearchSuggestions from 'components/SearchSuggestions'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { SVGIcon } from 'shared/ui'
import { filter as FilterIcon } from 'assets/icons/groove/v2'
import {
  selectIsOnSearchPage,
  selectIsRFRBeingStupid,
} from 'selectors/location'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { emptyArr } from 'util/arrays'
import { buildIdFromAny } from 'util/globalId'
import PlainTextEditor from './PlainTextEditor'
import { styles } from './styles'
import oldStyles from './styles.css'
import { $createFilterNode, $isFilterNode } from './PlainTextEditor/FilterNode'
import {
  getMatchedRecentSearchQueries,
  mergeRegister,
  updateEditorContentAfterSubmit,
  textNodeTransformAfterSelectFilter,
  exitCurrentEditorNode,
} from './util'
import MailboxSearchFilter from './MailboxSearchFilter'

const SearchWithSuggestions = ({
  isSearchBoxFocused,
  committedSearchQuery,
  onSearchBoxFocus,
  onSearchBoxBlur,
  onSubmit,
  children,
  className,
}) => {
  const dispatch = useDispatch()
  const [editor] = useLexicalComposerContext()
  const searchBoxRef = useRef(null)
  const [recentSearchQueryList, setRecentSearchesQueryList] = useState([])
  const [shouldShowAllSuggestions, setShouldShowAllSuggestion] = useState(false)
  const [isSuggestionActive, setIsSuggestionActive] = useState(false)
  const searchMailboxIds = useSelector(selectSearchMailboxIds)

  const prefersSearchCurrentMailboxByDefault = useSelector(
    selectCurrentUserPrefersSearchCurrentMailboxByDefault
  )
  const currentMailbox = useSelector(selectCurrentMailbox)
  const suggestions = useSelector(selectTicketSearchSuggestions)
  const currentQuery = useSelector(selectCurrentQuery)
  const currentSubmittedTicketSearchQueryObject = currentQuery
  const currentSearches = useMemo(
    () => {
      return currentQuery.search ? [currentQuery.search] : emptyArr
    },
    [currentQuery.search]
  )
  const isReloadingSubmittedSearchQuery = useSelector(
    selectIsReloadingSubmittedSearchQuery
  )
  const isTypedSearch = useSelector(selectIsOnSearchPage)
  const isDuplicatedSearchAction = useSelector(selectIsRFRBeingStupid)
  const isOnSearchPage = useSelector(selectIsOnSearchPage)
  const shouldShowAllSuggestionsButton = suggestions.narrow?.length > 0

  const showClearIcon =
    Object.keys(currentSubmittedTicketSearchQueryObject).length > 0 &&
    isTypedSearch
  const placeholder = `Search your ${app.t(
    searchMailboxIds.length ? 'mailboxes' : 'mailbox'
  )}…`

  const handleSelect = useCallback(
    (queryString, valueId) => {
      editor.update(
        () => {
          const selection = $getSelection()
          const selectionNodes = selection.getNodes()
          const currentNode = selectionNodes[0]
          const newFilterNode = $createFilterNode(queryString, valueId)

          if ($isParagraphNode(currentNode)) {
            // Can't replace the paragraph node / root node with a text node, so we append instead
            currentNode.append(newFilterNode)
          } else if ($isFilterNode(currentNode)) {
            currentNode.replace(newFilterNode)
          } else if (currentNode.getType() === 'text') {
            textNodeTransformAfterSelectFilter(
              currentNode,
              selection,
              newFilterNode
            )
          } else {
            const p = $createParagraphNode()
            p.append(newFilterNode)
            $getRoot().append(p)
          }

          const previousSibling = newFilterNode.getPreviousSibling()
          if (previousSibling && previousSibling.getTextContent() !== ' ') {
            newFilterNode.insertBefore($createTextNode(' '))
          }
          // Check if colon string is in the end and there're no other colon in the string
          // If it isn't, exit the filter
          if (queryString === '#') {
            newFilterNode.selectNext()
          } else if (
            queryString.split(':').length > 2 ||
            !queryString.endsWith(':')
          ) {
            newFilterNode.insertAfter($createTextNode(' '))
            newFilterNode.selectNext()
          }
        },
        {
          onUpdate: () => {
            // Check if search box is overflowing
            // If it is, scroll to the end
            if (
              searchBoxRef.current.scrollWidth >
              searchBoxRef.current.clientWidth
            ) {
              searchBoxRef.current.scrollLeft = searchBoxRef.current.scrollWidth
            }
          },
        }
      )
    },
    [editor]
  )

  const handleEnterKeyEvent = useCallback(
    e => {
      const selection = $getSelection()
      if (isSuggestionActive || !selection) return
      // If the selection is in the filter
      const selectionNodes = selection.getNodes()
      const currentNode = selectionNodes[0]
      if (selectionNodes.length === 1 && $isFilterNode(currentNode)) {
        // Prevent submitting the search
        e.stopPropagation()

        exitCurrentEditorNode(currentNode)
      }
    },
    [isSuggestionActive]
  )

  const handleSubmit = useCallback(
    (...args) => {
      const { search } = onSubmit(...args)
      updateEditorContentAfterSubmit(editor, search ? [search] : [])
    },
    [editor, onSubmit]
  )

  const handleClearInput = useCallback(
    e => {
      dispatch(doClearSearch(e, { onlyClearInput: true }))
    },
    [dispatch]
  )

  const handleEscape = useCallback(
    e => {
      dispatch(doClearSearch(e, { shouldBlur: true }))
    },
    [dispatch]
  )

  const handleUpdateSearchMailboxes = useCallback(
    (mailboxes, { shouldFocusSearchBox } = {}) => {
      dispatch(doUpdateSearchMailboxIds(mailboxes))
      if (shouldFocusSearchBox) {
        editor.focus()
      }
    },
    [dispatch, editor]
  )

  const clickShowAllSuggestions = useCallback(
    () => {
      setShouldShowAllSuggestion(previousState => {
        if (!isSearchBoxFocused) return true
        return !previousState
      })
      if (!isSearchBoxFocused) {
        editor.focus()
      }
    },
    [editor, isSearchBoxFocused]
  )

  const preventBlur = useCallback(e => {
    e.preventDefault()
  }, [])

  const handleStartSearch = useCallback(
    () => {
      editor.focus()
    },
    [editor]
  )

  const handleSuggestionsMouseDown = useCallback(e => {
    // Should allow to focus the time input
    if (e.target.tagName === 'INPUT') {
      return
    }
    e.preventDefault()
  }, [])

  useEffect(
    () => {
      // Only allow one line of text: prevent inserting line breaks
      return mergeRegister(
        editor.registerCommand(
          INSERT_LINE_BREAK_COMMAND,
          () => {
            return true
          },
          COMMAND_PRIORITY_HIGH
        ),
        editor.registerNodeTransform(LineBreakNode, node => {
          node.remove()
        })
      )
    },
    [editor]
  )

  useEffect(
    () => {
      return mergeRegister(
        editor.registerCommand(
          FOCUS_COMMAND,
          onSearchBoxFocus,
          COMMAND_PRIORITY_EDITOR
        ),
        editor.registerCommand(
          BLUR_COMMAND,
          onSearchBoxBlur,
          COMMAND_PRIORITY_EDITOR
        )
      )
    },
    [editor, onSearchBoxFocus, onSearchBoxBlur]
  )

  useEffect(
    () => {
      return editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        handleEscape,
        COMMAND_PRIORITY_LOW
      )
    },
    [editor, handleEscape]
  )

  useEffect(
    () => {
      return editor.registerCommand(
        KEY_ENTER_COMMAND,
        handleEnterKeyEvent,
        COMMAND_PRIORITY_HIGH
      )
    },
    [editor, handleEnterKeyEvent]
  )

  useEffect(
    () => {
      // Only read the local storage when the search suggestions are open
      if (isSearchBoxFocused) {
        setRecentSearchesQueryList(
          getMatchedRecentSearchQueries(committedSearchQuery)
        )
      }
    },
    [isSearchBoxFocused, committedSearchQuery]
  )

  useEffect(
    () => {
      if (!isSearchBoxFocused) {
        setShouldShowAllSuggestion(false)
      }
    },
    [isSearchBoxFocused]
  )

  useEffect(
    () => {
      searchBoxRef.current = editor.getRootElement()
      dispatch(doUpdateSearchByKey('editor', editor))
    },
    [editor, dispatch]
  )

  useEffect(
    () => {
      // If the user refreshes the page, we want to update the editor with the search from current search query
      if (currentSearches.length && isReloadingSubmittedSearchQuery) {
        updateEditorContentAfterSubmit(editor, currentSearches)
      }
    },
    [editor, currentSearches, isReloadingSubmittedSearchQuery]
  )

  useEffect(
    () => {
      if (isReloadingSubmittedSearchQuery) {
        dispatch(doUpdateSearchByKey('isReloadingSubmittedSearchQuery', false))
      }
    },
    [dispatch, isReloadingSubmittedSearchQuery]
  )

  useEffect(
    () => {
      if (
        showClearIcon &&
        !isTypedSearch &&
        isOnSearchPage &&
        !isDuplicatedSearchAction
      ) {
        dispatch(doClearSearch(null, { shouldSubmit: false, shouldBlur: true }))
      }
    },
    [
      isTypedSearch,
      dispatch,
      isOnSearchPage,
      isDuplicatedSearchAction,
      showClearIcon,
    ]
  )

  useEffect(
    () => {
      if (
        prefersSearchCurrentMailboxByDefault &&
        !isTypedSearch &&
        !isDuplicatedSearchAction
      ) {
        handleUpdateSearchMailboxes(
          currentMailbox?.id
            ? [buildIdFromAny('Channel', currentMailbox.id)]
            : []
        )
      }
    },
    [
      currentMailbox,
      handleUpdateSearchMailboxes,
      prefersSearchCurrentMailboxByDefault,
      isTypedSearch,
      isDuplicatedSearchAction,
    ]
  )

  return (
    <div
      className={cn(
        'grui pr-3 relative flex items-center',
        { focused: isSearchBoxFocused },
        className
      )}
      css={styles.searchBox}
    >
      <ListenToKeyboard
        onForwardSlash={handleStartSearch}
        disableForInput
        preventDefault
      />
      <MailboxSearchFilter
        focused={isSearchBoxFocused}
        selectedMailboxIds={searchMailboxIds}
        onSelectMailboxes={handleUpdateSearchMailboxes}
      />
      <PlainTextEditor placeholder={placeholder} />
      {showClearIcon && (
        <SVGIcon
          name="times"
          color="color.monochrome.mediumDark"
          size={16}
          className={cn(
            oldStyles.searchBox_clearIcon,
            oldStyles['icon--opacity--dimmed'],
            'search-clearIcon grui mr-2'
          )}
          onClick={handleClearInput}
          onMouseDown={preventBlur}
        />
      )}
      {shouldShowAllSuggestionsButton && (
        <Button
          type="icon"
          size="small"
          className="builder-icon"
          css={styles.searchQueryBuilderButton}
          onClick={clickShowAllSuggestions}
          onMouseDown={preventBlur}
        >
          <FilterIcon />
        </Button>
      )}
      {isSearchBoxFocused && (
        <SearchSuggestions
          focusElement={searchBoxRef.current}
          searchQuery={committedSearchQuery}
          searchMailboxIds={searchMailboxIds}
          recentSearchQueries={recentSearchQueryList}
          onSubmit={handleSubmit}
          onSelect={handleSelect}
          onMouseDown={handleSuggestionsMouseDown}
          shouldShowAllSuggestions={shouldShowAllSuggestions}
          suggestions={suggestions}
          className="search-suggestions"
          setIsSuggestionActive={setIsSuggestionActive}
        >
          {children}
        </SearchSuggestions>
      )}
    </div>
  )
}

export default SearchWithSuggestions
