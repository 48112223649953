import { css } from '@emotion/react'
import moment from 'moment-timezone-all'
import { useCallback, useState, useMemo } from 'react'

import {
  ADDRESS,
  DATE,
  DROPDOWN,
  LINK,
  NUMBER,
  MULTI_LINE,
  SINGLE_LINE,
  TIMEZONE,
  MULTI_SELECT,
} from 'ducks/crm/customFields'
import { Select, Input, TextArea } from 'util/ui'
import { useAutofocus } from 'util/hooks'
import { isEmpty } from 'util/objects'

import CustomFieldDropdown from './CustomFieldEditDropdown'
import CustomFieldEditDate from './CustomFieldEditDate'

const TIMEZONES = [
  ...moment.tz
    .names()
    .map(name => ({ text: name.replace(/_/g, ' '), value: name })),
]

const singleLineStyle = () => theme => css`
  .sui .ui.form & {
    .error {
      display: none;
    }
    input {
      &:invalid,
      &:hover:invalid {
        border-color: ${theme.color.negative[500]};
        > .error {
          display: block;
        }
      }
    }
  }
`

export default function CustomFieldEditValue({
  fieldKey,
  options,
  title,
  type,
  value = {},
}) {
  const { content } = value || {}
  const values = useMemo(
    () => {
      if (Array.isArray(value)) return value
      if (isEmpty(value)) return []
      return [value]
    },
    [value]
  )

  const arrayOfContents = useMemo(
    () => {
      return values.map(v => v?.content)
    },
    [values]
  )
  const autofocusRef = useAutofocus()
  const isEmail = fieldKey.match(/_email(s\[[0-9]\])?$/)
  const isPhone = fieldKey.match(/phone_number$/)
  const [timezone, setTimezone] = useState(value.content)
  const handleTimezoneChange = useCallback(
    (_, { value: newValue }) => {
      setTimezone(newValue)
    },
    [setTimezone]
  )
  switch (type) {
    case ADDRESS:
      return (
        <>
          <Input
            defaultValue={value.street}
            fluid
            name={`${fieldKey}.street`}
            placeholder="Street"
            ref={autofocusRef}
          />
          <Input
            defaultValue={value.street2}
            fluid
            name={`${fieldKey}.street2`}
            placeholder="Street 2"
          />
          <Input
            defaultValue={value.city}
            fluid
            name={`${fieldKey}.city`}
            placeholder="City"
          />
          <Input
            defaultValue={value.state}
            fluid
            name={`${fieldKey}.state`}
            placeholder="State"
          />
          <Input
            defaultValue={value.postalCode}
            fluid
            name={`${fieldKey}.postalCode`}
            placeholder="Postal code"
          />
          <Input
            defaultValue={value.country}
            fluid
            name={`${fieldKey}.country`}
            placeholder="Country"
          />
        </>
      )
    case DATE:
      return <CustomFieldEditDate fieldKey={fieldKey} value={value.content} />
    case DROPDOWN:
      return (
        <CustomFieldDropdown
          fieldKey={fieldKey}
          options={options}
          title={title}
          value={value.content}
        />
      )
    case MULTI_SELECT:
      return (
        <CustomFieldDropdown
          fieldKey={fieldKey}
          options={options}
          title={title}
          value={arrayOfContents}
          multiple
        />
      )
    case LINK:
      return (
        <>
          <Input
            defaultValue={value.link}
            fluid
            name={`${fieldKey}.link`}
            placeholder="URL"
            ref={autofocusRef}
          />
          <Input
            defaultValue={value.text}
            fluid
            name={`${fieldKey}.text`}
            placeholder="Text"
          />
        </>
      )
    case MULTI_LINE:
      return (
        <TextArea
          defaultValue={content}
          name={`${fieldKey}.content`}
          ref={autofocusRef}
        />
      )
    case NUMBER:
    case SINGLE_LINE:
      return (
        <div css={singleLineStyle}>
          <Input
            defaultValue={content}
            fluid
            name={`${fieldKey}.content`}
            type={
              (type === NUMBER && 'number') ||
              (isPhone && 'tel') ||
              (isEmail && 'email') ||
              'text'
            }
            ref={autofocusRef}
          />
        </div>
      )
    case TIMEZONE:
      return (
        <>
          <Select fluid options={TIMEZONES} onChange={handleTimezoneChange} />
          <input type="hidden" name={`${fieldKey}.content`} value={timezone} />
        </>
      )
    default:
      return null
  }
}
