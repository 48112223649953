import { css } from '@emotion/react'

const paneGroup = css`
  @media print {
    min-width: 0 !important;
  }
`

export const styles = {
  paneGroup,
}
