import { connect } from 'react-redux'

import { doHandleUploadComplete, doUploadFiles } from 'actions/attachments'

import {
  selectAttachmentsByDraftId,
  selectDraftAssignmentLabelByTicketId,
  selectDraftByTicketId,
} from 'ducks/drafts2/selectors'
import { doRealtimeAgentStartTicketTypingNotification } from 'actions/realtime'
import {
  debouncedHandleDraftChange,
  handleDraftChange,
  doDeleteDraft,
  doSendReplyDraft,
  doSwitchToDirectReply,
  doSwitchToReplyAll,
} from 'ducks/drafts2/operations'
import { doSwitchToForward } from 'ducks/drafts2/operations/doSwitchToForward'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'

import { selectIsForwarding } from 'selectors/page'

import { emptyArr } from 'util/arrays'

import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'

import {
  selectAccountPrefersReplyOpen,
  selectPrefersAiEnabled,
} from 'selectors/app/selectAccountPreferences'
import { doOpenTicketPage } from 'actions/pages'
import { doToggleCannedReplyDropdown } from 'ducks/cannedReplies/operations'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectIsRequiredConversationFieldsFilled } from 'ducks/crm/contacts/selectors/customFields'

import View from './view'

const DRAFT_TYPE = 'reply'
const select = () => {
  return (state, props) => {
    const { ticketId } = props
    // Defaults only applicable for "new" converations
    const { isOpen = false, subject = '' } =
      selectCurrentConversationById(state, ticketId) || {}
    const draft = selectDraftByTicketId(state, ticketId, DRAFT_TYPE) || {}
    const {
      id: draftId,
      assigneeGroupId,
      serverVersion,
      version,
      isForwarding: isForwardingFromDraft,
      to,
      cc,
      bcc,
      title,
      mailboxId,
      replyType,
      isSendable,
      notSendableReason,
    } = draft

    const isForwardingFromQuery = selectIsForwarding(state)
    const showKbSearch = selectShowKbSearchOnInboxEditor(state)
    const isCustomFieldsValid = selectIsRequiredConversationFieldsFilled(state)

    return {
      draftId,
      syncable: !!draftId,
      isOpen,
      isSynced: serverVersion === version,
      assignmentLabel: selectDraftAssignmentLabelByTicketId(
        state,
        ticketId,
        DRAFT_TYPE
      ),
      assigneeGroupId,
      mailboxId,
      to: to || emptyArr,
      cc: cc || emptyArr,
      bcc: bcc || emptyArr,
      subject: title || subject || '',
      isForwardingFromDraft,
      isForwardingFromQuery,
      forwarding: isForwardingFromDraft || isForwardingFromQuery,
      replyType: replyType || 'reply-all',
      isSendable: isSendable && isCustomFieldsValid,
      notSendableReason,
      showKbSearch,
      prefersOpen: selectAccountPrefersReplyOpen(state),
      attachments: selectAttachmentsByDraftId(state, draftId),
      isNewTicket: selectIsComposingNewTicket(state),
      prefersAiEnabled: selectPrefersAiEnabled(state),
    }
  }
}

const perform = dispatch => {
  return {
    deleteDraft: (ticketId, draftId) => {
      if (draftId) {
        const msg = 'Are you sure you want to delete this draft?'
        if (!window.confirm(msg)) return // eslint-disable-line no-alert
        dispatch(doDeleteDraft(ticketId, draftId))
      } else {
        dispatch(doUpdateEditorVisibility(false))
      }
      if (ticketId !== 'new') {
        dispatch(doOpenTicketPage(ticketId))
      }
    },
    debouncedHandleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId, oldBody } = opts
      const messageId = null // not used yet
      debouncedHandleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields,
        oldBody
      )
    },
    handleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    handleDraftMultiChange: (ticketId, fields, opts) => {
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    sendDraft: draftId => {
      dispatch(doSendReplyDraft(draftId))
    },
    uploadFiles: (...args) => {
      dispatch(doUploadFiles(...args))
    },
    realtimeAgentStartTicketTypingNotification: (...args) => {
      dispatch(doRealtimeAgentStartTicketTypingNotification(...args))
    },
    switchToReplyAll: (ticketId, draftId) => {
      dispatch(doSwitchToReplyAll(ticketId, DRAFT_TYPE, draftId))
    },
    switchToDirectReply: (ticketId, draftId) => {
      dispatch(doSwitchToDirectReply(ticketId, DRAFT_TYPE, draftId))
    },
    switchToForward: (ticketId, draftId) => {
      dispatch(doSwitchToForward(ticketId, DRAFT_TYPE, draftId))
    },
    openTicketPageWithReplying: ticketId => {
      dispatch(doOpenTicketPage(ticketId, { replying: true }))
    },
    openCannedReplyDropdown: (source, cursorBookmark) => {
      dispatch(doToggleCannedReplyDropdown(true, { source, cursorBookmark }))
    },
    handleUploadComplete: (...args) =>
      dispatch(doHandleUploadComplete(...args)),
  }
}

export default connect(select, perform)(View)
