/* eslint-disable react/display-name */
import React from 'react'
import cn from 'classnames'

import PanelGroup from 'components/PaneGroup'
import RightSidebar from 'components/App/DesktopView/Layout/RightSidebar'
import MainCell from 'components/App/DesktopView/Layout/MainCell'
import withPanelWidthManager from 'components/App/DesktopView/Layout/withPanelWidthManager'
import TicketList from 'subapps/ticketing/components/TicketList'

import RightSidebarToggler from '../../RightSidebar/Toggler'
import useRightSidebar from '../../RightSidebar/useRightSidebar'
import styles from '../styles.css'

import OverlayBackground from './OverlayBackground'
import { DEFAULT_WIDTHS, DEFAULT_BREAKPOINTS, STORAGE_KEY } from './defaults'
import { styles as emotionStyles } from './styles'

class ModernView extends React.PureComponent {
  // Length has to match panes count
  componentWrappers = [
    React.forwardRef((props, ref) => {
      return (
        <div
          {...props}
          ref={ref}
          className={cn(props.className, 'hidden-in-print')}
        />
      )
    }),
    null,
    React.forwardRef((props, ref) => {
      const { isRightSidebarCollapsed, overlay } = this.props
      const { onTransitionEnd, finalStyle } = useRightSidebar({
        isRightSidebarCollapsed,
        overlay,
        style: props.style,
      })

      return (
        <div
          {...props}
          ref={ref}
          onTransitionEnd={onTransitionEnd}
          style={finalStyle}
          className={cn(
            props.className,
            styles.rightSidebarWrapper,
            'hidden-in-print'
          )}
        />
      )
    }),
  ]

  render() {
    const {
      toggleRightSidebar,
      isBulkSelectionMode,
      isRightSidebarCollapsed,
      overlay,
      ticketId,
      panelWidths = [],
      panelUpdateCallback,
    } = this.props

    const showRightSideBar = !isBulkSelectionMode && ticketId
    const isRightSidebarHiddenOrCollapsed =
      isRightSidebarCollapsed || !showRightSideBar
    const collapsedPanels = isRightSidebarHiddenOrCollapsed
      ? (panelWidths.length - 1).toString()
      : ''
    return (
      <>
        {showRightSideBar && <RightSidebarToggler />}
        <PanelGroup
          css={emotionStyles.paneGroup}
          spacing={0}
          panelWidths={panelWidths}
          collapsedPanels={collapsedPanels}
          onUpdate={panelUpdateCallback}
          ComponentWrappers={this.componentWrappers}
          breakpoints={DEFAULT_BREAKPOINTS}
          className="four-column-view"
        >
          <TicketList channelType="mailbox" />
          <MainCell />
          {showRightSideBar && (
            <RightSidebar
              panelWidth={(panelWidths[2] || {}).size}
              overlay={overlay}
            />
          )}
        </PanelGroup>
        <OverlayBackground
          onClick={toggleRightSidebar}
          visible={overlay && !isRightSidebarCollapsed}
        />
      </>
    )
  }
}

export default withPanelWidthManager(DEFAULT_WIDTHS, STORAGE_KEY)(ModernView)
