import React from 'react'
import { useSelector } from 'react-redux'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'
import { selectReportingPermitted } from 'ducks/currentUser/selectors/selectReportingPermitted'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { LANDING_PAGE as KB_LANDING_PAGE } from 'subapps/kb/pages'
import { selectIsFreemium } from 'ducks/billing/selectors/selectIsFreemium'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { doOpenMainPage } from 'actions/pages'
import { REPORTS_MAIN_PAGE } from 'ducks/reports/pages'
import { AI_AGENT_PAGE } from 'constants/pages'
import { selectFlag } from 'ducks/flags/selectors'
import { HIDE_AI_AGENT_DOT } from 'ducks/flags/flagNames'
import { INDEX_PAGE as CRM_LANDING_PAGE } from 'ducks/crm/pages'
import FeatureGate from 'components/FeatureGate'
import { selectPrefersKbEnabled } from 'selectors/app/selectAccountPreferences'

import Item from './Item'
import { styles } from './styles'

const toMainPage = doOpenMainPage()
const toAIAgentPage = { type: AI_AGENT_PAGE }
const toReportsPage = { type: REPORTS_MAIN_PAGE }
const toKbLandingPage = { type: KB_LANDING_PAGE }
export const toBillingPage = { type: SETTINGS_BILLING_PAGE }
const toCrmContactsPage = { type: CRM_LANDING_PAGE }

const Nav = ({ className, active }) => {
  const showReporting = useSelector(selectReportingPermitted)
  const isFreemium = useSelector(selectIsFreemium)
  const currentUserIsAdminOrOwner = useSelector(selectCurrentUserIsAdminOrOwner)
  const showUpgrade = isFreemium && currentUserIsAdminOrOwner
  const showKbSection = useSelector(selectPrefersKbEnabled)
  const hideAiAgentDot = useSelector(selectFlag)(HIDE_AI_AGENT_DOT)

  return (
    <nav className={className}>
      <div
        css={[text.styles.fontMedium, styles.list]}
        className="grui flex items-center"
      >
        <Item
          to={toMainPage}
          css={styles.item}
          isActive={active === 'Conversations'}
        >
          Conversations
        </Item>
        <Item
          to={toAIAgentPage}
          css={styles.item}
          isActive={active === 'AI Agent'}
          className="grui relative"
        >
          AI Agent
          {hideAiAgentDot === false && (
            <Dot
              className="grui absolute"
              css={styles.dot}
              color="color.negative.500"
            />
          )}
        </Item>
        {showReporting && (
          <Item
            to={toReportsPage}
            css={styles.item}
            isActive={active === 'Reporting'}
            dataTestId="Reporting"
          >
            Reports
          </Item>
        )}
        {showKbSection && (
          <Item
            to={toKbLandingPage}
            css={styles.item}
            isActive={active === 'Knowledge Base'}
          >
            Knowledge Base
          </Item>
        )}
        <FeatureGate flag="crm-contacts-page-enabled">
          <Item
            css={styles.item}
            to={toCrmContactsPage}
            isActive={active === 'Customers'}
          >
            Customers
          </Item>
        </FeatureGate>

        {showUpgrade && (
          <Item css={styles.upgrade} to={toBillingPage}>
            Upgrade your free plan
          </Item>
        )}
      </div>
    </nav>
  )
}

export default Nav
