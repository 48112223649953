import { connect } from 'react-redux'
import { selectIsTicketSearchSuggestions } from 'selectors/search_suggestions'
import { selectCurrentCommittedTicketSearchQueryCurrentPart } from 'selectors/search/base'
import { selectCurrentSubmittedTicketSearchFilterMatches } from 'selectors/search/searchFilters'
import SearchSuggestions from './view'

export default connect(state => ({
  isSuggestions: selectIsTicketSearchSuggestions(state),
  currentSubmittedTicketSearchFilterMatches: selectCurrentSubmittedTicketSearchFilterMatches(
    state
  ),
  currentCommittedTicketSearchQueryCurrentPart: selectCurrentCommittedTicketSearchQueryCurrentPart(
    state
  ),
}))(SearchSuggestions)
