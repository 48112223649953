import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectMessageById,
  selectConversationEventGroupById,
} from 'ducks/tickets/selectors'
import { selectAttachmentsByMessageId } from 'ducks/tickets/selectors/selectAttachmentsByMessageId'
import Attachments from 'components/Attachments'
import ReactionList from 'components/ReactionList'
import { emptyObj } from 'util/objects'
import { doToggleNoteReaction } from 'ducks/tickets/actions/doToggleNoteReaction'

import Header from './Header'
import Body from './Body'
import TrackingDetails from './TrackingDetails'

import Avatar from './Avatar'

const ExpandedNoteContent = ({
  ticketId,
  eventGroupId,
  expandChangeset,
  isFirstMessage,
  onEditClick,
}) => {
  const dispatch = useDispatch()
  const eventGroup = useSelector(
    state =>
      selectConversationEventGroupById(state, eventGroupId, null, true) ||
      emptyObj
  )
  const {
    summary: { id: messageId, isBodyEmpty } = {},
    isForward,
    actor: {
      id: actorId,
      name: actorName,
      role: actorRole,
      avatarUrl: actorAvatarUrl,
    } = {},
  } = eventGroup
  const message = useSelector(state => selectMessageById(state, messageId))
  const { parts } = message
  const attachments = useSelector(state =>
    selectAttachmentsByMessageId(state, messageId)
  )

  const onReactionClick = useCallback(
    reaction => {
      dispatch(doToggleNoteReaction(ticketId, messageId, reaction))
    },
    [dispatch, ticketId, messageId]
  )

  return (
    <React.Fragment>
      <div className="header-body-container">
        <Avatar
          id={actorId}
          type={actorRole}
          name={actorName}
          avatarUrl={actorAvatarUrl}
        />
        <div className="note-header-body">
          <div onClick={expandChangeset}>
            <Header
              eventGroupId={eventGroupId}
              onEditClick={onEditClick}
              onReactionClick={onReactionClick}
            />
          </div>
          <div>
            <Body
              parts={parts}
              isBodyEmpty={isBodyEmpty}
              isForward={isForward}
              isFirstMessage={isFirstMessage}
            />
          </div>
        </div>
      </div>
      <div className="bottom-side">
        <Attachments attachments={attachments} />
        {isFirstMessage && <TrackingDetails />}
        <ReactionList eventGroupId={eventGroupId} onClick={onReactionClick} />
      </div>
    </React.Fragment>
  )
}

export default ExpandedNoteContent
