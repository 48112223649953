import React from 'react'
import cn from 'classnames'

import { fileClass } from 'util/mime'
import { propFunc } from 'util/functions'
import ScrollerContext from 'components/Scroller/Context'

import Header from './Header'
import Attachment from './Attachment'
import styles from './styles.css'

const Attachments = props => {
  const {
    draftAttachments,
    ticketAttachments,
    attachments: propAttachments,
  } = props
  let attachments = propAttachments || []
  if (draftAttachments) attachments = attachments.concat(draftAttachments)
  if (ticketAttachments) attachments = attachments.concat(ticketAttachments)
  if (!attachments || attachments.length === 0) return <EmptyState {...props} />
  const fileAttachments = []
  const imageAttachments = []
  const maliciousAttachments = []
  attachments.forEach(attachment => {
    if (attachment.scanReport?.status === 'FOUND') {
      maliciousAttachments.push(attachment)
    } else if (fileClass(attachment.fileType) === 'image') {
      imageAttachments.push(attachment)
    } else {
      fileAttachments.push(attachment)
    }
  })

  return (
    <NormalState
      {...props}
      allAttachments={attachments}
      fileAttachments={fileAttachments}
      imageAttachments={imageAttachments}
      maliciousAttachments={maliciousAttachments}
    />
  )
}

Attachments.defaultProps = {
  forDraft: false,
}

const key = attachment => {
  return attachment.id || attachment.key || attachment.fileName
}

const NormalState = props => {
  const { allAttachments, forNote, className } = props
  const isUploading = allAttachments.some(a => a.isUploading)
  return (
    <div
      className={cn(styles.Attachments, className, {
        [styles['Attachments-forNote']]: forNote,
      })}
    >
      <Header attachments={allAttachments} isUploading={isUploading} />
      <DesktopAttachmentLists {...props} isUploading={isUploading} />
    </div>
  )
}

class DesktopAttachmentLists extends React.PureComponent {
  static contextType = ScrollerContext

  componentDidMount() {
    this.scrollContainer()
  }

  componentDidUpdate(prevProps) {
    if (this.props.allAttachments.length !== prevProps.allAttachments.length)
      this.scrollContainer()
  }

  saveRef = node => (this.container = node)

  scrollContainer = () => {
    const { footerRef, forDraft } = this.props

    if (
      !forDraft ||
      !this.context?.getScrollerAPI ||
      !footerRef?.current ||
      !this.container
    ) {
      return
    }
    const parentScroller = this.context.getScrollerAPI()?.getElement()
    const attachementsBottom = this.container.getBoundingClientRect().bottom
    const floatingFooterTop = footerRef.current.getBoundingClientRect().top
    // Make sure the attachments bottom isn't partially hidden when uploading an attachment, so the attachment's cancel button can be visible
    if (parentScroller && attachementsBottom > floatingFooterTop) {
      parentScroller.scrollTop =
        parentScroller.scrollTop + attachementsBottom - floatingFooterTop + 4
    }
  }

  render() {
    const {
      allAttachments,
      fileAttachments,
      imageAttachments,
      maliciousAttachments,
      forNote,
      onClickImage,
      forDraft,
      draftId,
      afterDelete,
    } = this.props

    return (
      <div ref={this.saveRef}>
        {imageAttachments.length > 0 && (
          <div className={styles['Attachments-grid']} key="images">
            {imageAttachments.map((attachment, index) => (
              <div
                className={styles['Attachments-gridCell']}
                key={key(attachment)}
              >
                <Attachment
                  draftId={draftId}
                  attachment={attachment}
                  error={attachment.error}
                  forDraft={forDraft}
                  forNote={forNote}
                  isImage
                  isSingle={allAttachments.length === 1}
                  isUploading={attachment.isUploading}
                  key={key(attachment)}
                  onClick={propFunc(onClickImage, imageAttachments, index)}
                  afterDelete={afterDelete}
                />
              </div>
            ))}
          </div>
        )}
        {fileAttachments.length > 0 && (
          <div className={styles['Attachments-list']} key="files">
            {fileAttachments.map(attachment => (
              <Attachment
                draftId={draftId}
                attachment={attachment}
                error={attachment.error}
                forDraft={forDraft}
                forNote={forNote}
                isSingle={allAttachments.length === 1}
                isUploading={attachment.isUploading}
                key={key(attachment)}
                afterDelete={afterDelete}
              />
            ))}
          </div>
        )}
        {maliciousAttachments.length > 0 && (
          <div className={styles['Attachments-list']} key="files">
            {maliciousAttachments.map(attachment => (
              <Attachment
                draftId={draftId}
                attachment={attachment}
                error={attachment.error}
                forDraft={forDraft}
                forNote={forNote}
                isSingle={allAttachments.length === 1}
                isUploading={attachment.isUploading}
                key={key(attachment)}
                afterDelete={afterDelete}
                isMalicious
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

const EmptyState = () => null

export default Attachments
