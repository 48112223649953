import { connect } from 'react-redux'
import { selectCurrentMailboxErrorMessage } from 'ducks/mailboxes/selectors/selectCurrentMailboxErrorMessage'
import { selectIsSettingUpTheCurrentMailBox } from 'ducks/mailboxes/selectors/selectIsSettingUpTheCurrentMailBox'
import { selectShowDemoMailboxBar } from 'ducks/mailboxes/selectors/selectShowDemoMailboxBar'
import { selectDoesCurrentMailboxHaveState } from 'ducks/mailboxes/selectors/selectDoesCurrentMailboxHaveState'
import { CHANNEL_STATE, CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import {
  selectShowCreditCardExpireSoon,
  selectShowCreditCardExpired,
  selectPrimaryCreditCard,
  selectShowLastBillingFailed,
} from 'ducks/billing/selectors'
import { selectHasAnyMailboxByStates } from 'ducks/mailboxes/selectors/selectHasAnyMailboxByStates'
import { selectCancelsAt } from 'ducks/billing/selectors/selectCancelsAt'
import { selectShowPendingCancellation } from 'ducks/billing/selectors/selectShowPendingCancellation'
import { selectShowExpiredBilling } from 'ducks/billing/selectors/selectShowExpiredBilling'
import { selectShowTrialMessage } from 'ducks/billing/selectors/selectShowTrialMessage'
import { selectDaysLeftBeforeExpiring } from 'ducks/billing/selectors/selectDaysLeftBeforeExpiring'
import { selectShowBillingPlanUsageLimited } from 'ducks/billing/selectors/usage/selectShowBillingPlanUsageLimited'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectCurrentUser2faEnforced } from 'ducks/currentUser/selectors/selectCurrentUser2faEnforced'
import { selectGitHubNeedsReinstall } from 'ducks/integrations/github'
import { selectIsWidgetFromUrlDisconnected } from 'ducks/widgets/selectors/widgets'
import { selectShowTwitterDeprecation } from 'ducks/integrations/twitter/selectors'
import { selectSearchesRequestState } from 'selectors/search'
import {
  selectShowContinueForwardingSetupBar,
  selectShowContinueForwardingSetupPane,
} from 'selectors/layout'
import AppNoticesView from './view'

const select = state => {
  const {
    loaded: isSearchesLoaded,
    error: isSearchesError,
  } = selectSearchesRequestState(state)

  return {
    showDemoMailboxBar: selectShowDemoMailboxBar(state),
    showExpiredBilling: selectShowExpiredBilling(state),
    showGitHubReauth: selectGitHubNeedsReinstall(state),
    showPendingCancellation: selectShowPendingCancellation(state),
    showCreditCardExpireSoon: selectShowCreditCardExpireSoon(state),
    showCreditCardExpired: selectShowCreditCardExpired(state),
    showLastBillingFailed: selectShowLastBillingFailed(state),
    creditCard: selectPrimaryCreditCard(state),
    cancelsAt: selectCancelsAt(state),
    showTrialMessage: selectShowTrialMessage(state, 'inbox'),
    trialDaysLeft: selectDaysLeftBeforeExpiring(state, 'inbox'),
    isManager: selectCurrentUserIsAdminOrOwner(state),
    isCurrentMailboxConfirmedOrUnconfirmed: selectDoesCurrentMailboxHaveState(
      state,
      [CHANNEL_STATE.UNCONFIRMED],
      null,
      [CHANNEL_TYPE.FORWARDING]
    ),
    isCurrentMailboxDisconnected:
      selectDoesCurrentMailboxHaveState(state, [CHANNEL_STATE.DISCONNECTED]) ||
      selectDoesCurrentMailboxHaveState(
        state,
        [CHANNEL_STATE.DISCONNECTED, CHANNEL_STATE.UNCONFIRMED],
        null,
        [CHANNEL_TYPE.IMAP]
      ),
    isCurrentMailboxSyncing: selectDoesCurrentMailboxHaveState(state, [
      CHANNEL_STATE.SYNCING,
    ]),
    isCurrentMailboxError: selectDoesCurrentMailboxHaveState(state, [
      CHANNEL_STATE.ERROR,
    ]),
    currentMailboxErrorMessage: selectCurrentMailboxErrorMessage(state),
    isCurrentMailboxConfirmedOrUnconfirmedLegacy: selectDoesCurrentMailboxHaveState(
      state,
      [CHANNEL_STATE.UNCONFIRMED],
      null,
      ['LEGACY']
    ),
    isWidgetFromUrlDisconnected: selectIsWidgetFromUrlDisconnected(state),
    showTwitterDeprecation: selectShowTwitterDeprecation(state),
    isSettingUpTheCurrentMailBox: selectIsSettingUpTheCurrentMailBox(state),
    showContinueForwardingSetupPane: selectShowContinueForwardingSetupPane(
      state
    ),
    showContinueForwardingSetupBar: selectShowContinueForwardingSetupBar(state),
    isSearchesLoaded: isSearchesLoaded && !isSearchesError,
    showBillingPlanUsageLimited: selectShowBillingPlanUsageLimited(state),
    currentUser2faEnforced: selectCurrentUser2faEnforced(state),
    hasMigratingMailbox: selectHasAnyMailboxByStates(state, [
      CHANNEL_STATE.MIGRATING,
    ]),
    isCurrentMailboxMigrating: selectDoesCurrentMailboxHaveState(state, [
      CHANNEL_STATE.MIGRATING,
    ]),
  }
}

export default connect(select)(AppNoticesView)
