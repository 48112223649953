import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import produce from 'immer'
import { useSelector } from 'react-redux'
import { selectRealMailboxes } from 'ducks/mailboxes/selectors/selectRealMailboxes'
import { selectCurrentRecipientSyncSource } from 'ducks/drafts2/selectors'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'

const initialState = {
  isEditing: false,
  rowVisibility: {
    from: true,
    to: true,
    name: false,
    phone: false,
    cc: false,
    bcc: false,
    subject: false,
  },
  isNameDirty: false,
  focusedRow: null,
  actionsRow: 'to',
}

const actionVisibilityOrder = ['subject', 'bcc', 'cc', 'phone', 'name', 'to']

const getActionsRow = rowVisibility => {
  return actionVisibilityOrder.find(candidate => {
    return rowVisibility[candidate]
  })
}

const buildInitialState = state => {
  return {
    ...initialState,
    ...state,
    actionsRow: getActionsRow(state.rowVisibility) || state.actionsRow,
  }
}

const reducer = produce((draftState, { type, payload = {} }) => {
  switch (type) {
    case 'EXPAND': {
      draftState.isEditing = true
      const { row, shouldFocus = true } = payload
      if (row) {
        draftState.rowVisibility[row] = true
        if (shouldFocus) {
          draftState.focusedRow = row
        }
      } else if (shouldFocus) {
        draftState.focusedRow = null
      }
      break
    }
    case 'COLLAPSE': {
      draftState.isEditing = false
      break
    }
    case 'SHOW_ROW': {
      const { row, shouldFocus = true } = payload
      draftState.rowVisibility[row] = true
      if (shouldFocus) {
        draftState.focusedRow = row
      }
      break
    }
    case 'HIDE_ROW': {
      const { row } = payload
      draftState.rowVisibility[row] = false
      if (draftState.focusedRow === row) {
        draftState.focusedRow = null
      }
      if (row === 'name') {
        draftState.isNameDirty = false
      }
      break
    }
    case 'SHIFT_FOCUS': {
      const { direction } = payload
      if (!direction) throw new Error('Invalid direction')
      const rowNames = Object.keys(draftState.rowVisibility)
      const startIndex = rowNames.indexOf(draftState.focusedRow)
      if (startIndex === -1) return
      const candidateRows = rowNames.slice(startIndex + 1)
      if (direction === 'BACKWARD') {
        candidateRows.reverse()
      }
      const newRow = candidateRows.find(key => {
        return !!draftState.rowVisibility[key]
      })
      if (newRow) {
        draftState.focusedRow = newRow
      }
      break
    }
    case 'SET_FOCUS': {
      const { row } = payload
      draftState.rowVisibility[row] = true
      draftState.focusedRow = row
      break
    }
    case 'CLEAR_FOCUS': {
      draftState.focusedRow = null
      break
    }
    case 'SET_NAME_DIRTY_STATE': {
      const { dirty } = payload
      draftState.isNameDirty = dirty
      break
    }
    default:
    // do nothing
  }

  draftState.actionsRow = getActionsRow(draftState.rowVisibility)
})

export const useHeaderController = ({
  ticketId,
  isNewTicket,
  isForwardingType,
  toRecipients,
  ccRecipients,
  bccRecipients,
  phone,
  subject,
}) => {
  const rawTicket = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )
  const mailboxes = useSelector(selectRealMailboxes)
  const [state, dispatch] = useReducer(
    reducer,
    {
      isEditing: isNewTicket,
      rowVisibility: {
        from: mailboxes.length !== 1, // Show for 0 or more than 1 mailboxes
        to: true,
        name: false,
        phone: !!phone,
        cc: !!(ccRecipients && ccRecipients.length > 0),
        bcc: !!(bccRecipients && bccRecipients.length > 0),
        subject: isNewTicket || (rawTicket?.subject || '') !== (subject || ''),
      },
      isNameDirty: false,
      focusedRow: !toRecipients || toRecipients.length === 0 ? 'to' : null,
      actionsRow: 'to',
    },
    buildInitialState
  )

  const toFocusableRef = useRef(null)
  const ccFocusableRef = useRef(null)
  const bccFocusableRef = useRef(null)
  const nameFocusableRef = useRef(null)
  const subjectFocusableRef = useRef(null)
  const phoneFocusableRef = useRef(null)

  const getFocusableRef = useCallback(
    row => {
      if (!row) return null

      switch (row) {
        case 'to':
          return toFocusableRef
        case 'cc':
          return ccFocusableRef
        case 'bcc':
          return bccFocusableRef
        case 'name':
          return nameFocusableRef
        case 'subject':
          return subjectFocusableRef
        case 'phone':
          return phoneFocusableRef
        default:
          throw new Error(`Invalid focusable ref row name: ${row}`)
      }
    },
    [
      toFocusableRef,
      ccFocusableRef,
      bccFocusableRef,
      nameFocusableRef,
      subjectFocusableRef,
      phoneFocusableRef,
    ]
  )

  const setFocus = useCallback(
    row => {
      if (!row) {
        dispatch({ type: 'CLEAR_FOCUS', payload: {} })
        return
      }

      dispatch({ type: 'SHOW_ROW', payload: { row, shouldFocus: true } })
    },
    [dispatch]
  )

  const advanceFocus = useCallback(
    () => {
      dispatch({ type: 'SHIFT_FOCUS', payload: { direction: 'FORWARD' } })
    },
    [dispatch]
  )

  const retreatFocus = useCallback(
    () => {
      dispatch({ type: 'SHIFT_FOCUS', payload: { direction: 'BACKWARD' } })
    },
    [dispatch]
  )

  useEffect(
    () => {
      const ref = getFocusableRef(state.focusedRow)
      if (!ref?.current) return
      if (ref.current === document.activeElement) return
      if (ref.current.contains(document.activeElement)) return
      ref.current.focus()
    },
    [state.focusedRow, getFocusableRef]
  )

  const expand = useCallback(
    row => {
      let calculatedRow = row
      if (!calculatedRow) {
        if (!toRecipients?.length) {
          calculatedRow = 'to'
        } else if (ccRecipients.length) {
          calculatedRow = 'cc'
        } else if (bccRecipients.length) {
          calculatedRow = 'bcc'
        }
      }
      dispatch({ type: 'EXPAND', payload: { row: calculatedRow } })
      setFocus(row)
    },
    [dispatch, toRecipients, bccRecipients, ccRecipients, setFocus]
  )

  useEffect(
    () => {
      let timeoutId
      if (isNewTicket) return () => {}
      if (isForwardingType && !state.isEditing) {
        // HACK (jscheel): Give draft chance to swap over to forwarding
        timeoutId = setTimeout(() => {
          expand()
        }, 1)
      }

      return () => {
        clearTimeout(timeoutId)
      }
    },
    [isNewTicket, isForwardingType, state.isEditing, expand, ccRecipients]
  )

  const syncingSource = useSelector(selectCurrentRecipientSyncSource)

  useLayoutEffect(
    () => {
      if (!isNewTicket || syncingSource === 'to') return
      // NOTE (jscheel): This only handles the hiding of the row, not the showing.
      // The showing of the name row is controlled by the useRecipientsEditor. It's
      // not really ideal to separate out this logic, and I need to re-address this
      // later, but gotta get this fix out.
      const to = toRecipients?.[0]
      if (!to || (to?.id && !to.name === to.email && !state.isNameDirty)) {
        dispatch({ type: 'HIDE_ROW', payload: { row: 'name' } })
      }
    },
    [isNewTicket, toRecipients, state.isNameDirty, syncingSource, setFocus]
  )

  const onActionClick = useCallback(
    row => {
      dispatch({ type: 'SHOW_ROW', payload: { row } })
      setFocus(row)
    },
    [dispatch, setFocus]
  )

  const onToRecipientUpdated = useCallback(
    found => {
      // NOTE (jscheel): Forwarding supports multiple recipients and doesn't support name
      if (isForwardingType) return
      if (found) {
        advanceFocus()
      } else {
        setFocus('name')
      }
    },
    [isForwardingType, advanceFocus, setFocus]
  )

  const allRecipients = useMemo(
    () => {
      return (toRecipients || [])
        .concat(ccRecipients, bccRecipients)
        .filter(Boolean)
    },
    [toRecipients, ccRecipients, bccRecipients]
  )

  const getFocusedRow = useCallback(
    () => {
      return state.focusedRow
    },
    [state.focusedRow]
  )

  return {
    state,
    allRecipients,
    dispatch,
    getFocusableRef,
    setFocus,
    advanceFocus,
    retreatFocus,
    onToRecipientUpdated,
    onActionClick,
    expand,
    getFocusedRow,
  }
}
