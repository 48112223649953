import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMailboxHasTickets } from 'ducks/mailboxes/selectors/selectCurrentMailboxHasTickets'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { selectIsRefreshingSearches } from 'ducks/searches/selectors'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'

import TitleBar from './TitleBar'
import FilterBar from './FilterBar'

export default function ConversationListHeader(props) {
  const {
    TitleBarComponent = TitleBar,
    FilterBarComponent = FilterBar,
    isWide,
  } = props
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const currentMailboxHasTickets = useSelector(selectCurrentMailboxHasTickets)
  const isRefreshingSearches = useSelector(selectIsRefreshingSearches)
  const query = useSelector(selectCurrentQuery)

  const showActionBar =
    currentMailboxHasTickets === true ||
    !currentMailboxId ||
    isRefreshingSearches ||
    !!query.tagid ||
    !!query.assignee

  return (
    <div className="conversation-list-header">
      {showActionBar && (
        <>
          <TitleBarComponent isWide={isWide} />
          <FilterBarComponent isWide={isWide} />
        </>
      )}
    </div>
  )
}
