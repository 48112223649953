import React from 'react'
import { VictoryPie } from 'victory'
import { useTheme } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'

import { styles } from './styles'
import ChartLabel from './ChartLabel'
import Activation from './Activation'

const getLabelRadius = ({ eventKey }) => {
  if (eventKey === 0) return 100
  if (eventKey === 1) return 80
  return 40
}

const MailboxStep = ({ ...props }) => {
  const theme = useTheme()

  return (
    <>
      <div css={styles.pieContainer}>
        <VictoryPie
          startAngle={-90}
          endAngle={270}
          colorScale={[
            theme.color.primary.info,
            theme.color.primary.info,
            theme.color.secondary.info,
          ]}
          data={[
            {
              x: '1',
              y: 1,
              note: 'Sign up for an',
              secondNote: 'account',
              completed: true,
            },
            { x: '2', y: 1, note: 'Verify email', completed: true },
            {
              x: '3',
              y: 1,
              note: 'Connect ',
              secondNote: `${app.t('mailbox')}(es)`,
              completed: false,
            },
          ]}
          labelComponent={<ChartLabel />}
          labelRadius={getLabelRadius}
          style={{
            labels: {
              fill: theme.color.monochrome.white,
              fontSize: 16,
              fontFamily: theme.font.primary,
            },
          }}
        />
      </div>

      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          key="mailbox"
          className="grui w-100"
        >
          <Activation
            {...props}
            name={`Connect ${app.t('mailbox')}(es)`}
            title="One more step!"
            subtitle={`Connect your ${app.t(
              'Mailbox'
            )} to activate your Groove account.`}
            type="mailbox"
            skippable={false}
            completed={false}
          />
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export default MailboxStep
