import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectConversationEventGroupById } from 'ducks/tickets/selectors'
import { selectTicketEventsRequestByEventGroupId } from 'ducks/tickets/selectors/selectTicketEventsRequestByEventGroupId'
import { doFetchTicketEventsByGroupId } from 'ducks/tickets/actions/doFetchTicketEventsByGroupId'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import useIsMounted from 'util/hooks/useIsMounted'
import { emptyObj } from 'util/objects'
import CollapsedMessage from './CollapsedMessage'
import ExpandedMessage from './ExpandedMessage'

function EventGroup({ ticketId, eventGroupId, expanded, onClick, onLoad }) {
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  const { collapsed, changesetId, isFirstMessage } = useSelector(
    state => selectConversationEventGroupById(state, eventGroupId) || emptyObj
  )
  const { untried, loaded: hasRequestLoaded } = useSelector(state =>
    selectTicketEventsRequestByEventGroupId(state, eventGroupId)
  )
  const isLoaded = collapsed === false || hasRequestLoaded

  const handleOnClick = useCallback(
    e => {
      if (onClick) onClick(e, eventGroupId)
    },
    [onClick, eventGroupId]
  )

  useEffect(
    () => {
      // This would only happen for event groups that come pre-loaded
      // from the eventGroups gql response which currently always auto
      // expands the first and last message. Everyting else will need to be
      // loaded
      ;(async () => {
        if (!isLoaded && untried && expanded) {
          await dispatch(
            doFetchTicketEventsByGroupId({
              conversationId: ticketId,
              eventGroupId,
              channelType: MAILBOX_CHANNEL_TYPE,
            })
          )
          if (isMounted()) onLoad(eventGroupId, changesetId)
        }
      })()
    },
    [
      dispatch,
      eventGroupId,
      ticketId,
      expanded,
      untried,
      onLoad,
      isLoaded,
      isMounted,
      changesetId,
    ]
  )

  const MessageComponent =
    expanded && isLoaded ? ExpandedMessage : CollapsedMessage

  return (
    <MessageComponent
      eventGroupId={eventGroupId}
      ticketId={ticketId}
      onClick={handleOnClick}
      isFirstMessage={isFirstMessage}
    />
  )
}

export default React.memo(EventGroup)
