import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Dropdown } from 'shared/ui'

import { propFunc } from 'util/functions'

const buildOption = (key, text, onClick, id = null, prefix = 'Add note &') => ({
  id,
  key,
  text: `${prefix} ${text}`,
  onClick,
  value: key,
})

const buildOptions = (isOpen, onOpen, onClose, onSnooze) => {
  const snoozeOption = buildOption('snoozed', 'snooze...', onSnooze)

  if (isOpen) {
    return [
      buildOption('opened', 'leave open', onOpen),
      buildOption('closed', 'close', onClose),
      snoozeOption,
    ]
  }

  return [
    buildOption('closed', 'leave closed', onClose),
    buildOption('opened', 'open', onOpen),
    snoozeOption,
  ]
}

const CommentingDropdown = ({
  onSendOpenClick,
  onSendClosedClick,
  onSendSnoozedClick,
  isOpen,
  actionLabel,
  ...rest
}) => {
  return (
    <Dropdown
      {...rest}
      header={'Note options'}
      options={propFunc(
        buildOptions,
        isOpen,
        onSendOpenClick,
        onSendClosedClick,
        onSendSnoozedClick
      )()}
    />
  )
}

CommentingDropdown.propTypes = {
  /* Whether the dropdown menu is disabled */
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,

  /* onClick handlers for the button + each of the 3 DD menu options */
  onSendOpenClick: PropTypes.func.isRequired,
  onSendClosedClick: PropTypes.func.isRequired,
  onSendSnoozedClick: PropTypes.func.isRequired,
}

CommentingDropdown.defaultProps = {
  disabled: false,
}

export default styled(CommentingDropdown)`
  &&& {
    .button {
      color: ${props => props.theme.color.monochrome.white};
      background-color: ${props => props.theme.color.primary.brand};
    }
  }
`
