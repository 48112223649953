import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import useSearchTags from 'subapps/settings/components/TagsDropdown/useSearchTags'
import { useDebouncedCallback } from 'use-debounce'
import SearchComposer from './PlainTextEditor/Composer'
import styles from './styles.css'

const Search = ({
  className,
  committedSearchQuery,
  doFetchRecipients,
  doUpdateTicketSearchQuery,
  doToggleListSearchBoxFocused,
  doSubmitTicketsSearch,
  currentSubmittedTicketSearchFilterMatches,
}) => {
  const [isSearchBoxFocused, setIsSearchBoxFocused] = useState(false)
  const [shouldShowDatePicker, setShouldShowDatePicker] = useState(false)

  const { handleChangeSearch } = useSearchTags({
    searchDelayMs: 500,
    field: 'ids',
  })

  const updateSearchBoxFocusedState = useCallback(isFocused => {
    setIsSearchBoxFocused(isFocused)
  }, [])

  const searchLabelsByPart = useCallback(
    currentPart => {
      if (!currentPart) return
      const { value } = currentPart
      handleChangeSearch({ target: { value } })
    },
    [handleChangeSearch]
  )

  const [debouncedFetchRecipients] = useDebouncedCallback(
    doFetchRecipients,
    500
  )

  const handleInputChange = useCallback(
    (value, currentPart) => {
      const trimmedValue = value?.trim()
      doUpdateTicketSearchQuery(trimmedValue, {
        commit: true,
        submit: false,
        currentPart,
      })
      debouncedFetchRecipients(currentPart)
      searchLabelsByPart(currentPart)
    },
    [doUpdateTicketSearchQuery, debouncedFetchRecipients, searchLabelsByPart]
  )

  const toggleDatePicker = useCallback(visible => {
    setShouldShowDatePicker(visible)
  }, [])

  const handleSearchBoxFocused = useCallback(
    () => {
      updateSearchBoxFocusedState(true)
      doToggleListSearchBoxFocused(true)
    },
    [updateSearchBoxFocusedState, doToggleListSearchBoxFocused]
  )

  const handleSearchBoxBlur = useCallback(
    () => {
      toggleDatePicker(false)
      updateSearchBoxFocusedState(false)
      doToggleListSearchBoxFocused(false)
    },
    [
      toggleDatePicker,
      updateSearchBoxFocusedState,
      doToggleListSearchBoxFocused,
    ]
  )

  const handleSubmit = useCallback(
    (query, { shouldIncludePreviousSubmittedFilters } = {}) => {
      handleSearchBoxBlur()
      const newQuery = !shouldIncludePreviousSubmittedFilters
        ? query
        : [...currentSubmittedTicketSearchFilterMatches, query]
            .filter(Boolean)
            .join(' ')

      return doSubmitTicketsSearch(newQuery, {
        shouldIncludeSearchMailboxes: shouldIncludePreviousSubmittedFilters,
      })
    },
    [
      handleSearchBoxBlur,
      doSubmitTicketsSearch,
      currentSubmittedTicketSearchFilterMatches,
    ]
  )

  const stopCurtainClick = useCallback(ev => {
    ev.stopPropagation()
  }, [])

  return (
    <div className={className}>
      <div className={styles.searchAndSuggestionsBox}>
        <div
          onMouseDown={stopCurtainClick}
          className={cn(styles.aboveCurtain, 'search-above-curtain')}
        >
          <SearchComposer
            onChange={handleInputChange}
            onToggleDatePicker={toggleDatePicker}
            isSearchBoxFocused={isSearchBoxFocused}
            committedSearchQuery={committedSearchQuery}
            onSearchBoxFocus={handleSearchBoxFocused}
            onSearchBoxBlur={handleSearchBoxBlur}
            onSubmit={handleSubmit}
            shouldShowDatePicker={shouldShowDatePicker}
          />
        </div>
      </div>
    </div>
  )
}

export default styled(Search)`
  position: relative;
`
