import React from 'react'
import { useSelector } from 'react-redux'
import LogConversationPage from 'components/App/DesktopView/Pages/LogConversationPage'
import NewConversationPage from 'components/App/DesktopView/Pages/NewConversationPage'
import TicketLayout from 'components/App/DesktopView/Layout/Ticket'
import BulkDetailPane from 'components/App/DesktopView/Bulk/DetailPane'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import {
  selectIsTicketSelectionMode,
  selectTicketSelectionCount,
} from 'selectors/ticket_list'
import { selectPageComponentName } from 'selectors/page/selectPageComponentName'
import ListInspector from '../../ListInspector'

const Switcher = () => {
  const componentName = useSelector(selectPageComponentName)
  const isBulkSelectionMode = useSelector(selectIsTicketSelectionMode)
  const isClassicView = useSelector(selectPrefersClassicView)
  const areTicketsSelected = useSelector(selectTicketSelectionCount) > 0

  if (componentName === 'NewConversationPage') return <NewConversationPage />
  if (componentName === 'LogConversationPage') return <LogConversationPage />

  if (isBulkSelectionMode && !isClassicView) {
    if (!areTicketsSelected) return <ListInspector />

    return <BulkDetailPane />
  }

  return <TicketLayout />
}

export default Switcher
