import React from 'react'
import cn from 'classnames'

import TagList from 'components/TagList'
import EditableSubject from './EditableSubject'
import styles from './styles.css'
import { MessageCount } from './MessageCount'

const TicketHero = ({ ticketId, className }) => {
  return (
    <div className={cn(styles.root, className)}>
      <EditableSubject ticketId={ticketId} />
      <MessageCount ticketId={ticketId} />
      <TagList ticketId={ticketId} className={styles.labels} />
    </div>
  )
}

export default TicketHero
