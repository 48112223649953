import styled from '@emotion/styled'
import cn from 'classnames'
import React from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Negative from '@groovehq/internal-design-system/lib/assets/icons/Negative'
import { buttonTooltipStyle } from './styles'

// Wrap the component with forwardRef to handle the ref
const ButtonDisableWrapper = React.forwardRef(
  ({ className, disabled, children, tooltip: tooltipNode }, ref) => {
    const tooltip = (
      <div css={buttonTooltipStyle}>
        <Negative className="icon required" /> {tooltipNode}
      </div>
    )
    return (
      <Tooltip
        title={tooltip}
        position="top"
        strategy="absolute"
        disabled={!disabled || !tooltipNode}
        maxWidth={400}
      >
        <div className={cn(className, disabled ? 'disabled' : null)} ref={ref}>
          {children}
        </div>
      </Tooltip>
    )
  }
)

// Styled component with the forward ref component
const ButtonDisableWrapperStyled = styled(ButtonDisableWrapper)`
  transition: opacity 200ms ease-in-out;

  &.disabled {
    opacity: 0.5;
    > div {
      pointer-events: none;
    }
  }
`

export default ButtonDisableWrapperStyled
