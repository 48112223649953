import React, { useMemo } from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'

import { fileClass } from 'util/mime'
import { humanFileSize } from 'util/strings'
import { fixDownloadUrl } from 'util/url'
import { Flex, Icon, MindfulText } from 'shared/ui'
import { MAX_PREVIEWABLE_IMAGE_SIZE } from 'util/attachments'
import styles from './styles.css'

class Attachment extends React.PureComponent {
  onDelete = () => {
    const {
      afterDelete,
      doDeleteFile,
      attachment,
      forNote,
      draftId,
    } = this.props
    doDeleteFile(attachment, forNote, draftId)
    if (afterDelete) afterDelete(attachment, forNote, draftId)
  }

  onCancel = () => {
    const { attachment } = this.props
    if (attachment.uploadRequest?.abort) {
      attachment.uploadRequest.abort()
    }
  }

  isSuspicious = () => {
    const { attachment } = this.props

    return attachment?.type === 'SUSPICIOUS_ATTACHMENT'
  }

  render() {
    const {
      isImage,
      isMalicious,
      forDraft,
      isUploading,
      attachment,
      ...props
    } = this.props

    const canShowDeleteButton = forDraft && !isUploading
    const canShowCancelButton =
      forDraft && isUploading && attachment.uploadRequest

    if (isMalicious) {
      return (
        <MaliciousAttachment
          {...props}
          attachment={attachment}
          forDraft={forDraft}
          isUploading={isUploading}
          isSuspicious={this.isSuspicious()}
        />
      )
    }

    // So it turns out all webkit engine browsers (Chrome, Opera, Edge)
    // all shit the bed when a very high resolution image is added to img tag.
    // Works fine on Firefox
    // disable image previews for attachments > limit
    // a better solution would be to get the resolution using the js Image class,
    // but that just means all images will be preloaded + extra processing
    // also using loading="lazy" attribute does not fix this
    if (
      isImage &&
      attachment.fileSize &&
      attachment.fileSize < MAX_PREVIEWABLE_IMAGE_SIZE
    ) {
      return (
        <ImageAttachment
          {...props}
          attachment={attachment}
          forDraft={forDraft}
          isUploading={isUploading}
          canShowDeleteButton={canShowDeleteButton}
          canShowCancelButton={canShowCancelButton}
          onDelete={this.onDelete}
          onCancel={this.onCancel}
          isSuspicious={this.isSuspicious()}
        />
      )
    }

    return (
      <FileAttachment
        {...props}
        attachment={attachment}
        forDraft={forDraft}
        isUploading={isUploading}
        onDelete={this.onDelete}
        onCancel={this.onCancel}
        canShowDeleteButton={canShowDeleteButton}
        canShowCancelButton={canShowCancelButton}
        isSuspicious={this.isSuspicious()}
      />
    )
  }
}

const TooltipTitle = ({
  attachment,
  isSuspicious = false,
  isMalicious = false,
}) => {
  const { fileName, scanReport: { status, matches } = {} } = attachment || {}

  const scanStatusDescription = useMemo(
    () => {
      switch (status) {
        case 'warning':
          return 'This file may contain macros or an encrypted zip.'
        case 'found':
          return 'This file is likely a virus/malware.'
        default:
          return ''
      }
    },
    [status]
  )

  if (isSuspicious || isMalicious) {
    return (
      <>
        {fileName}
        <br />
        {isMalicious && 'Downloading this attachment is disabled'}
        {isSuspicious && 'This attachment is flagged as suspicious'}
        <br />
        {scanStatusDescription}
        <br />
        <br />
        <strong>Scan Report:</strong>
        <br />
        Threat Level: {status}
        <br />
        Scan Findings: {matches.join(', ')}
      </>
    )
  }

  return fileName
}

const MaliciousAttachment = ({ attachment, className }) => {
  const { fileName, scanReport: { status } = {} } = attachment || {}

  const iconColor =
    status === 'found' ? 'color.primary.negative' : 'color.primary.warning'

  return (
    <div
      className={cn(
        className,
        'file',
        'grui cursor-not-allowed',
        styles.attachment,
        styles['attachment-fileAttachment']
      )}
    >
      <Flex
        className={cn(
          'nameWithSize overflow-hidden',
          styles.attachment_nameWithSize
        )}
      >
        <Tooltip
          title={<TooltipTitle attachment={attachment} />}
          className={styles.tooltip}
        >
          <div className="grui flex flex-center">
            <div className="grui truncate">
              <MindfulText as="span" className="hidden-in-print">
                {fileName}
              </MindfulText>
            </div>
            <Icon
              className="grui ml-3"
              size="xsmall"
              color={iconColor}
              name="error"
            />
          </div>
        </Tooltip>
        <FileSize attachment={attachment} />
      </Flex>
    </div>
  )
}

const FileAttachment = ({
  attachment,
  className,
  onDelete,
  onCancel,
  error,
  forDraft,
  forNote,
  isSingle,
  isUploading,
  subdomain,
  isSuspicious,
  canShowCancelButton,
  canShowDeleteButton,
}) => {
  const canShowFileNameWithoutLink = (!isUploading && error) || isUploading
  return (
    <div
      className={cn(
        className,
        'file',
        styles.attachment,
        styles['attachment-fileAttachment'],
        {
          [styles['attachment-uploading']]: isUploading,
          [styles['attachment-forNote']]: forNote,
        }
      )}
    >
      <Flex
        className={cn(
          'nameWithSize overflow-hidden',
          styles.attachment_nameWithSize
        )}
      >
        {(canShowCancelButton || canShowDeleteButton) && (
          <Icon
            name="close"
            size="small"
            className="grui mr-3"
            onClick={canShowCancelButton ? onCancel : onDelete}
          />
        )}
        {!isUploading &&
          !error && (
            <React.Fragment>
              <Tooltip
                title={
                  <TooltipTitle
                    attachment={attachment}
                    isSuspicious={isSuspicious}
                  />
                }
                className={styles.tooltip}
              >
                <div className="grui truncate">
                  <MindfulText
                    as="a"
                    className="hidden-in-print"
                    href={fixDownloadUrl(attachment.downloadUrl, subdomain)}
                    target="_blank"
                    download={fileClass(attachment.fileType) !== 'text'}
                  >
                    {attachment.fileName}
                  </MindfulText>
                </div>
              </Tooltip>
              <MindfulText className="visible-only-in-print" truncate>
                {attachment.fileName}
              </MindfulText>
            </React.Fragment>
          )}
        {canShowFileNameWithoutLink && (
          <MindfulText truncate>{attachment.fileName}</MindfulText>
        )}
        {!isUploading &&
          forDraft &&
          error && (
            <Tooltip title={error.message} disabled={!error.message}>
              <div>
                <Icon
                  className="grui ml-3"
                  size="xsmall"
                  color="color.primary.negative"
                  name="error"
                />
              </div>
            </Tooltip>
          )}
        {isSingle &&
          !forNote &&
          !isSuspicious && (
            <div
              className={cn('Icon Icon-attachment', styles.attachmentIcon)}
            />
          )}
        {isSuspicious && (
          <Icon
            className="grui ml-3"
            size="xsmall"
            color="color.primary.warning"
            name="error"
          />
        )}
        <FileSize isUploading={isUploading} attachment={attachment} />
        {isUploading && <ProgressBar attachment={attachment} />}
      </Flex>
    </div>
  )
}

const ImageAttachment = ({
  attachment,
  className,
  onDelete,
  onCancel,
  error,
  forDraft,
  forNote,
  isUploading,
  onClick,
  isSuspicious,
  canShowCancelButton,
  canShowDeleteButton,
}) => {
  return (
    <div
      className={cn(
        className,
        styles.attachment,
        styles['attachment-imageAttachment'],
        {
          [styles['attachment-uploading']]: isUploading,
          [styles['attachment-forNote']]: forNote,
        }
      )}
    >
      {isUploading && (
        <div
          className={cn(
            'imagePreview',
            styles.attachment_imagePreview,
            styles['attachment_imagePreview-upload']
          )}
        >
          <FileTypeIcon attachment={attachment} />
        </div>
      )}
      {!isUploading &&
        !error && (
          <img
            alt={attachment.fileName}
            className={cn(
              'imagePreview',
              'hidden-in-print',
              styles.attachment_imagePreview
            )}
            onClick={onClick}
            src={attachment.url}
          />
        )}

      {isUploading && (
        <div
          className={cn(
            'nameWithSize grui mb-4',
            styles.attachment_nameWithSize
          )}
        >
          <ProgressBar attachment={attachment} />
          <FileSize isUploading={isUploading} attachment={attachment} />
        </div>
      )}

      <Flex
        className={cn(
          'nameWithSize overflow-hidden',
          styles.attachment_nameWithSize
        )}
      >
        {(canShowDeleteButton || canShowCancelButton) && (
          <>
            <Icon
              name="close"
              className="grui mr-3"
              size="small"
              onClick={canShowCancelButton ? onCancel : onDelete}
            />
            <Tooltip
              title={
                <TooltipTitle
                  attachment={attachment}
                  isSuspicious={isSuspicious}
                />
              }
              className={styles.tooltip}
            >
              <div className="grui overflow-hidden">
                <MindfulText truncate>{attachment.fileName}</MindfulText>
              </div>
            </Tooltip>
          </>
        )}
        {!isUploading && (
          <>
            {!error && (
              <FileSize isUploading={isUploading} attachment={attachment} />
            )}
            {error &&
              forDraft && (
                <Tooltip title={error.message} disabled={!error?.message}>
                  <div>
                    <Icon
                      className="grui ml-3"
                      size="xsmall"
                      color="color.primary.negative"
                      name="error"
                    />
                  </div>
                </Tooltip>
              )}
            {isSuspicious && (
              <Icon
                className="grui ml-3"
                size="xsmall"
                color="color.primary.warning"
                name="error"
              />
            )}
          </>
        )}
      </Flex>
    </div>
  )
}

const FileTypeIcon = ({ attachment }) => {
  return (
    <div
      className={cn(
        styles.attachmentTypeIcon,
        `${styles.attachmentTypeIcon}-${fileClass(attachment.fileType)}`
      )}
    />
  )
}

const FileSize = ({ isUploading, attachment }) => {
  return (
    <div className={cn('size', styles.attachment_size)}>
      {isUploading
        ? `${attachment.progress || 0}%`
        : humanFileSize(attachment.fileSize)}
    </div>
  )
}

const ProgressBar = ({ attachment }) => {
  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBar_bg} />
      <div
        className={styles.progressBar_bar}
        style={{ transform: `scaleX(${attachment.progress / 100})` }}
      />
    </div>
  )
}

export default styled(Attachment)`
  color: ${props => props.theme.color.monochrome.medium};

  .name {
    background: ${props => props.theme.color.primary.brand};
  }

  .imagePreview {
    border: 1px solid ${props => props.theme.color.monochrome.light};
    border-radius: 2px;
    height: 80px;
  }

  .size {
    font-size: ${props => props.theme.fontSize.tiny};
  }

  .nameWithSize {
    font-weight: ${props => props.theme.fontWeight.medium};
  }
`
