import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsPrintModeOn } from 'selectors/app'
import cn from 'classnames'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'

import { SUI } from 'shared/ui'
import ListenToKeyboard from 'components/ListenToKeyboard'
import ScrollerContext from 'components/Scroller/Context'
import { selectTicketEventGroupsByConversationId } from 'ducks/tickets/selectors/selectTicketEventGroupsByConversationId'
import { clearHash } from 'ducks/location/utils'
import usePrevious from 'util/hooks/usePrevious'
import { doRecordErrorAndFetchTicketEventGroups } from 'ducks/tickets/actions/doRecordErrorAndFetchTicketEventGroups'
import AIMessage from './AIMessage'
import withMessageScroller from './withMessageScroller'
import styles from './styles.less'
import EventGroup from './EventGroup'

function cleanChangesetId(hash) {
  const changesetId = hash?.replace(/^#(changeset-)?/, '')
  return changesetId || undefined
}

function calculateGroupIdExpandedState({
  currentGroupIdExpandedState,
  isPrintModeOn,
  wasPrintModeOn,
  eventGroups,
  lastEventGroupId,
  selectedChangesetId,
}) {
  const newGroupIdExpandedState = { ...currentGroupIdExpandedState }
  const mustUpdate = isPrintModeOn || wasPrintModeOn
  eventGroups.forEach((eventGroup, index) => {
    const { id, changesetId } = eventGroup
    const isLast = index >= eventGroups.length - 1
    const isLastMessage = id === lastEventGroupId
    const isRefrencedInUrl = selectedChangesetId === changesetId
    const isExpanded =
      isPrintModeOn ||
      isRefrencedInUrl ||
      (!isRefrencedInUrl && (isLast || isLastMessage))

    newGroupIdExpandedState[id] =
      mustUpdate || newGroupIdExpandedState[id] === undefined
        ? isExpanded
        : newGroupIdExpandedState[id]
  })
  return newGroupIdExpandedState
}

const Changesets = ({ ticketId, onScrollToChangeset }) => {
  const dispatch = useDispatch()
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const previousTicketId = usePrevious(ticketId)
  const isPrintModeOn = useSelector(selectIsPrintModeOn)
  const wasPrintModeOn = usePrevious(isPrintModeOn)
  const eventGroups = useSelector(state =>
    selectTicketEventGroupsByConversationId(state, ticketId)
  )
  const hasScrolled = useRef(false)
  // Get the last message id excluding nodes. The idea here is that
  // the last note and last normal message should both be expanded
  const lastEventGroup = useMemo(
    () => {
      return eventGroups.filter(eg => eg.isNote === false).at(-1)
    },
    [eventGroups]
  )
  const [selectedChangesetId] = useState(cleanChangesetId(window.location.hash))

  const [groupIdExpandedState, setGroupIdExpandedState] = useState(
    calculateGroupIdExpandedState({
      currentGroupIdExpandedState: {},
      isPrintModeOn,
      wasPrintModeOn,
      eventGroups,
      lastEventGroupId: lastEventGroup?.id,
      selectedChangesetId,
    })
  )

  const eventGroupIdByChangesetId = useMemo(
    () =>
      eventGroups.reduce((acc, eg) => {
        // eslint-disable-next-line no-param-reassign
        acc[eg.changesetId] = eg.id
        return acc
      }, {}),
    [eventGroups]
  )

  const { getScrollerAPI } = useContext(ScrollerContext)

  const toggleEventGroupExpanded = useCallback(
    (_e, eventGroupId) => {
      setGroupIdExpandedState(currentGroupIdExpandedState => ({
        ...currentGroupIdExpandedState,
        [eventGroupId]: !currentGroupIdExpandedState[eventGroupId],
      }))
      clearHash()
    },
    [setGroupIdExpandedState]
  )

  const setEventGroupExpanded = useCallback(
    (_e, eventGroupId) => {
      setGroupIdExpandedState(currentGroupIdExpandedState => ({
        ...currentGroupIdExpandedState,
        [eventGroupId]: true,
      }))
      clearHash()
    },
    [setGroupIdExpandedState]
  )

  const handleEventGroupOnLoad = useCallback(
    (eventGroupId, changesetId) => {
      if (
        !hasScrolled.current &&
        (selectedChangesetId === changesetId ||
          (!selectedChangesetId && eventGroupId === lastEventGroup?.id))
      ) {
        hasScrolled.current = true
        onScrollToChangeset(changesetId)
      }
    },
    [onScrollToChangeset, selectedChangesetId, lastEventGroup?.id]
  )

  useEffect(
    () => {
      setGroupIdExpandedState(currentGroupIdExpandedState => {
        return calculateGroupIdExpandedState({
          currentGroupIdExpandedState,
          isPrintModeOn,
          wasPrintModeOn,
          eventGroups,
          lastEventGroupId: lastEventGroup?.id,
          selectedChangesetId,
        })
      })
    },
    [
      eventGroups,
      isPrintModeOn,
      wasPrintModeOn,
      selectedChangesetId,
      lastEventGroup?.id,
      setGroupIdExpandedState,
    ]
  )

  useEffect(
    () => {
      // Define the event listener function
      const handleHashChange = () => {
        const changesetId = cleanChangesetId(window.location.hash)

        const eventGroupId = eventGroupIdByChangesetId[changesetId]
        if (!eventGroupId) return

        setEventGroupExpanded(null, eventGroupId)
        onScrollToChangeset(changesetId)
      }

      // Add the event listener
      window.addEventListener('hashchange', handleHashChange)

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('hashchange', handleHashChange)
      }
    },
    [setEventGroupExpanded, onScrollToChangeset, eventGroupIdByChangesetId]
  )

  useEffect(
    () => {
      if (previousTicketId && previousTicketId !== ticketId) {
        hasScrolled.current = false
      }
    },
    [previousTicketId, ticketId]
  )

  useEffect(
    () => {
      const { collapsed, changesetId } = lastEventGroup || {}
      if (!hasScrolled.current && collapsed === false) {
        hasScrolled.current = true
        onScrollToChangeset(changesetId)
      }
    },
    [hasScrolled, lastEventGroup, onScrollToChangeset]
  )

  useEffect(
    () => {
      if (eventGroups.length === 0) {
        dispatch(doRecordErrorAndFetchTicketEventGroups(ticketId, eventGroups))
      }
    },
    [dispatch, ticketId, eventGroups]
  )

  const changeAll = useCallback(
    isExpanded => {
      setGroupIdExpandedState(currentGroupIdExpandedState => {
        const newGroupIdExpandedState = { ...currentGroupIdExpandedState }
        Object.keys(newGroupIdExpandedState).forEach(
          k => (newGroupIdExpandedState[k] = isExpanded)
        )
        return newGroupIdExpandedState
      })
    },
    [setGroupIdExpandedState]
  )

  const collapseAll = useCallback(
    () => {
      changeAll(false)
    },
    [changeAll]
  )

  const expandAll = useCallback(
    () => {
      changeAll(true)
    },
    [changeAll]
  )

  const handleOnLoading = useCallback(
    () => {
      getScrollerAPI().scrollToBottom()
    },
    [getScrollerAPI]
  )

  return (
    <SUI>
      <div
        className={cn(styles.changesetsContainer, {
          [styles['changesetsContainer-3-column-view']]: is3ColumnView,
        })}
      >
        <ListenToKeyboard
          onSemicolon={expandAll}
          onSemicolonFirefox={expandAll}
          onShiftSemicolon={collapseAll}
          onShiftSemicolonFirefox={collapseAll}
          disableForInput
        />
        {eventGroups.map(eventGroup => {
          const { id } = eventGroup
          return (
            <EventGroup
              key={id}
              eventGroupId={id}
              ticketId={ticketId}
              expanded={!!groupIdExpandedState[id]}
              onClick={toggleEventGroupExpanded}
              onLoad={handleEventGroupOnLoad}
            />
          )
        })}
        <AIMessage
          onLoading={getScrollerAPI() ? handleOnLoading : null}
          className="grui m-5"
        />
      </div>
    </SUI>
  )
}

export default withMessageScroller(Changesets)
