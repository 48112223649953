import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectGroupById,
  selectOpenGroupIds,
} from 'ducks/folders/selectors/groups'
import {
  selectCurrentFolderItemQueryId,
  selectLabelItems,
} from 'ducks/folders/selectors/items'

import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'

import Link from 'redux-first-router-link'
import * as Icons from 'assets/icons/groove/v2'
import { doToggleGroup } from 'ducks/folders/operations/groups'
import SearchRawCount from '../SearchRawCount'
import { styles } from '../styles'

const TagsGroup = props => {
  const { groupId, onClick } = props
  const dispatch = useDispatch()

  const { id, collectionId: channelId, name, icon, channelType } = useSelector(
    state => selectGroupById(state, groupId)
  )

  const currentFolderQueryId = useSelector(selectCurrentFolderItemQueryId)
  const items = useSelector(state =>
    selectLabelItems(state, channelId, channelType)
  )
  const openGroupIds = useSelector(selectOpenGroupIds)
  const open = openGroupIds.includes(id)

  const GroupIcon = Icons[icon]

  const handleClick = useCallback(
    () => {
      if (onClick) onClick(groupId)
      dispatch(doToggleGroup({ groupId }))
    },
    [dispatch, onClick, groupId]
  )

  if (items.length === 0) return null

  return (
    <Menu.Item
      key={groupId}
      icon={<GroupIcon />}
      defaultOpen={open}
      onClick={handleClick}
      hasArrow
      css={styles.item}
      subMenu={items.map(item => {
        const { id: itemId, name: tagName, queryId, linkTo } = item
        const current = queryId === currentFolderQueryId

        return (
          <Menu.Item
            key={itemId}
            as={Link}
            to={linkTo}
            active={current}
            isSubMenuItem
            css={[styles.fixOptionsPosition, styles.item]}
            count={<SearchRawCount queryId={queryId} />}
          >
            {tagName}
          </Menu.Item>
        )
      })}
    >
      {name}
    </Menu.Item>
  )
}

export default TagsGroup
