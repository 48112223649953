import React, { useCallback } from 'react'
import { useController } from 'react-hook-form'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'

const normalizeBoolean = value => {
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  return value
}

const denormalizeBoolean = value => {
  if (value === true) {
    return 'true'
  }
  if (value === false) {
    return 'false'
  }
  return value
}

const RadioWithBoolValue = ({ control, name, value, ...otherProps }) => {
  const { field } = useController({
    name,
    control,
  })
  const handleChange = useCallback(
    e => {
      field.onChange(normalizeBoolean(e.target.value))
    },
    [field]
  )

  return (
    <Radio
      {...field}
      {...otherProps}
      value={denormalizeBoolean(value)}
      onChange={handleChange}
      checked={field.value === value}
    />
  )
}

export default RadioWithBoolValue
