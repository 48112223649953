import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsRequiredConversationFieldsFilled } from 'ducks/crm/contacts/selectors/customFields'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { styles } from './styles'

const EditorButton = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    editorButtonType,
    validateCustomFields,
    type,
    to,
    onClick,
    ...rest
  } = props

  const isCustomFieldsValid =
    useSelector(selectIsRequiredConversationFieldsFilled) ||
    !validateCustomFields
  const overrideType = !isCustomFieldsValid && 'warningOutline'
  const additionalButtonStyle =
    isCustomFieldsValid &&
    editorButtonType === 'note' &&
    styles.selectorNoteButton

  return (
    <Tooltip
      title="Please complete custom fields"
      strategy="absolute"
      position="top"
      disabled={isCustomFieldsValid}
    >
      <Button
        {...rest}
        to={isCustomFieldsValid ? to : null}
        onClick={isCustomFieldsValid ? onClick : null}
        type={overrideType || type}
        className={className}
        css={[styles.selectorButton, additionalButtonStyle]}
        ref={ref}
      >
        {children}
      </Button>
    </Tooltip>
  )
})

export default EditorButton
