import React from 'react'
import BasicEditor from 'shared/editor/Basic'
import { editorStyle } from 'components/App/DesktopView/shared/EditorStyles/styles'

require('tinymce/plugins/autoresize/plugin')
require('shared/editor/plugins/groove-before-set-content-cleanup')
require('shared/editor/plugins/groove-paste-cleanup')

const fixupPlainTextDraft = plain => {
  if (!plain) return ''
  return plain.replace(/\n/g, '<br />')
}

class FacebookEditor extends React.Component {
  shouldComponentUpdate(nextProps) {
    // HACK (jscheel): Editor was re-rendering when it shouldn't, so we are being
    // a bit more heavy-handed and explicit that nothing should be changing.
    const keys = Object.keys(this.props)
    // eslint-disable-next-line no-plusplus
    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i]
      if (this.props[key] !== nextProps[key]) {
        return true
      }
    }

    // We change the content key when you swop between tickets. This should
    // then trigger a re-render where in the componentDidUpdate method we use
    // the tinymce api to update the current content of the editor
    // Ultimately we want to be able to re-use our tinymce instance for all tickets
    if (this.props.contentKey !== nextProps.contentKey) {
      return true
    }
    if (this.props.contentKeyExpansion !== undefined) {
      const expansionWas = this.props.contentKeyExpansion
      const expansionIs = nextProps.contentKeyExpansion
      if (!expansionIs && expansionWas) return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { contentKey, content } = this.props
    if (contentKey !== prevProps.contentKey) {
      // Tinymce doesnt support setting the content to null. When its null
      // we should just set it to empty
      this.updateEditorContent(content || '')
    }
    if (this.props.contentKeyExpansion !== undefined) {
      const expansionWas = prevProps.contentKeyExpansion
      const expansionIs = this.props.contentKeyExpansion
      const currentContent = this.editor && this.editor.getContent()
      const element = document.activeElement
      // we just want from draft to no draft, we need to clear
      if (!expansionIs && expansionWas) {
        this.updateEditorContent(content || '')
      }
      // When switching between drafts we need to update the content
      if (!!expansionIs && !!expansionWas && expansionIs !== expansionWas) {
        this.updateEditorContent(content || '')
      }
      if (element && element.id.match('tiny-react')) {
        return // we're focused in the editor, don't override it
      }
      if (currentContent === content) return // no need, short circuit
      // we went from no draft to new draft
      if (expansionIs && !expansionWas && !prevProps.content) {
        this.updateEditorContent(content || '')
        // put caret at end of text
        try {
          this.editor.selection.select(this.editor.getBody(), true)
          this.editor.selection.collapse(false)
          window.focus()
        } catch (e) {
          // pass
        }
      }
    }
  }

  onChange = (content, options) => {
    if (this.editorRef) {
      const plain = this.getPlainText()
      this.props.onChange(plain, options)
    }
  }

  getPlainText = () => {
    return this.editorRef.getBody().innerText
  }

  updateEditorContent = content => {
    if (this.editorRef) {
      this.editorRef.setContent(content)
    } else {
      setTimeout(() => {
        this.updateEditorContent(content)
      }, 50)
    }
  }

  saveRef = node => {
    const { forwardRef } = this.props
    this.editorRef = node
    if (forwardRef) forwardRef(node)
  }

  render() {
    const { config, content, ...rest } = this.props
    const plugins = 'paste autoresize groove-inline-placeholder'

    return (
      <BasicEditor
        css={editorStyle}
        config={{
          autoresize_bottom_margin: 20,
          autoresize_min_height: 100,
          autoresize_on_init: true,
          body_class: 'editor-facebook',
          plugins,
          resize: false,
          toolbar: false,
          elementpath: false,
          valid_elements: 'p,br,strong/b',
          forced_root_block: false,
          ...config,
        }}
        forwardRef={this.saveRef}
        initialValue={fixupPlainTextDraft(content)}
        onChange={this.onChange}
        placeholder={'Type your reply here...'}
        inline
        {...rest}
      />
    )
  }
}

export default FacebookEditor
