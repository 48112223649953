import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Flex } from 'shared/ui'
import { emptyArr } from 'util/arrays'
import Option from './Option'
import styles from './styles.less'

const FilterBar = ({
  className,
  shouldShowOptions,
  isWide,
  options = emptyArr,
}) => {
  if (!shouldShowOptions) {
    return null
  }
  return (
    <Flex
      className={cn(
        'px-12',
        styles['filter-bar'],
        isWide && styles['filter-bar-wide'],
        className
      )}
    >
      <div className="filters">
        {shouldShowOptions &&
          options.map(option => (
            <Option key={option.queryId} option={option} />
          ))}
      </div>
    </Flex>
  )
}

FilterBar.propTypes = {
  shouldShowOptions: PropTypes.bool.isRequired,
  isWide: PropTypes.bool,
}

FilterBar.defaultProps = {
  shouldShowOptions: false,
  isWide: false,
}

FilterBar.displayName = 'FilterBar'

export default FilterBar
