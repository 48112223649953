/* eslint-disable jsx-a11y/label-has-for */

import React from 'react'
import cn from 'classnames'
import { css } from '@emotion/react'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { withTicketPreview } from 'ducks/ticketing/components'
import { propFunc } from 'util/functions'
import { styles as checkboxStyles } from 'components/ConversationList/styles'
import TextSearchMarker from 'components/TextSearchMarker'
import {
  snooze as SnoozeIcon,
  edit as EditIcon,
  file as FileIcon,
  arrowLeft as ArrowLeft,
} from 'assets/icons/groove/v2'
import { LONGEST_UNANSWERED } from 'constants/defaults'
import LabelBox from './LabelBox'

const Preview = withTicketPreview(
  ({
    delayedShowTicketPreviewModal,
    cancelDelayedShowTicketPreviewModal,
    children,
  }) => {
    return (
      <span
        onMouseMove={delayedShowTicketPreviewModal}
        onMouseLeave={cancelDelayedShowTicketPreviewModal}
        onClick={cancelDelayedShowTicketPreviewModal}
      >
        {children}
      </span>
    )
  }
)

const mailboxIndicator = mailboxColor => css`
  background-color: ${mailboxColor};
`

const avatarComponent = agent => {
  if (!agent) return null
  const avatarUrl = agent.avatarUrl
  const actorInitials = agent.initials
  const styles = { backgroundImage: `url(${avatarUrl})` }
  return (
    <div className="avatar" style={styles}>
      {!avatarUrl && actorInitials}
    </div>
  )
}

class InnerListItem extends React.PureComponent {
  onClick = e => {
    const { conversationId, navigateToConversation, draftType } = this.props
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    e.stopPropagation()
    e.preventDefault()
    navigateToConversation(conversationId, draftType)
    return true
  }

  onCheckboxClick = () => {
    const { conversationId, toggleConversationSelection } = this.props
    toggleConversationSelection(conversationId)
  }

  onCloseOrOpenClick = e => {
    const {
      conversationId,
      isCloseable,
      openConversation,
      closeConversation,
    } = this.props
    e.stopPropagation()
    // eslint-disable-next-line no-unused-expressions
    isCloseable
      ? closeConversation(conversationId)
      : openConversation(conversationId)
  }

  onUnreadIndicatorClick = e => {
    const { conversationId, isUnread, markAsRead, markAsUnread } = this.props
    e.stopPropagation()
    // eslint-disable-next-line no-unused-expressions
    isUnread ? markAsRead(conversationId) : markAsUnread(conversationId)
  }

  render() {
    if (!this.props.present) return null
    const {
      conversationId,
      assignmentLabel,
      customerLabelFull,
      messageCount,
      subject,
      subjectEmptyPlaceholderText,
      snippetText,
      snippetAuthor,
      snippetType,
      timestamp,
      labelIds,
      hasCollision,
      collidingAgent,
      collisionType,
      collisionOperation,
      hasAttachments,
      selected,
      isStarred,
      hasDraft,
      isSnoozed,
      snoozedUntilLabel,
      canBulkSelect = true,
      isUnread,
      mailboxColor,
      linkUrl,
      shouldShowLabels,
      labelsJSON,
      conversationPreviewOnHoverEnabled,
      currentSortOrder,
      unansweredSinceLabel,
      unansweredSinceDurationLevel,
    } = this.props

    const isUnansweredSort = LONGEST_UNANSWERED === currentSortOrder
    const showUnansweredSinceLabel = !!unansweredSinceLabel && isUnansweredSort

    return (
      <>
        {mailboxColor && (
          <div className="mailbox-indicator">
            <div
              className="inner"
              css={propFunc(mailboxIndicator, mailboxColor)}
            />
          </div>
        )}
        <div className="sui inner">
          <div className="left">
            {canBulkSelect && (
              <>
                <Checkbox
                  id={`conversation-${conversationId}`}
                  onChange={this.onCheckboxClick}
                  checked={selected}
                  withRadius
                  css={[
                    checkboxStyles.checkbox,
                    isUnread && checkboxStyles.checkboxInUnreadTicket,
                  ]}
                />
                <div
                  className="unread-indicator"
                  onClick={this.onUnreadIndicatorClick}
                />
              </>
            )}
          </div>
          <a href={linkUrl} onClick={this.onClick}>
            <div className="row top">
              <div className="header">
                <Preview
                  ticketId={conversationId}
                  className="preview"
                  disabled={!conversationPreviewOnHoverEnabled}
                  eventOffsetLeft={30}
                >
                  <span className="customer">{customerLabelFull}</span>
                </Preview>
                {isStarred && <span className="Icon Icon-star" />}
                {hasAttachments && <span className="Icon Icon-attachment" />}
              </div>
              <div className="message">
                <span className={cn('subject', { empty: !subject })}>
                  {subject ? (
                    <TextSearchMarker text={subject} />
                  ) : (
                    subjectEmptyPlaceholderText
                  )}
                </span>
                {messageCount > 0 && (
                  <div className="count">
                    {messageCount > 99 ? '99+' : messageCount}
                  </div>
                )}
                {shouldShowLabels && (
                  <LabelBox
                    isUnread={isUnread}
                    labelsJSON={labelsJSON}
                    labelIds={labelIds}
                  />
                )}
              </div>
              <div className="right-side">
                <span className="datetime">{timestamp}</span>
              </div>
            </div>
            <div className="row">
              <div className="header">
                {!hasCollision && (
                  <span className="assignment">
                    {assignmentLabel || 'Unassigned'}
                  </span>
                )}
                {hasCollision && (
                  <span className={['collision', collisionType].join(' ')}>
                    {avatarComponent(collidingAgent)}
                    <span className="chip">
                      <span className="name">
                        {collidingAgent && collidingAgent.shortName}
                      </span>{' '}
                      {collisionOperation}
                    </span>
                  </span>
                )}
              </div>
              <div className="message">
                {showUnansweredSinceLabel && (
                  <span
                    className={cn(
                      'unanswered-since',
                      unansweredSinceDurationLevel
                    )}
                  >
                    {unansweredSinceLabel}
                  </span>
                )}
                {isSnoozed && (
                  <span className="snoozeduntil">
                    <SnoozeIcon className="icon snooze" />
                    {snoozedUntilLabel}
                  </span>
                )}
                <span className={['snippet', snippetType].join(' ')}>
                  {!hasDraft &&
                    snippetAuthor && (
                      <span className="author">
                        {snippetType &&
                          snippetType === 'internal' && (
                            <FileIcon className="icon note" />
                          )}
                        <span>{snippetAuthor}</span>
                        {snippetType === 'internal' ? (
                          <span>: </span>
                        ) : (
                          <ArrowLeft className="arrow" />
                        )}
                      </span>
                    )}
                  {hasDraft && (
                    <span className="author">
                      <EditIcon className="icon draft" />Draft:{' '}
                    </span>
                  )}
                  <span className={cn('snippetText', { empty: !snippetText })}>
                    {snippetText ? (
                      <TextSearchMarker text={snippetText} />
                    ) : (
                      <span>There is no content in this message</span>
                    )}
                  </span>
                </span>
              </div>
              <div className="right-side">
                <span className="conversation-number">#{conversationId}</span>
              </div>
            </div>
          </a>
        </div>
      </>
    )
  }
}

export default InnerListItem
