import { doOpenSearchPage } from 'actions/pages/doOpenSearchPage'
import { selectCurrentFilterQuery } from 'ducks/searches/selectors'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tag from '@groovehq/internal-design-system/lib/components/Tag/Tag'
import { styles } from '../styles'

export default function FilterBadge({ queryKey, queryValue, amendedValue }) {
  const dispatch = useDispatch()
  const currentQuery = useSelector(selectCurrentFilterQuery)

  const handleRemoveFilter = useCallback(
    () => {
      const updatedQuery = { ...currentQuery }
      if (Array.isArray(updatedQuery[queryKey])) {
        updatedQuery[queryKey] = updatedQuery[queryKey].filter(
          v => v !== queryValue
        )
      } else {
        delete updatedQuery[queryKey]
      }
      dispatch(
        doOpenSearchPage('', { query: updatedQuery, ignoreTicketId: true })
      )
    },
    [dispatch, currentQuery, queryKey, queryValue]
  )

  return (
    <Tag closable css={styles.filterBadge} onClose={handleRemoveFilter}>
      {queryKey}: {amendedValue}
    </Tag>
  )
}
