// Shared Layout for all pages that show a ticket detail pane
import { useSelector } from 'react-redux'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import ListInspector from '../ListInspector'
import TicketInspector from '../TicketInspector'

const Page = () => {
  const ticketId = useSelector(selectCurrentTicketId)
  if (ticketId) return <TicketInspector ticketId={ticketId} />

  return <ListInspector />
}

export default Page
