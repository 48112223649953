import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const heading = theme => css`
  font-size: ${theme.fontSize.x6large};
  font-weight: ${theme.fontWeight.bold};
  line-height: 1.25;

  @media (min-width: 1024px) {
    font-size: ${rem(72)};
    font-weight: ${theme.fontWeight.fontWeight};
  }
`

const subheading = css`
  margin-top: ${rem(22)};
  font-size: ${rem(19)};

  @media (min-width: 768px) {
    font-size: ${rem(26)};
  }
`

const headingContainer = css`
  max-width: ${rem(760)};
  margin: 0 auto;
  text-align: center;
`

const tag = theme => css`
  border-radius: ${rem(4)};
  background-color: rgb(220 252 231);
  color: rgb(34 197 94);
  padding: ${rem(6)} ${rem(8)};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
`

const reportingWrapper = css`
  max-width: ${rem(972)};
  width: 100%;
  margin: ${rem(40)} auto ${rem(100)};
`

const arrowWrapper = css`
  top: 20%;
  left: -20px;
  transform: translate(-100%, -50%);
  display: none;

  @media (min-width: 1380px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  svg {
    width: ${rem(100)};
    height: auto;
    transform: rotate(90deg);
  }
`

export const styles = {
  heading,
  subheading,
  headingContainer,
  tag,
  reportingWrapper,
  arrowWrapper,
}
