import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutogrowingTextarea from 'components/AutogrowingTextarea'
import { Icon } from 'shared/ui'
import { ENTER, ESCAPE } from 'constants/keys'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'

import {
  doTicketTitleDraftChanged,
  doStopEditingTicketTitle,
} from 'actions/app'
import { doUpdateTitle } from 'ducks/tickets/actions/doUpdateTitle'
import { styles } from './styles'

const EditableSubjectEditingState = ({ ticketId }) => {
  const dispatch = useDispatch()
  const { subject: title } = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )

  const updateTitle = useCallback(
    () => {
      dispatch(doUpdateTitle(ticketId))
    },
    [dispatch, ticketId]
  )

  const stopEditingTicketTitle = useCallback(
    () => {
      dispatch(doStopEditingTicketTitle(ticketId))
    },
    [dispatch, ticketId]
  )

  const handleOnKeyDown = useCallback(
    e => {
      if (e.keyCode === ENTER) updateTitle()
      if (e.keyCode === ESCAPE) stopEditingTicketTitle()
    },
    [updateTitle, stopEditingTicketTitle]
  )

  const handleInput = useCallback(
    e => {
      const input = e.target
      dispatch(doTicketTitleDraftChanged(input.value))
    },
    [dispatch]
  )

  return (
    <div css={styles.container}>
      <AutogrowingTextarea
        css={styles.input}
        autoFocus
        defaultValue={title}
        spellCheck="false"
        autoCorrect="off"
        autoCapitalize="off"
        onBlur={stopEditingTicketTitle}
        onInput={handleInput}
        onKeyDown={handleOnKeyDown}
      />
      <Icon name="keyboard-return" size="large" onMouseDown={updateTitle} />
    </div>
  )
}

export default EditableSubjectEditingState
