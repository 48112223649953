import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectMailboxByStates } from 'ducks/mailboxes/selectors/selectMailboxByStates'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { CHANNEL_STATE, CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_EMAIL_FORWARD } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import EmptyInboxFooter from '../EmptyPage/Footer'
import { styles } from './styles'

const MailboxActivationFooter = ({ completed, onClickCreateBtn }) => {
  const ticketListMailboxId = useSelector(selectCurrentMailboxId)
  const unconfirmedForwardingMailboxes = useSelector(state =>
    selectMailboxByStates(state, [CHANNEL_STATE.UNCONFIRMED], null, [
      CHANNEL_TYPE.FORWARDING,
    ])
  )
  const firstMailbox = unconfirmedForwardingMailboxes[0] || {}
  const currentMailboxId = unconfirmedForwardingMailboxes.some(
    mailbox => mailbox.id === ticketListMailboxId
  )
    ? ticketListMailboxId
    : firstMailbox.id

  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
    lazyLoad: true,
  })

  const onOpen = useCallback(
    () => {
      openAddDrawer(currentMailboxId, {
        query: {
          ...buildDrawerQueryParam(
            drawerId,
            'drawerChannelType',
            CHANNEL_TYPE.FORWARDING
          ),
        },
      })
    },
    [openAddDrawer, currentMailboxId, drawerId]
  )

  if (completed || !currentMailboxId) {
    return <EmptyInboxFooter onClickCreateBtn={onClickCreateBtn} />
  }

  // Continue to finish forwarding setup for the current mailbox
  return (
    <Button
      type="info"
      className="grui px-14 mt-10"
      css={styles.actionBtn}
      onClick={onOpen}
    >
      Complete setup
    </Button>
  )
}

export default MailboxActivationFooter
