import IntegrationActionContentGitHub, {
  shouldShowAction as shouldShowGitHubAction,
} from './IntegrationActionContentGitHub'
import IntegrationActionContentJiraCloud, {
  shouldShowAction as shouldShowJiraAction,
} from './IntegrationActionContentJiraCloud'
import IntegrationActionContentJiraServer from './IntegrationActionContentJiraServer'
import IntegrationActionContentTrello, {
  shouldShowAction as shouldShowTrelloAction,
} from './IntegrationActionContentTrello'

const PROVIDER_COMPONENTS = {
  GITHUB: IntegrationActionContentGitHub,
  JIRA_CLOUD: IntegrationActionContentJiraCloud,
  JIRA_SERVER: IntegrationActionContentJiraServer,
  TRELLO: IntegrationActionContentTrello,
}

const SHOULD_SHOW_ACTION = {
  GITHUB: shouldShowGitHubAction,
  JIRA_CLOUD: shouldShowJiraAction,
  JIRA_SERVER: shouldShowJiraAction,
  TRELLO: shouldShowTrelloAction,
}

export function shouldShowIntegrationAction(action, settings) {
  const { change: { provider } = {} } = action
  const func = SHOULD_SHOW_ACTION[provider]
  if (func) return func(action, settings[provider])
  return true
}

const PROVIDERS = {
  'g.integration.github.external': 'github',
  'g.integration.jira_cloud.external': 'jira_cloud',
  'g.integration.jira_server.external': 'jira_server',
  'g.integration.trello.external': 'trello',
}
export function getActionFromMxEvent(event) {
  const content = event.getContent()
  const provider = PROVIDERS[event.getType()]
  return {
    change: {
      action: content,
      provider,
    },
  }
}

export function shouldShowIntegrationMxEvent(event, settings) {
  return shouldShowIntegrationAction(getActionFromMxEvent(event), settings)
}

export default function IntegrationExternalAction({
  action,
  action: { change: { provider } = {} },
  ...rest
}) {
  const ProviderIntegrationActionContent = PROVIDER_COMPONENTS[provider]
  if (ProviderIntegrationActionContent) {
    return <ProviderIntegrationActionContent action={action} {...rest} />
  }
  return null
}
