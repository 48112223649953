import { connect } from 'react-redux'
import { selectKnownMailboxes } from 'ducks/mailboxes/selectors/selectKnownMailboxes'
import { selectIsLoggingNewConversation } from 'selectors/page'
import FromRow from './view'

const select = state => ({
  mailboxes: selectKnownMailboxes(state),
  isLogging: selectIsLoggingNewConversation(state),
})

export default connect(select, null)(FromRow)
