import { useCallback, useEffect, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchTagsV2ByIdsOrNames } from 'ducks/tags/actions'
import { capture } from 'ducks/tracking/actions'
import { buildId } from 'util/globalId'
import { doAddTags } from 'ducks/tickets/actions/doAddTags'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectCurrentTagsByIds } from 'ducks/tags/selectors'

import Tag from './Tag'
import { styles as baseStyles } from '../styles'
import { styles } from './styles'

const tagsForLoadingState = [
  // Random text for showing different length of tags
  'billing',
  'sales',
  'upgrade',
  'premium',
  'whitelabel',
  'enterprise',
].map((value, index) => ({
  id: index + 1,
  name: value,
}))

const SuggestedTags = ({
  ticketId,
  suggestion,
  isLoading,
  isLoadingTagNames,
  isLoadedTagNames,
}) => {
  const dispatch = useDispatch()
  const ticketGid = buildId('Conversation', ticketId)
  const { tags: ticketTagIds } = useSelector(
    state => selectCurrentConversationById(state, ticketId) || {}
  )
  const canShowLoadingState = isLoading || isLoadingTagNames

  useEffect(
    () => {
      if (
        !isLoading &&
        !isLoadingTagNames &&
        !isLoadedTagNames &&
        suggestion?.value?.length
      ) {
        dispatch(doFetchTagsV2ByIdsOrNames(suggestion.value))
      }
    },
    [dispatch, suggestion, isLoadingTagNames, isLoadedTagNames, isLoading]
  )

  const labelsFromStore = useSelector(state =>
    selectCurrentTagsByIds(state, suggestion?.value)
  )

  const onClick = useCallback(
    tag => {
      capture('ai tag selected', {
        ticket_id: ticketGid,
      })
      dispatch(doAddTags(ticketId, [tag.id]))
    },
    [dispatch, ticketId, ticketGid]
  )

  const tags = useMemo(
    () => {
      if (canShowLoadingState) {
        return tagsForLoadingState
      }

      if (!labelsFromStore) {
        return []
      }

      return labelsFromStore.filter(tag => !ticketTagIds.includes(tag.id))
    },
    [labelsFromStore, ticketTagIds, canShowLoadingState]
  )

  if (!suggestion?.id && !isLoading) {
    return null
  }

  return (
    <div
      className="grui flex flex-col mt-10"
      css={canShowLoadingState && baseStyles.loadingState}
    >
      <span css={baseStyles.actionTitle}>Suggested Tags</span>

      {tags.length ? (
        <div className="grui flex flex-row mt-10" css={styles.tagsList}>
          {tags.map(tag => (
            <Tag
              key={tag.id}
              tag={tag}
              onClick={onClick}
              isLoading={isLoading}
            />
          ))}
        </div>
      ) : (
        <span className="grui mt-10" css={styles.emptyText}>
          No tags
        </span>
      )}
    </div>
  )
}

export default memo(SuggestedTags)
