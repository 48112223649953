import React from 'react'
import { css } from '@emotion/react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { Flex, Label, IconButton } from 'shared/ui'
import { pluralize } from 'util/strings'
import SpacedItems from 'shared/components/ui/SpacedItems'
import BulkStateBadge from 'components/StateBadge/Bulk'
import BulkAssignmentBadge from 'components/App/DesktopView/shared/AssignmentDropdown/Badge/Bulk'
import ChangeInboxDropdown from 'components/App/DesktopView/shared/ChangeInboxDropdown'
import BulkStarIcon from 'components/Bulk/StarIcon'
import BulkSnoozeIcon from 'components/App/DesktopView/Bulk/DetailPane/SnoozeIcon'
import BulkTagIcon from 'components/App/DesktopView/Labels/LabelingDropdown/Bulk'
import { selectSearchEntityIdsByCurrentQueryId } from 'ducks/searches/selectors'
import { styles as checkboxStyles } from 'components/ConversationList/styles'
import styles from './styles.less'

const flexStyle = () => theme => css`
  min-height: 42px;
  padding: ${`${theme.spacing['3']} ${theme.spacing['10']}`};
`

export default function BulkActionBar(props) {
  const {
    onUnselectAllClick,
    onSelectAllClick,
    onMarkAsUnreadClick,
    onMarkAsSpamClick,
    onDeleteClick,
    conversationIds,
    selectedCount,
  } = props

  const ticketIds = useSelector(selectSearchEntityIdsByCurrentQueryId) || []
  const isAllSelected = ticketIds.length === selectedCount

  return (
    <Flex
      css={flexStyle}
      className="grui items-center conversation-list-header-title-bar"
    >
      <Checkbox
        checked={isAllSelected}
        indeterminate={!isAllSelected}
        id="conversation-group-selection"
        onChange={isAllSelected ? onUnselectAllClick : onSelectAllClick}
        css={checkboxStyles.checkbox}
        withRadius
      />
      <SpacedItems.Container
        direction="horizontal"
        verticalAlign="center"
        horizontalAlign="center"
        gap="mini"
        className={cn(styles.BulkActionBarContainer, 'grui ml-10')}
      >
        <SpacedItems.Item>
          <BulkStateBadge variant="withIcon" />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <BulkStarIcon iconSize={22} />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <BulkTagIcon key={`bulk-tag`} />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <BulkSnoozeIcon conversationIds={conversationIds} iconSize={16} />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <IconButton
            name="delete"
            size="small"
            iconSize={20}
            onClick={onDeleteClick}
            tooltip="Move to Trash"
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <IconButton
            name="error-outline"
            size="small"
            iconSize={19}
            onClick={onMarkAsSpamClick}
            tooltip="Mark as Spam"
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <IconButton
            name="mail"
            size="small"
            iconSize={20}
            onClick={onMarkAsUnreadClick}
            tooltip="Mark as Unread"
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <ChangeInboxDropdown
            ticketIds={conversationIds}
            trigger={
              <IconButton
                name="moveToInbox"
                svg
                size="small"
                iconSize={20}
                tooltip={`Change ${app.t('Mailbox')}`}
              />
            }
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <BulkAssignmentBadge iconSize={22} />
        </SpacedItems.Item>
        <SpacedItems.Item gap="auto">
          <Label size="small">
            {selectedCount} {pluralize(selectedCount, 'conversation')} selected
          </Label>
        </SpacedItems.Item>
      </SpacedItems.Container>
    </Flex>
  )
}
