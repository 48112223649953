import { selectConversationEventById } from 'ducks/tickets/selectors'
import { useSelector } from 'react-redux'
import ByLine from './ByLine'

export default function Action({ eventGroupId, eventId }) {
  const { formattedCreatedAt } = useSelector(state =>
    selectConversationEventById(state, eventId)
  )

  return (
    <ByLine eventGroupId={eventGroupId} eventId={eventId}>
      {byLine => {
        if (!byLine) return null

        return (
          <div id={eventId} className="action subsequent">
            <div className="contents">
              <div className="header">
                <div className="author">{byLine}</div>

                <div className="right-side">
                  <div className="datetime">{formattedCreatedAt}</div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </ByLine>
  )
}
