import React, {
  Fragment,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'

import Scroller from 'components/Scroller'
import DefaultListItem from 'components/ConversationList/ListItem'
import ListenToKeyboard from 'components/ListenToKeyboard'

import { Loader } from 'shared/ui'
import { debounce } from 'util/functions'
import debug from 'util/debug'

import { isSafari10 } from 'util/browser'

import InvalidSearchMessage from 'components/App/Pages/SearchPage/InvalidSearchMessage'
import SearchErrorMessage from 'components/App/Pages/SearchPage/SearchErrorMessage'
import { isQueryIdValid } from 'ducks/searches/utils/query'
import MailboxContinueForwardingSetupBar from 'components/App/DesktopView/Layout/MailboxContinueForwardingSetupBar'
import { selectDoesCurrentMailboxHaveState } from 'ducks/mailboxes/selectors/selectDoesCurrentMailboxHaveState'
import { selectIsSettingUpTheCurrentMailBox } from 'ducks/mailboxes/selectors/selectIsSettingUpTheCurrentMailBox'
import { CHANNEL_STATE, CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { selectShowContinueForwardingSetupPane } from 'selectors/layout'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'

import DefaultEmptyState from './EmptyState'
import ListLoaded from './ListLoaded'
import EndIndicator from './EndIndicator'
import styles from './styles.less'

function getHeightStyle() {
  if (!isSafari10()) return {}

  const header = document.querySelector('[class*="ConversationListHeader_"]')
  if (!header) return {}

  const headerHeight = header.getBoundingClientRect().height
  return { height: `calc(100% - ${headerHeight}px)` }
}

const ConversationList = ({
  currentQueryId,
  loadMore,
  hasLoaded: inputHasLoaded,
  hasErrored,
  is3ColumnView,
  isLoadingMore,
  isRefreshing,
  isSelectionMode,
  isSidebarOpen,
  selectedConversationIds,
  conversationIds,
  onListLoaded,
  ListItem = DefaultListItem,
  EmptyState = DefaultEmptyState,
  canLoadMore,
  currentSortOrder,
  footer,
  currentConversationId,
  onComponentDidMount,
  currentPage,
  totalPages,
  navigateToConversation,
}) => {
  const hasLoaded = !isRefreshing && inputHasLoaded
  const scrollerRef = useRef(null)

  const conversationIdsLessThanThreshold = conversationIds.length <= 15

  const [canAutoFill, setCanAutoFill] = useState(true)
  const hasMoreItemsToLoad = useMemo(
    () => {
      const hasConversations = conversationIds.some(e => e)
      const hasMore = hasConversations && currentPage < totalPages

      return !!isLoadingMore || !!hasMore
    },
    [conversationIds, currentPage, isLoadingMore, totalPages]
  )

  const navigateTo = useMemo(
    () => {
      return debounce(nextConversationId => {
        navigateToConversation(nextConversationId)
      }, 50)
    },
    [navigateToConversation]
  )

  const moveUp = useCallback(
    () => {
      const index = conversationIds.indexOf(currentConversationId)
      const length = conversationIds.length
      let nextConversationId
      if (index === 0) {
        nextConversationId = conversationIds[length - 1]
      } else {
        nextConversationId = conversationIds[index - 1]
      }
      if (nextConversationId) navigateTo(nextConversationId)
    },
    [conversationIds, currentConversationId, navigateTo]
  )

  const moveDown = useCallback(
    () => {
      const index = conversationIds.indexOf(currentConversationId)
      const length = conversationIds.length
      let nextConversationId
      if (index === length - 1) {
        if (!hasMoreItemsToLoad) {
          nextConversationId = conversationIds[0]
        }
      } else {
        nextConversationId = conversationIds[index + 1]
      }
      if (nextConversationId) navigateTo(nextConversationId)
    },
    [conversationIds, currentConversationId, navigateTo, hasMoreItemsToLoad]
  )

  const isQueryValid = useMemo(() => isQueryIdValid(currentQueryId), [
    currentQueryId,
  ])
  const hasConversations = conversationIds.some(e => e)
  const triggerListLoaded =
    (hasConversations && !isLoadingMore) || isSidebarOpen
  const hasMore = hasConversations && canLoadMore

  useEffect(() => {
    if (onComponentDidMount) {
      onComponentDidMount(this)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (
        canAutoFill &&
        hasMore &&
        !isLoadingMore &&
        conversationIdsLessThanThreshold
      ) {
        debug('Conversation list under threshold, loading more')
        setCanAutoFill(false)
        loadMore()
      }
    },
    [
      hasMore,
      isLoadingMore,
      loadMore,
      conversationIdsLessThanThreshold,
      canAutoFill,
    ]
  )

  useEffect(
    () => {
      if (!isLoadingMore && !hasErrored) {
        setCanAutoFill(true)
      }
    },
    [isLoadingMore, hasErrored]
  )

  const isCurrentMailboxConfirmedOrUnconfirmed = useSelector(state =>
    selectDoesCurrentMailboxHaveState(
      state,
      [CHANNEL_STATE.UNCONFIRMED],
      null,
      [CHANNEL_TYPE.FORWARDING]
    )
  )
  const isSettingUpTheCurrentMailBox = useSelector(
    selectIsSettingUpTheCurrentMailBox
  )
  const showContinueForwardingSetupPane = useSelector(
    selectShowContinueForwardingSetupPane
  )
  const isSearchesLoaded = hasLoaded && !hasErrored
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)

  const showMailboxContinueForwardingSetupBar =
    is3ColumnView &&
    isCurrentMailboxConfirmedOrUnconfirmed &&
    !isSettingUpTheCurrentMailBox &&
    !showContinueForwardingSetupPane &&
    isSearchesLoaded &&
    isManager

  return (
    <Fragment>
      {showMailboxContinueForwardingSetupBar && (
        <MailboxContinueForwardingSetupBar />
      )}
      <ListenToKeyboard
        onUp={moveUp}
        onDown={moveDown}
        onJ={moveUp}
        onK={moveDown}
        preventDefault
        disableForInput
      />
      <div className="ConversationList" style={getHeightStyle()}>
        {hasErrored && <SearchErrorMessage />}
        {!hasErrored && !isQueryValid && <InvalidSearchMessage />}
        {!hasLoaded &&
          !hasErrored &&
          isQueryValid && <Loader className={styles.loader} />}
        {!hasConversations && hasLoaded && <EmptyState />}
        {hasLoaded &&
          isQueryValid &&
          hasConversations && (
            <Scroller
              ref={scrollerRef}
              loadMore={loadMore}
              hasMore={hasMore}
              loadingMore={isLoadingMore}
              loader={<Loader className={styles.loader} />}
              footer={footer}
              endIndicator={<EndIndicator key={'conversation-end-indicator'} />}
              className={cn(
                'ConversationListItems',
                'desktopNormalScrollbarDisableRightBorder',
                {
                  'ConversationListItems--bulk-select-on': !!isSelectionMode,
                  [styles.isLoadingMore]: isLoadingMore,
                }
              )}
            >
              {conversationIds
                .filter(e => e)
                .map(conversationId => (
                  <ListItem
                    key={conversationId}
                    conversationId={conversationId}
                    selected={
                      selectedConversationIds.indexOf(conversationId) > -1
                    }
                    is3ColumnView={is3ColumnView}
                    currentSortOrder={currentSortOrder}
                  />
                ))}
            </Scroller>
          )}
        {triggerListLoaded && (
          <ListLoaded
            key={currentQueryId}
            onListLoaded={onListLoaded}
            queryId={currentQueryId}
          />
        )}
      </div>
    </Fragment>
  )
}

export default ConversationList
