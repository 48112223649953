import React, { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { css } from '@emotion/react'
import { selectWidgetById } from 'ducks/widgets/selectors/widgets'
import { selectIsOfflineAgentsBotRunningForWidgetId } from 'ducks/chat/selectors/botState'
import { buildId } from 'util/globalId'

const availabilityStatusStyle = () => theme => css`
  color: ${theme.color.monochrome.mediumDark};
  font-size: ${theme.fontSize.tiny};
`

const botRunningStatus = {
  offlineBotRunning: `"No online ${app.t('agent')}" bot running`,
}

const statuses = {
  online: 'Accepting all chats',
  existing: 'Accepting existing chats',
  offline: 'Disabled for all chats',
}

const AvailabilityStatus = React.memo(({ widgetId, className }) => {
  const widget = useSelector(
    state => selectWidgetById(state, widgetId),
    shallowEqual
  )
  const offlineBotRunning = useSelector(
    state =>
      selectIsOfflineAgentsBotRunningForWidgetId(
        state,
        buildId('Widget', widgetId)
      ),
    shallowEqual
  )

  const availability = useMemo(
    () => {
      // check if online
      if (!widget || !widget.publishedSettings) return undefined
      const chatAvailability = widget.publishedSettings['chat.availability']
      let availabilityStatus =
        statuses[widget.publishedSettings['chat.availability']]
      if (offlineBotRunning && chatAvailability !== 'offline') {
        availabilityStatus = botRunningStatus.offlineBotRunning
      }
      return availabilityStatus
    },
    [widget, offlineBotRunning]
  )

  if (!availability) return null

  return (
    <div css={availabilityStatusStyle} className={className}>
      {availability}
    </div>
  )
})

export default AvailabilityStatus
