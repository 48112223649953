import React, { useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import NoCustomerEmailBanner from 'components/Banners/NoCustomerEmail'
import Printable from 'components/Printable'
import Scroller from 'components/Scroller'
import ImageLightbox from 'components/ImageLightbox'
import Header from 'subapps/ticketing/components/TicketHeader'
import cn from 'classnames'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { runOnNextTick, debounce } from 'util/functions'
import { selectTicketRequestByConversationId } from 'ducks/tickets/selectors/selectTicketRequestByConversationId'
import { selectIsContactEmailBlankByConversationId } from 'ducks/tickets/selectors/selectIsContactEmailBlankByConversationId'
import {
  selectIsEditorPinned,
  selectIsEditorVisible,
} from 'ducks/editor/selectors'
import { doMarkAsRead } from 'ducks/tickets/actions/doMarkAsRead'

import TicketChangesetPlaceholder from './TicketChangesetPlaceholder'
import TicketHero from '../TicketHero'
import Changesets from '../Changesets'
import Footer from '../Footer'
import styles from '../styles.css'
import KeyboardHandler from '../KeyboardHandler'

const debouncedResize = (onResize, wrappedScrollerRef, scrollerAPIRef) =>
  debounce((height, diff) => {
    if (wrappedScrollerRef.current) {
      const { getScrollDimensions, scrollToY } = scrollerAPIRef.current
      const scrolls = getScrollDimensions()
      const newScroll = scrolls.scrollTop + diff
      scrollToY(newScroll)
    } else {
      runOnNextTick(() => {
        onResize(height, diff)
      })
    }
  }, 50)

const NormalState = ({ ticketId }) => {
  const dispatch = useDispatch()
  const scrollerAPIRef = useRef(null)
  const wrappedScrollerRef = useRef(null)
  const printableRef = useRef(null)
  const isEditorVisible = useSelector(selectIsEditorVisible)
  const isEditorPinned = useSelector(selectIsEditorPinned)

  const { loaded: eventGroupsLoaded } = useSelector(state =>
    selectTicketRequestByConversationId(state, ticketId)
  )
  const isCustomerEmailBlank = useSelector(state =>
    selectIsContactEmailBlankByConversationId(state, ticketId)
  )

  const ticket = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )

  useEffect(
    () => {
      dispatch(doMarkAsRead(ticketId))
    },
    [dispatch, ticketId]
  )

  const onResize = useCallback((height, diff) => {
    return debouncedResize(debouncedResize, wrappedScrollerRef, scrollerAPIRef)(
      height,
      diff
    )
  }, [])

  const setScrollerAPIReference = useCallback(wrappedScroller => {
    if (wrappedScroller)
      scrollerAPIRef.current = wrappedScroller.getScrollerAPI()
  }, [])

  const setWrapperReference = useCallback(component => {
    // eslint-disable-next-line react/no-find-dom-node
    wrappedScrollerRef.current = ReactDOM.findDOMNode(component)
  }, [])

  const footerContent = (
    <Footer
      loaded={eventGroupsLoaded}
      ticketId={ticketId}
      onResize={onResize}
    />
  )

  return (
    <div className={styles.TicketInspector}>
      <KeyboardHandler ticketId={ticketId} />
      {eventGroupsLoaded && isCustomerEmailBlank && <NoCustomerEmailBanner />}
      <Header conversationId={ticketId} />
      <div className={cn(styles.scrollerWrapper)} ref={setWrapperReference}>
        <Scroller
          className={cn('desktopNormalScrollbarDisableRightBorder')}
          ref={setScrollerAPIReference}
        >
          <Printable ref={printableRef} id="printableWholeTicket">
            {ticket && (
              <TicketHero ticketId={ticketId} className={styles.ticketHero} />
            )}
            {eventGroupsLoaded && <Changesets ticketId={ticketId} />}
            {!eventGroupsLoaded && (
              <TicketChangesetPlaceholder ticketId={ticketId} />
            )}
          </Printable>
          {(!isEditorPinned || !isEditorVisible) && footerContent}
        </Scroller>
        {isEditorPinned && isEditorVisible && footerContent}
      </div>
      <ImageLightbox />
    </div>
  )
}

export default NormalState
