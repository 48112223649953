import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@emotion/react'
import { Close } from '@groovehq/internal-design-system/lib/assets/icons'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { hasLowContrast } from 'util/colors'
import { selectCurrentTagById } from 'ducks/tags/selectors'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { doUpdateTags } from 'ducks/tickets/actions/doUpdateTags'
import { styles, Container } from './styles'

export default function Tag({ ticketId, tagId }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const tag = useSelector(state => selectCurrentTagById(state, tagId))
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )
  const { tags } = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )
  const { name, color } = tag
  const isTagColorSmilarToBackground = hasLowContrast(
    theme.color.monochrome.white,
    color
  )

  const onDeleteClick = useCallback(
    () => {
      const newTags = tags.filter(tid => tid !== tagId)
      dispatch(doUpdateTags(ticketId, newTags))
    },
    [dispatch, ticketId, tagId, tags]
  )

  return (
    <Container
      color={color}
      className="grui inline-flex items-center"
      css={isTagColorSmilarToBackground && styles.tagWithLightColor}
    >
      <div>{name}</div>
      {isAdminOrOwnerOrAgent && (
        <Close
          name="close"
          size="xsmall"
          onClick={onDeleteClick}
          css={[styles.closeIcon]}
        />
      )}
    </Container>
  )
}
