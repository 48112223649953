import { useSelector } from 'react-redux'

import {
  selectLeftNavMailboxes,
  selectLeftNavShowBookDemoCard,
  selectLeftNavShowTeammatesCard,
} from 'selectors/left_nav'

import { selectShowOnboardingWorkflow } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflow'
import { selectCurrentMailboxId } from 'ducks/searches/selectors/selectCurrentMailboxId'

export const useLeftNav = () => {
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const mailboxes = useSelector(selectLeftNavMailboxes)
  const mailboxId = currentMailboxId || (mailboxes && mailboxes[0]?.id)
  const showBookDemoCard = useSelector(selectLeftNavShowBookDemoCard)
  const showTeammatesCard = useSelector(state =>
    selectLeftNavShowTeammatesCard(state, mailboxId)
  )
  const showOnboardingWorkflow = useSelector(selectShowOnboardingWorkflow)

  return {
    mailboxes,
    showBookDemoCard,
    showTeammatesCard,
    showOnboardingWorkflow,
  }
}
