import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'

import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import doCalculateRefund from 'ducks/integrations/shopify/operations/doCalculateRefund'
import doCreateRefund from 'ducks/integrations/shopify/operations/doCreateRefund'
import { isOrderFulfilled } from 'ducks/integrations/shopify/utils'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { useShopifyOrder } from 'ducks/integrations/shopify/hooks'
import { debounce } from 'util/functions'
import { capture } from 'ducks/tracking/actions'
import OrderItemTable from './OrderItemTable'
import Footer from './Footer'
import RefundSummary from './RefundSummary'
import ReturnNotification from './ReturnNotification'
import { buildLineItems, updateLineItemsWithSuggestedRefund } from './functions'
import {
  FORM_KEY_LINE_ITEMS,
  FORM_KEY_RESTOCK,
  FORM_KEY_NOTE,
  FORM_KEY_SEND_NOTIFICATION,
  FORM_KEY_SHIPPING_AMOUNT,
  FORM_KEY_REFUND_AMOUNT,
  FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT,
} from './constants'

const FORM_SCHEMA = yup.object().shape({
  [FORM_KEY_LINE_ITEMS]: yup.array(yup.object()).min(1, 'Add at least 1 item'),
  [FORM_KEY_RESTOCK]: yup.boolean().required(),
  [FORM_KEY_NOTE]: yup.string().nullable(),
  [FORM_KEY_SEND_NOTIFICATION]: yup.boolean().required(),
  [FORM_KEY_SHIPPING_AMOUNT]: yup
    .number()
    .typeError('Amount must be a number')
    .nullable()
    .when(
      [FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT],
      (suggestedRefund, schema) => {
        const maxSuggested =
          suggestedRefund?.shipping?.maximumRefundableSet?.shopMoney?.amount ||
          '0.0'

        if (maxSuggested === '0.0') {
          return schema
        }

        return schema
          .min(0)
          .max(maxSuggested, 'Can’t refund more than available')
      }
    ),
  [FORM_KEY_REFUND_AMOUNT]: yup
    .number()
    .typeError('Amount must be a number')
    .moreThan(0, '')
    .when(
      [FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT],
      (suggestedRefund, schema) => {
        const maxSuggested =
          suggestedRefund?.suggestedTransactions?.[0]?.maximumRefundableSet
            ?.shopMoney?.amount || '0.0'
        return schema.max(maxSuggested, 'Can’t refund more than available')
      }
    )
    .required(),
  [FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT]: yup.object().required(),
})

const RefundOrder = ({
  drawerResourceId: orderId,
  drawerIntegrationId,
  onClose,
  onExit,
}) => {
  const dispatch = useDispatch()

  const conversationId = useSelector(selectCurrentTicketId)
  const { order, integration } = useShopifyOrder({
    integrationId: drawerIntegrationId,
    orderId,
  })
  const isFulfilled = isOrderFulfilled(order)
  const orderCurrencyCode = order?.currencyCode

  // The currency that is used to refund the order. This must be the presentment currency, which is the currency used by the customer.
  // https://shopify.dev/api/admin-graphql/2024-01/mutations/refundCreate#field-refundinput-currency
  const orderPresentmentCurrencyCode = order?.presentmentCurrencyCode

  const isLoading = !integration?.id || !order?.id

  const [sending, setSending] = useState(false)
  const [isCalculating, setIsCalculating] = useState(false)

  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
    getValues,
    register,
    trigger,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      [FORM_KEY_LINE_ITEMS]: [],
      [FORM_KEY_RESTOCK]: true,
      [FORM_KEY_NOTE]: null,
      [FORM_KEY_SEND_NOTIFICATION]: true,
      [FORM_KEY_REFUND_AMOUNT]: 0.0,
      [FORM_KEY_SHIPPING_AMOUNT]: 0.0,
      [FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT]: null,
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateSuggestedRefund = useCallback(
    debounce(() => {
      setIsCalculating(true)
      const payload = {
        lineItems: getValues(FORM_KEY_LINE_ITEMS),
        shippingAmount: getValues(FORM_KEY_SHIPPING_AMOUNT),
        isFulfilled,
        restock: getValues(FORM_KEY_RESTOCK),
      }

      dispatch(doCalculateRefund(integration.id, order.id, payload)).then(
        data => {
          const suggestedRefund = data?.order?.suggestedRefund
          // reset the refund amount form field to the suggested refund amount (which can then be changed by the user)
          // it's by design that the refund amount gets reset after each recalculation
          setValue(
            FORM_KEY_REFUND_AMOUNT,
            // we want the refund amount to always show with 2 decimal places
            parseFloat(
              suggestedRefund?.amountSet?.shopMoney?.amount || 0
            ).toFixed(2)
          )

          // add the response to the form state, so we can use the values for schema validation
          setValue(
            FORM_KEY_SUGGESTED_REFUND_CALCULATION_RESULT,
            suggestedRefund
          )

          // link up each order table line item with corresponding suggestedRefund.refundLineItem record by line item id
          // also makes form schema validation simpler for lineItems
          const lineItems = getValues(FORM_KEY_LINE_ITEMS)
          const updatedItems = updateLineItemsWithSuggestedRefund(
            lineItems,
            suggestedRefund
          )
          setValue(FORM_KEY_LINE_ITEMS, updatedItems)

          // re-run validation based on calculation result
          trigger()

          setIsCalculating(false)
        }
      )
    }, 500),
    [setIsCalculating, dispatch, integration, order]
  )

  // load product table first before suggestedRefund is calculated
  // this happens just once on mount/ready state
  useEffect(
    () => {
      if (isLoading || !order?.id) return

      const newOrderItems = buildLineItems(order)
      setValue(FORM_KEY_LINE_ITEMS, newOrderItems)

      calculateSuggestedRefund()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.id, isLoading]
  )

  // Custom function for updating table data
  const updateData = useCallback(
    (index, id, value) => {
      const lineItems = getValues(FORM_KEY_LINE_ITEMS)
      if (id === 'suggestedRefund.quantity') {
        const lineItem = { ...lineItems[index] }
        const newRefundQuantity = parseInt(value, 10)
        if (newRefundQuantity > lineItem.currentQuantity) return
        if (newRefundQuantity === lineItem.suggestedRefund.quantity) return
        lineItem.suggestedRefund.quantity = newRefundQuantity
        lineItems[index] = lineItem
        setValue(FORM_KEY_LINE_ITEMS, lineItems)

        calculateSuggestedRefund()
      }
    },
    [getValues, calculateSuggestedRefund, setValue]
  )

  const onSubmit = useCallback(
    async data => {
      const payload = {
        currencyCode: orderPresentmentCurrencyCode,
        note: data[FORM_KEY_NOTE],
        notify: data[FORM_KEY_SEND_NOTIFICATION],
        lineItems: data[FORM_KEY_LINE_ITEMS],
        shippingAmount: data[FORM_KEY_SHIPPING_AMOUNT],
        refundAmount: data[FORM_KEY_REFUND_AMOUNT],
        suggestedTransactions: data.suggestedRefund?.suggestedTransactions,
        conversationId,
      }

      setSending(true)
      await dispatch(doCreateRefund(integration.id, order.id, payload))
      capture('Shopify Order Refunded')
      setSending(false)
      onClose()
    },
    [
      dispatch,
      integration,
      order,
      onClose,
      orderPresentmentCurrencyCode,
      conversationId,
    ]
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  return (
    <Drawer
      title="Refund order"
      footer={
        <Footer
          sending={sending}
          control={control}
          onClose={onClose}
          orderCurrencyCode={orderCurrencyCode}
          isLoading={isLoading}
          isCalculating={isCalculating}
          isValid={isValid}
        />
      }
      onClose={onExit}
      open
      isLoading={isLoading}
      css={text.styles.textDark}
      size="wide"
      container={DrawerForm}
    >
      <div className="grui pt-12">
        {isFulfilled && (
          <ReturnNotification integration={integration} orderId={orderId} />
        )}
        <OrderItemTable
          control={control}
          isLoading={isLoading}
          updateData={updateData}
          isCalculating={isCalculating}
        />
        <RefundSummary
          control={control}
          orderCurrencyCode={orderCurrencyCode}
          className="grui mt-10"
          register={register}
          isCalculating={isCalculating}
          calculateSuggestedRefund={calculateSuggestedRefund}
        />
      </div>
    </Drawer>
  )
}

export default RefundOrder
