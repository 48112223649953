import React from 'react'
import cn from 'classnames'

import BasicEditor from 'shared/editor/Basic'
import removeformatExtended from 'shared/editor/formats/removeformat_extended'
import { COMPOSER_ID } from 'shared/editor/utils'
import 'shared/editor/plugins/groove-inline-placeholder'
import { editorStyle } from 'components/App/DesktopView/shared/EditorStyles/styles'

require('tinymce/plugins/autoresize/plugin')
require('shared/editor/plugins/groove-before-set-content-cleanup')
require('shared/editor/plugins/groove-paste-cleanup')
require('shared/editor/plugins/groove-emojis')

class EmailEditor extends React.Component {
  constructor(props) {
    super(props)
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.config = {
      ...this.rootConfig(),
      ...props.config,
    }
  }

  state = {
    config: {},
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== !this.props.config) {
      this.setState({
        config: {
          ...this.rootConfig(),
          ...nextProps.config,
        },
      })
    }
  }

  shouldComponentUpdate(nextProps) {
    // HACK (jscheel): Editor was re-rendering when it shouldn't, so we are being
    // a bit more heavy-handed and explicit that nothing should be changing.
    const keys = Object.keys(this.props)
    // eslint-disable-next-line no-plusplus
    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i]
      if (
        key !== 'content' &&
        key !== 'contentKeyExpansion' &&
        this.props[key] !== nextProps[key]
      ) {
        return true
      }
    }

    // We change the content key when you swop between tickets. This should
    // then trigger a re-render where in the componentDidUpdate method we use
    // the tinymce api to update the current content of the editor
    // Ultimately we want to be able to re-use our tinymce instance for all tickets
    if (this.props.contentKey !== nextProps.contentKey) {
      return true
    }

    if (this.props.contentKeyExpansion !== undefined) {
      const expansionWas = this.props.contentKeyExpansion
      const expansionIs = nextProps.contentKeyExpansion
      if (!expansionIs && expansionWas) return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { contentKey, content } = this.props
    if (contentKey !== prevProps.contentKey) {
      // Tinymce doesnt support setting the content to null. When its null
      // we should just set it to empty
      this.updateEditorContent(content || '')
    }
    if (this.props.contentKeyExpansion !== undefined) {
      const expansionWas = prevProps.contentKeyExpansion
      const expansionIs = this.props.contentKeyExpansion
      const currentContent = this.editor && this.editor.getContent()
      const element = document.activeElement
      // we just want from draft to no draft, we need to clear
      if (!expansionIs && expansionWas) {
        this.updateEditorContent(content || '')
      }
      // When switching between drafts we need to update the content
      if (!!expansionIs && !!expansionWas && expansionIs !== expansionWas) {
        this.updateEditorContent(content || '')
      }
      if (element && element.className.match('mce-edit-focus')) {
        return // we're focused in the editor, don't override it
      }
      if (currentContent === content) return // no need, short circuit
      // we went from no draft to new draft;
      // and the draft is created by content change (prevent changing focus when editing the contact)
      if (expansionIs && !expansionWas && !prevProps.content && content) {
        this.updateEditorContent(content || '')
        // put caret at end of text
        try {
          this.editor.selection.select(this.editor.getBody(), true)
          this.editor.selection.collapse(false)
          window.focus()
        } catch (e) {
          // pass
        }
      }
    }
  }

  updateEditorContent = content => {
    if (this.editor) {
      this.editor.setContent(content)
    } else {
      setTimeout(() => {
        this.updateEditorContent(content)
      }, 50)
    }
  }

  rootConfig = () => {
    return {
      autoresize_bottom_margin: 0,
      autoresize_max_height: 300,
      autoresize_min_height: 60,
      autoresize_on_init: true,
      body_class: 'editor-email',
      fixed_toolbar_container: '#email-editor-toolbar',
      forced_root_block: 'div',
      formats: { removeformat: removeformatExtended },
      inline: true,
      inline_styles: true,
      paste_data_images: true,
      fix_list_elements: true,
      plugins:
        'groove-emojis groove-paste-cleanup groove-before-set-content-cleanup link image code lists autoresize paste groove-inline-placeholder',
      toolbar:
        'bold italic underline strike | alignleft aligncenter alignright alignjustify | bullist numlist | link | removeformat',
      valid_styles: {
        '*': 'nothing', // remove all styles
        span: 'text-decoration', // allows Tiny's underline style
        div: 'text-align', // text alignment
      },
    }
  }

  takeToolbarContainerRef = el => {
    this.toolbarContainerRef = el
  }

  forceToolbarVisibility = editor => {
    // HACK (jscheel): This will trick the editor into thinking it was focused
    // without actually focusing it (causing the toolbar to appear).
    this.forceToolbarVisibilitySemaphore = true
    editor.fire('focus')
    editor.fire('blur')
    this.forceToolbarVisibilitySemaphore = false
  }

  handleInit = editor => {
    this.forceToolbarVisibility(editor)
    if (this.props.onInit) {
      this.props.onInit(editor)
    }
  }

  handleFocus = (...args) => {
    const { onFocus } = this.props
    if (onFocus && !this.forceToolbarVisibilitySemaphore) {
      onFocus(...args)
    }
  }

  handleBlur = (...args) => {
    const { onBlur } = this.props
    if (onBlur && !this.forceToolbarVisibilitySemaphore) {
      onBlur(...args)
    }
  }

  handleEditorRef = editor => {
    this.editor = editor
    const { forwardRef } = this.props
    if (forwardRef) forwardRef(this.editor)
  }

  render() {
    const { className, content, placeholder = '', ...rest } = this.props
    const { config } = this.state

    return (
      <BasicEditor
        {...rest}
        id={COMPOSER_ID}
        css={editorStyle}
        className={cn(className, 'toolbar-bottom')}
        config={config}
        initialValue={content}
        placeholder={placeholder}
        onInit={this.handleInit}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        inline={config.inline}
        forwardRef={this.handleEditorRef}
      />
    )
  }
}

export default EmailEditor
