import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomFieldValueForCustomFieldKeyAndCategoryType } from 'ducks/crm/contacts'
import { selectCustomFieldsByKey } from 'ducks/crm/customFields/selectors'
import { SINGLE_LINE } from 'ducks/crm/customFields'
import CustomFieldKeyValue from '../../CustomFieldKeyValue'
import DerivedLastSeenAt from './DerivedLastSeenAt'
import LocalTime from './LocalTime'
import ArrayCustomFields from './ArrayCustomFields'

const EXCLUDED_FIELDS = [
  'company_domain',
  'company_logo',
  'company_name',
  'contact_avatar',
  'contact_first_name',
  'contact_last_name',
  'contact_email',
]
const COMPONENT_OVERIDES = {
  derived_last_seen_at: DerivedLastSeenAt,
  local_time: LocalTime,
}

export default memo(CustomField)

function CustomField({
  categoryType,
  isVisible,
  onHideItem,
  onShowItem,
  isInEditMode,
  itemKey,
  subjectId,
}) {
  const customFieldsByKey = useSelector(selectCustomFieldsByKey)
  const customField = customFieldsByKey && customFieldsByKey[itemKey]
  const {
    icon: customFieldIcon,
    key: customFieldKey,
    name: customFieldName,
    options,
    type: customFieldType,
    required: customFieldRequired,
  } =
    customField || {}
  const { value: customFieldValue } =
    useSelector(state =>
      selectCustomFieldValueForCustomFieldKeyAndCategoryType(state, {
        customFieldKey,
        categoryType,
      })
    ) || {}
  const onEye = useCallback(
    () => {
      if (isVisible) {
        onHideItem(itemKey)
      } else {
        onShowItem(itemKey)
      }
    },
    [onHideItem, itemKey, isVisible, onShowItem]
  )

  const Component = COMPONENT_OVERIDES[itemKey]
  if (Component) {
    return (
      <Component
        fieldKey={customFieldKey}
        fieldHidden={!isVisible}
        isInEditMode={isInEditMode}
        options={options}
        onEye={onEye}
        subjectId={subjectId}
      />
    )
  }

  // NOTE: We want to hide boolean and money fields outside of Shopify for now
  if (customFieldType === 'BOOLEAN') return null
  if (customFieldType === 'MONEY') return null
  if (!customField) return null
  if (EXCLUDED_FIELDS.includes(customFieldKey)) return null
  if (customFieldKey === 'contact_secondary_emails') {
    return (
      <ArrayCustomFields
        categoryType={categoryType}
        fieldHidden={!isVisible}
        icon="at"
        individualTitle="Secondary email"
        isInEditMode={isInEditMode}
        fieldKey="contact_secondary_emails"
        onEye={onEye}
        subjectId={subjectId}
        title="Secondary emails"
        itemKey={itemKey}
        customFieldKey={customFieldKey}
        type={SINGLE_LINE}
        values={customFieldValue}
      />
    )
  }
  if (customFieldKey === 'company_secondary_domains') {
    return (
      <ArrayCustomFields
        categoryType={categoryType}
        fieldHidden={!isVisible}
        icon="globe"
        individualTitle="Secondary domain"
        isInEditMode={isInEditMode}
        fieldKey="company_secondary_domains"
        onEye={onEye}
        subjectId={subjectId}
        title="Secondary Domains!"
        type={SINGLE_LINE}
        values={customFieldValue}
      />
    )
  }
  return (
    <CustomFieldKeyValue
      fieldKey={customFieldKey}
      fieldHidden={!isVisible}
      icon={customFieldIcon}
      isInEditMode={isInEditMode}
      options={options}
      onEye={onEye}
      subjectId={subjectId}
      title={customFieldName}
      type={customFieldType}
      value={customFieldValue}
      required={customFieldRequired}
    />
  )
}
