import cn from 'classnames'
import { useSelector } from 'react-redux'
import { capitalize } from 'util/strings'
import { selectRatingEventForGroupId } from 'ducks/tickets/selectors/selectRatingEventForGroupId'
import ContactName from 'components/crm/ContactName'
import './styles.less'

const Rating = ({ eventGroupId }) => {
  const rating = useSelector(state =>
    selectRatingEventForGroupId(state, eventGroupId)
  )
  if (!rating) return null
  const {
    change: { grade, feedback },
    actor,
  } = rating
  return (
    <div className="rating">
      <div className={cn('rating-marker', grade)} />
      <div className="content">
        <div className="grade">
          <ContactName contactId={actor.id} /> left a rating of{' '}
          <span className={cn('grade', grade)}>
            {capitalize(grade.toLowerCase())}
          </span>
        </div>
        <div className={cn('feedback', { empty: !feedback })}>
          {feedback || 'No comment was left by the customer...'}
        </div>
      </div>
    </div>
  )
}

export default Rating
