import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const base = css`
  height: auto;
  min-height: ${rem(32)};
`

const pinnedItem = theme => css`
  svg {
    color: ${theme.color.mainNav.pin};
    width: ${rem(12)};
    height: ${rem(12)};
  }
`

export const styles = {
  base,
  pinnedItem,
}
