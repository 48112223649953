import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectGroupById,
  selectOpenGroupIds,
} from 'ducks/folders/selectors/groups'
import { doToggleGroup } from 'ducks/folders/operations/groups'
import * as Icons from 'assets/icons/groove/v2'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import Item from '../Item'
import { styles } from '../styles'

const FolderGroup = props => {
  const { groupId, onClick } = props
  const dispatch = useDispatch()

  const { id, name, icon, folderIds } = useSelector(state =>
    selectGroupById(state, groupId)
  )
  const openGroupIds = useSelector(selectOpenGroupIds)
  const open = openGroupIds.includes(id)

  const handleClick = useCallback(
    () => {
      if (onClick) onClick(groupId)
      dispatch(doToggleGroup({ groupId }))
    },
    [dispatch, onClick, groupId]
  )

  const GroupIcon = Icons[icon]

  return (
    <Menu.Item
      key={groupId}
      icon={<GroupIcon />}
      defaultOpen={open}
      onClick={handleClick}
      hasArrow
      css={styles.item}
      subMenu={folderIds.map(listId => (
        <Item key={listId} itemId={listId} padded={4} isSubMenuItem />
      ))}
    >
      {name}
    </Menu.Item>
  )
}

export default FolderGroup
