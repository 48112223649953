import Link from 'redux-first-router-link'
import { css } from '@emotion/react'
import SUI from 'shared/components/ui/SUI'
import Screen from 'components/Screen'
import { Grid, Button } from 'util/ui'
import { ERR_POPUP_BLOCKED } from 'util/oauth_window'
import { Header, SubHeader } from 'shared/components/ui/Typography'
import { V6_SIGNUP_PAGE as SIGNUP_PAGE } from 'subapps/onboarding/v6/pages'
import { LOGIN_PAGE } from 'constants/pages'
import themeVars from 'ui_theme/site/globals/site.variables'
import oAuthErrorImage from 'assets/oauth-error.jpg'
import logo from 'assets/groove_logo_v2.svg'
import useOpenContact from 'util/hooks/useOpenContact'

const subHeaderStyle = () => theme => css`
  &&& {
    margin-bottom: ${theme.spacing['14']} !important;
  }
`
const OAuthError = ({ service = '', error }) => {
  const openContact = useOpenContact({ left: true })

  return (
    <SUI transparent>
      <Screen>
        <img
          src={logo}
          width="106"
          height="28"
          alt="GrooveHQ"
          css={css`
            position: absolute;
            top: ${themeVars.massive};
            left: ${themeVars.massive};
          `}
        />
        <Grid
          columns={12}
          centered
          verticalAlign="middle"
          padded
          css={css`
            height: 100vh;
          `}
        >
          <Grid.Row centered={false}>
            <Grid.Column width={1} />
            <Grid.Column width={5}>
              <Header
                as="h1"
                fontType="display"
                css={css`
                  &&& {
                    margin-top: -100px !important;
                  }
                `}
              >
                Error connecting your {service} account
              </Header>
              <SubHeader size="tiny" as="p" color="dark" css={subHeaderStyle}>
                {error &&
                  error === ERR_POPUP_BLOCKED && (
                    <React.Fragment>
                      Your browser is blocking the {service} authentication
                      window. Please allow popups for this site and{' '}
                      <Link to={{ type: SIGNUP_PAGE }}>try again</Link>.
                    </React.Fragment>
                  )}
                {(!error || error !== ERR_POPUP_BLOCKED) && (
                  <React.Fragment>
                    Try to <Link to={{ type: LOGIN_PAGE }}>sign in</Link> or{' '}
                    <Link to={{ type: SIGNUP_PAGE }}>sign up</Link> again, or
                    contact our support team.
                  </React.Fragment>
                )}
              </SubHeader>
              <Button
                size="large"
                primary
                as="a"
                href="mailto:support@groovehq.com?subject=Oauth%20Error"
                onClick={openContact}
              >
                Contact support
              </Button>
            </Grid.Column>
            <Grid.Column width={5}>
              <img
                src={oAuthErrorImage}
                alt=""
                width="421"
                height="367"
                css={css`
                  max-width: 100%;
                  height: auto;
                `}
              />
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid.Row>
        </Grid>
      </Screen>
    </SUI>
  )
}

export default OAuthError
