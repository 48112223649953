import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { useShopifyDraft } from 'ducks/integrations/shopify/hooks'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import {
  DRAWER_TYPE_SHOPIFY_UNSAVED_CHANGES,
  DRAWER_TYPE_SHOPIFY_SEND_INVOICE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { strftime } from 'util/date'
import { capture } from 'ducks/tracking/actions'
import { styles } from './styles'
import { FORM_KEY_IS_PAYMENT_DUE_LATER, FORM_KEY_ORDER_ITEMS } from './util'

const CreateOrderDrawerFooter = ({
  integrationId,
  onClose,
  customerId,
  isDuplicate,
}) => {
  const {
    isSending,
    draftOrderComplete,
    integration,
    draftOrder,
    discardDraftOrder,
    isCalculating,
  } = useShopifyDraft({
    integrationId,
    customerId,
  })
  const {
    watch,
    formState: { isValid },
  } = useFormContext()
  const conversationId = useSelector(selectCurrentTicketId)

  const { openDrawer: openUnsavedDrawer } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_UNSAVED_CHANGES,
  })

  // callbacks
  const handleCreateOrder = useCallback(
    async paymentPending => {
      await draftOrderComplete(
        integration.id,
        draftOrder.id,
        paymentPending,
        conversationId,
        isDuplicate
      )
      capture(
        isDuplicate ? 'Shopify Order Duplicated' : 'Shopify Order Created'
      )
      onClose()
    },
    [
      integration,
      draftOrder,
      onClose,
      conversationId,
      isDuplicate,
      draftOrderComplete,
    ]
  )

  const handleCreatePaidOrder = useCallback(
    e => {
      e.preventDefault()
      handleCreateOrder(false)
    },
    [handleCreateOrder]
  )

  const handleCreatePaymentPendingOrder = useCallback(
    e => {
      e.preventDefault()
      handleCreateOrder(true)
    },
    [handleCreateOrder]
  )

  const handleClose = useCallback(
    () => {
      if (draftOrder && draftOrder.id) {
        openUnsavedDrawer(customerId, {
          additionalProps: {
            onDiscard: discardDraftOrder,
          },
        })
      } else {
        onClose()
      }
    },
    [openUnsavedDrawer, onClose, draftOrder, customerId, discardDraftOrder]
  )

  const {
    drawerId: sendInvoiceDrawerId,
    openDrawer: openSendInvoiceDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_SEND_INVOICE,
  })

  const onSendInvoice = useCallback(
    () => {
      if (draftOrder)
        openSendInvoiceDrawer(draftOrder.id, {
          query: {
            ...buildDrawerQueryParam(
              sendInvoiceDrawerId,
              'drawerIntegrationId',
              integration.id
            ),
          },
        })
    },
    [openSendInvoiceDrawer, sendInvoiceDrawerId, draftOrder, integration]
  )

  const orderItems = watch(FORM_KEY_ORDER_ITEMS)
  const isPaymentDueLater = watch(FORM_KEY_IS_PAYMENT_DUE_LATER)
  const canSendInvoice = !!draftOrder?.id && !!orderItems?.length

  return (
    <div css={[modalBtnsStyles.base, styles.footer]}>
      <>
        {isPaymentDueLater && (
          <ModalBtns.SaveBtn
            className="grui w-100"
            onClick={handleCreatePaymentPendingOrder}
            disabled={isSending || !isValid || isCalculating}
          >
            {isSending && 'Creating order...'}
            {!isSending && 'Create order'}
          </ModalBtns.SaveBtn>
        )}
        {!isPaymentDueLater && (
          <>
            <Tooltip
              title={`Invoice sent on ${strftime(
                '%B %d, %Y %l:%M %p',
                draftOrder?.invoiceSentAt
              )}`}
              disabled={!draftOrder?.invoiceSentAt}
            >
              <Button
                type="secondary"
                css={styles.footerBtnHalfWidth}
                onClick={onSendInvoice}
                disabled={
                  isSending || !isValid || !canSendInvoice || isCalculating
                }
              >
                {!!draftOrder?.invoiceSentAt && 'Resend invoice'}
                {!draftOrder?.invoiceSentAt && 'Send invoice'}
              </Button>
            </Tooltip>
            <ModalBtns.SaveBtn
              css={styles.footerBtnHalfWidth}
              onClick={handleCreatePaidOrder}
              disabled={isSending || !isValid || isCalculating}
            >
              {isCalculating && (
                <span>
                  Calculating<AnimatedEllipsis />
                </span>
              )}
              {!isCalculating && isSending && 'Creating order...'}
              {!isCalculating && !isSending && 'Mark as paid'}
            </ModalBtns.SaveBtn>
          </>
        )}
        <Button type="tertiary" className="grui w-100" onClick={handleClose}>
          Cancel
        </Button>
      </>
    </div>
  )
}

export default CreateOrderDrawerFooter
