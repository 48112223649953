import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_SETUP_EMAIL } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import NoticeBar from 'components/NoticeBar'
import { selectMailboxByStates } from 'ducks/mailboxes/selectors/selectMailboxByStates'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { CHANNEL_STATE, CHANNEL_TYPE } from 'ducks/mailboxes/constants'

import styles from './styles.less'

export default function ForwardingUnconfirmed({ isManager }) {
  const ticketListMailboxId = useSelector(selectCurrentMailboxId)
  const confirmedUnconfirmedMailboxes = useSelector(state =>
    selectMailboxByStates(state, [
      CHANNEL_STATE.UNCONFIRMED,
      CHANNEL_STATE.CONFIRMED,
    ])
  )
  const firstMailbox = confirmedUnconfirmedMailboxes[0] || {}
  const currentMailboxId = ticketListMailboxId || firstMailbox.id
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_SETUP_EMAIL,
    lazyLoad: true,
  })

  const mailboxName = !ticketListMailboxId ? ` for ${firstMailbox.name}` : ''

  const onOpen = useCallback(
    () => {
      openAddDrawer(currentMailboxId, {
        query: {
          ...buildDrawerQueryParam(
            drawerId,
            'drawerChannelType',
            CHANNEL_TYPE.FORWARDING
          ),
        },
      })
    },
    [openAddDrawer, currentMailboxId, drawerId]
  )

  let callToActionContent = `Please contact your administrator to setup your ${app.t(
    'mailbox'
  )} now.`
  if (isManager) {
    callToActionContent = (
      <a className={styles.demoBarLinkInlineWarning} onClick={onOpen}>
        Setup now {mailboxName}.
      </a>
    )
  }

  return (
    <NoticeBar
      flagName={`app-notice-legacy-mailbox-confirmed-unconfirmed-${currentMailboxId}`}
      dismissible
      type="warning"
    >
      You will only start receiving emails in this {app.t('mailbox')} after
      setup is completed!&nbsp;
      {callToActionContent}
    </NoticeBar>
  )
}
