import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { func, bool } from 'prop-types'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import {
  divider,
  text,
  list,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_SEND_INVOICE } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectIntegrationCredentialsForId } from 'ducks/integrations/selectors'

import doPreviewInvoice from 'ducks/integrations/shopify/operations/doPreviewInvoice'
import doSendInvoice from 'ducks/integrations/shopify/operations/doSendInvoice'
import useIsMounted from 'util/hooks/useIsMounted'
import { capture } from 'ducks/tracking/actions'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import { styles } from './ReviewInvoice.styles'
import {
  FORM_KEY_TO,
  FORM_KEY_SUBJECT,
  FORM_KEY_CUSTOM_MESSAGE,
  FORM_KEY_FROM,
  FORM_KEY_INCLUDE_BCC,
} from '../SendInvoice/constants'

// we should probably change this and SendInvoice in future to also handle order id too
const ReviewInvoice = ({
  drawerResourceId: draftOrderId,
  drawerIntegrationId,
  open = true,
  data,
  onClose,
  onExit,
  onHide,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [invoicePreview, setInvoicePreview] = useState({})
  const isMounted = useIsMounted()
  const { previewHtml, previewSubject } = invoicePreview

  const dispatch = useDispatch()

  const integration = useSelector(state => {
    return selectIntegrationCredentialsForId(state, {
      id: drawerIntegrationId,
    })
  })

  const conversationId = useSelector(selectCurrentTicketId)

  const handleSend = useCallback(
    async () => {
      setIsSending(true)

      await dispatch(
        doSendInvoice(integration.id, draftOrderId, conversationId, data)
      )
      capture('Shopify Draft Order Invoice Sent')

      onClose({ ignoreStack: true })
    },
    [data, dispatch, draftOrderId, integration, onClose, conversationId]
  )

  const handleOnBack = useCallback(
    async () => {
      onClose({
        previousDrawerAdditionalProps: {
          prefillForm: {
            [FORM_KEY_TO]: data[FORM_KEY_TO],
            [FORM_KEY_SUBJECT]: data[FORM_KEY_SUBJECT],
            [FORM_KEY_CUSTOM_MESSAGE]: data[FORM_KEY_CUSTOM_MESSAGE],
            [FORM_KEY_FROM]: data[FORM_KEY_FROM],
            [FORM_KEY_INCLUDE_BCC]: !!data.bcc,
          },
        },
      })
    },
    [onClose, data]
  )

  const {
    drawerId: sendInvoiceDrawerId,
    openDrawer: openSendInvoiceDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_SEND_INVOICE,
  })

  // we've probably loaded directly - this drawer is a second step and cannot operate
  // on its own, so we need to take someone to the first step
  useEffect(
    () => {
      if (!data) {
        onClose()
        openSendInvoiceDrawer(draftOrderId, {
          query: {
            ...buildDrawerQueryParam(
              sendInvoiceDrawerId,
              'drawerIntegrationId',
              drawerIntegrationId
            ),
          },
        })
      } else {
        dispatch(
          doPreviewInvoice(drawerIntegrationId, draftOrderId, data)
        ).then(loadedInvoicePreview => {
          if (isMounted()) {
            setInvoicePreview(loadedInvoicePreview)
            setIsLoading(false)
          }
        })
      }
    },
    [
      data,
      openSendInvoiceDrawer,
      onClose,
      draftOrderId,
      dispatch,
      drawerIntegrationId,
      isMounted,
      sendInvoiceDrawerId,
    ]
  )
  if (!data) return null

  const footer = !isLoading && (
    <ModalBtns
      saveBtnText={isSending ? 'Sending invoice...' : 'Send invoice'}
      saveBtnDisabled={isSending}
      tertiaryBtnText="Back"
      onSave={handleSend}
      onClickTertiaryBtn={handleOnBack}
    />
  )

  return (
    <Drawer
      title="Review invoice"
      open={open}
      footer={footer}
      onClose={onExit}
      onHide={onHide}
      isLoading={isLoading}
      contentStyles={styles.container}
      size="wide"
    >
      <div className="grui pt-10 flex flex-col flex-1" css={styles.content}>
        <ul
          className="grui flex-shrink-0"
          css={[list.styles.preflight, text.styles.textDark]}
        >
          <li>
            <span css={text.styles.textMediumDark}>From&nbsp;&nbsp;</span>
            {data.from}
          </li>
          <li>
            <span css={text.styles.textMediumDark}>To&nbsp;&nbsp;</span>
            {data.to}
          </li>
          <li>
            <span css={text.styles.textMediumDark}>Subject&nbsp;&nbsp;</span>
            {previewSubject}
          </li>
        </ul>
        <div
          className="grui my-10"
          css={divider.styles.base}
          role="separator"
        />
        <div className="grui flex-1" css={styles.iframeWrapper}>
          <iframe
            srcDoc={previewHtml}
            sandbox=""
            width="100%"
            height="100%"
            title="Invoice"
          />
        </div>
      </div>
    </Drawer>
  )
}

ReviewInvoice.propTypes = {
  onClose: func,
  open: bool.isRequired,
  onHide: func,
}

ReviewInvoice.defaultProps = {
  onClose() {},
  onHide() {},
  invoice: {},
  onSaveInvoice() {},
  onShowlastStep() {},
}

export default ReviewInvoice
