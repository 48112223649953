import { css } from '@emotion/react'

const pinStyle = theme => css`
  &&:hover svg path,
  &&.pinned svg path {
    fill: ${theme.color.primary.warning} !important;
  }
`

export const styles = {
  pinStyle,
}
