import { useSelector } from 'react-redux'
import { memo, useCallback } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { selectReactionTitleForEventGroupAndReaction } from 'ducks/tickets/selectors/selectReactionTitleForEventGroupAndReaction'
import { selectIsOwnReactionForEventGroupAndReaction } from 'ducks/tickets/selectors/selectIsOwnReactionForEventGroupAndReaction'
import { selectTotalReactionsForEventGroupAndReaction } from 'ducks/tickets/selectors/selectTotalReactionsForEventGroupAndReaction'

import { styles } from './styles'

const Reaction = ({ eventGroupId, reaction, onReactionClick }) => {
  const title = useSelector(state =>
    selectReactionTitleForEventGroupAndReaction(state, eventGroupId, reaction)
  )
  const isOwnReaction = useSelector(state =>
    selectIsOwnReactionForEventGroupAndReaction(state, eventGroupId, reaction)
  )
  const total = useSelector(state =>
    selectTotalReactionsForEventGroupAndReaction(state, eventGroupId, reaction)
  )

  const onClick = useCallback(
    () => {
      onReactionClick(reaction)
    },
    [onReactionClick, reaction]
  )

  return (
    <Tooltip title={title} position="top" strategy="fixed">
      <span
        css={[styles.reaction, isOwnReaction && styles.ownReaction]}
        onClick={onClick}
      >
        {reaction} {total}
      </span>
    </Tooltip>
  )
}

export default memo(Reaction)
