import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import { doUnpinSearch } from 'ducks/pinnedSearch/actions'

const MorePinnedSearchOptions = ({ queryId }) => {
  const dispatch = useDispatch()

  const handleDelete = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      dispatch(doUnpinSearch(queryId))
      return false
    },
    [dispatch, queryId]
  )

  const stopMenuRedirect = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }, [])

  return (
    <Menu.Item.MoreOptions
      onClick={stopMenuRedirect}
      overlay={
        <Dropdown.Menu>
          <Dropdown.Menu.Item
            onClick={handleDelete}
            itemKey="remove-pinnedsearch"
            isWarning
          >
            Remove pinned search
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      }
      menuItemIconHidden
    />
  )
}

export default MorePinnedSearchOptions
