import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import Screen from 'components/Screen'
import { TextLoader } from 'shared/ui'
import {
  selectRawEmailById,
  selectRawEmailRequestById,
} from 'ducks/rawEmails/selectors'
import { doFetchRawEmail } from 'ducks/rawEmails/actions'
import { useDispatch, useSelector } from 'react-redux'
import { emptyObj } from 'util/objects'
import styles from './styles.css'
import Header from './Header'

const ScrollableScreen = styled(Screen)`
  overflow: auto;
  height: 100%;
`

const RawEmailPage = ({ id: rawEmailId, className }) => {
  const dispatch = useDispatch()
  const { loading, loaded, untried } = useSelector(state =>
    selectRawEmailRequestById(state, rawEmailId)
  )
  const { headers, bodyPlainText: plain, body: html } =
    useSelector(state => selectRawEmailById(state, rawEmailId)) || emptyObj

  useEffect(
    () => {
      if (untried) {
        dispatch(doFetchRawEmail(rawEmailId))
      }
    },
    [dispatch, untried, rawEmailId]
  )

  return (
    <ScrollableScreen className={className}>
      <Header />
      <div className={styles.container}>
        {headers && (
          <div>
            <div className={styles.bodyType}>Headers:</div>
            <div className={styles.body}>
              {headers.map(({ name, value }) => `${name}: ${value}`).join(`\n`)}
            </div>
          </div>
        )}
        {loading && <TextLoader fullScreen />}
        {!loading &&
          loaded && (
            <div>
              <div className={styles.bodyType}>Plain Text:</div>
              <div className={styles.body}>{plain}</div>

              <div className={styles.bodyType}>HTML:</div>
              <div className={styles.body}>{html}</div>
            </div>
          )}
        {!untried && !loading && !loaded && <div>No raw email found</div>}
      </div>
    </ScrollableScreen>
  )
}

export default RawEmailPage
