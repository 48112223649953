import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { IconButton } from 'shared/ui'

const tooltipLabel = (label, bindHotKey) => {
  const key = bindHotKey ? ' [a]' : ''
  if (label) return `Reassign (${label})${key}`
  return `Assign${key}`
}

const WithIconState = ({
  assignmentLabel,
  bgColor,
  bindHotKey,
  children,
  className,
  onClick,
  size,
  iconSize = 14,
  tooltipPosition,
}) => {
  const isAssigned = Boolean(assignmentLabel)
  const label = isAssigned ? assignmentLabel : undefined

  return (
    <React.Fragment>
      <IconButton
        bgColor={bgColor}
        className={cn(className, { assigned: isAssigned })}
        key="badge"
        label={label}
        name="chatBubbleUser"
        onClick={onClick}
        size={size}
        iconSize={iconSize}
        svg
        tooltip={tooltipLabel(label, bindHotKey)}
        tooltipPosition={tooltipPosition}
      />
      {children}
    </React.Fragment>
  )
}

WithIconState.propTypes = {
  bindHotKey: PropTypes.bool,
  size: PropTypes.string,
  tooltipPosition: PropTypes.string,
}

WithIconState.defaultProps = {
  bindHotKey: false,
  size: 'medium',
  tooltipPosition: 'bottom',
}

export default WithIconState
