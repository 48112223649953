import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsEditingTicketTitle } from 'selectors/app'
import { selectTagsByConversationId } from 'ducks/tickets/selectors/selectTagsByConversationId'
import { styles } from './styles'
import Tag from './Tag'

const TagList = ({ ticketId, className }) => {
  const isEditingTicketTitle = useSelector(selectIsEditingTicketTitle)
  const tags = useSelector(state => selectTagsByConversationId(state, ticketId))
  if (!tags || tags.length === 0) return null

  return (
    <div
      css={[styles.labels, isEditingTicketTitle && styles.labelsHidden]}
      className={className}
    >
      {tags.map(tag => <Tag key={tag.id} ticketId={ticketId} tagId={tag.id} />)}
    </div>
  )
}

export default TagList
