module.exports = {
  appUrl: 'https://www.groovehq.com/',
  widgetUrl: 'https://widget-cluster.production.groovehq.com',
  appDomain: 'groovehq.com',
  kbProxyDomain: 'hosting.groovehq.help',
  api_url: 'https://api.production.groovehq.com',
  autologin_url: 'https://api.groovehq.com',
  graphql_url: 'https://graphql.production.groovehq.com/graphql',
  app_graphql_url: 'https://api.production.groovehq.com/v2/graphql',
  report_url: 'https://graphql.production.groovehq.com/reports',
  embeddable_cards_url:
    'https://graphql.production.groovehq.com/embeddable_cards/proxy',
  realtime_url: 'realtime.groovehq.com',
  s3_url: 's3.amazonaws.com',
  vapidPublicKey:
    'BNeZTrpheVt1AqrEWUBRMIa3_g-5KrW3Gf63qPgNKBTmYiB48dCol4y6QqxQtbT0xsMwyjM9QnuqGX8nq2diEnM',
  stripePublicKey: 'pk_live_TUyxO0NZ4mGfVntGxql5FC1900Lgtmu1Qs',
  filestackKey: 'AbnxTnzehQ4KY9hgqQ767z',
  filestackRegion: 'us-east-1',
  filestackBucket: 'groove-filestack-production',
  showReports: true,
  showSettings: true,
  api_concurrency: 20,
  gtm_segment: 'Application',
  gtm_version: 'beta',
  gtm_containerid: 'GTM-MQ94GV3',
  gtm_auth: 'gTuZYzA0PueTz4F77TZkNA',
  gtm_preview: 'env-2',
  google_recaptcha_site_key: '6LcEWeAjAAAAAPeQ2o_C-y8Gl3iYL43k4zV9fSjP',
  integrationsApiUrl: 'https://integrations.groovehq.com',
  integrationsApiConcurrency: 4,
  githubAppName: 'groovehq',
  chatServerUrl: 'https://chat-server-cluster.production.groovehq.com',
  chatIdentityServerUrl: 'https://vector.im',
  chatFileSizeLimits: {
    // in bytes
    matrix: 25 * 1024 * 1024, // 25 MB
    'g.bridge.messenger.facebook': 25 * 1024 * 1024, // 25 MB
    'g.bridge.messenger.instagram': 25 * 1024 * 1024, // 25 MB
  },
  emojiWidth: 280,
  emojiHeight: 399,
  uploaderUrl: 'https://uploader.groovehq.com',
  trialDays: 90,
  trialShowNoticeBannerDaysLeft: 3,
  sygnalServerUrl: 'http://sygnal.default.svc.cluster.local',
  disableSW: process.env.OFFLINE_RUNTIME === 'off',
  frillWidgets: {
    ideas: {
      key: 'f3249687-32c0-4697-a247-7029260a0535',
    },
    announcements: {
      key: 'ec26475a-3dd8-4012-9106-d1295b6b8719',
    },
    roadmap: {
      key: '4d0a5ca0-2f67-453c-985a-d54e0682d5b4',
    },
  },
  showFrillAnnoucements: true,
  shopify: {
    graphQLApiVersion: '2024-01',
    restApiVersion: '2024-01',
    appStoreGrooveAppUrl: 'https://apps.shopify.com/support-software',
  },
  rechargeClientId:
    '5f1291936b8bd9011b1dd86e9c4ffc10cc09a3614a63709f5ac2d19f9b9ee68a',
  posthog: {
    enabled: true,
    apiKey: 'phc_rWui2JtycBW2rzz2mWpE27H3PIue4GSW0RXUmT6ruMX',
    apiHost: 'https://observe-cluster.production.groovehq.com',
  },
  statusPageIOPageId: '73nj5jhb3tk4',
  hotjar: {
    hjid: '3237486',
    hjsv: '7',
  },
}
