import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'
import aiWidgetHeaderBg from 'assets/ai-widget-header-bg@2x.png'

const card = theme => css`
  font-family: ${theme.font.quaternary};
  font-size: ${theme.fontSize.large};
  line-height: ${theme.lineHeight.leadingTight};

  .ClosableCard-title {
    border-top-left-radius: ${rem(4)};
    border-top-right-radius: ${rem(4)};
  }

  &.open,
  &:hover {
    .ClosableCard-title {
      background: ${theme.color.monochrome.white} url(${aiWidgetHeaderBg})
        no-repeat center/cover;
    }
  }

  .ClosableCard-contentInternal {
    padding: 0;

    .rocket {
      font-size: 50px;
      display: block;
      padding-bottom: 8px;
    }
  }

  &&& button {
    font-family: inherit;
  }
`

export const styles = {
  card,
}
