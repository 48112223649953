import React from 'react'
import AssignmentDropdown from 'components/App/DesktopView/shared/AssignmentDropdown/Bulk'
import AssignmentBadge from '../'

export default function BulkAssignmentBadge({ assignmentLabel, iconSize }) {
  return (
    <AssignmentDropdown
      trigger={
        <AssignmentBadge
          iconSize={iconSize}
          assignmentLabel={assignmentLabel}
        />
      }
      bindHotKey
    />
  )
}
