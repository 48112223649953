import styled from '@emotion/styled'

import githubIcon from 'assets/integrations/github/icon-256.png'
import jiraCloudIcon from 'assets/integrations/jira-cloud/icon-256.png'
import jiraServerIcon from 'assets/integrations/jira-server/icon-256.png'
import trelloIcon from 'assets/integrations/trello/icon-96.png'
import hubspotIcon from 'assets/integrations/hubspot/icon-24.png'
import salesforceIcon from 'assets/integrations/salesforce/icon-24.png'
import shopifyIcon from 'assets/integrations/shopify.png'
import rechargeIcon from 'assets/integrations/recharge.png'
import Tooltip from 'shared/ui/Tooltip'
import themeVars from 'ui_theme/site/globals/site.variables'

const IMAGES = {
  ATLASSIAN_OAUTH2: jiraCloudIcon,
  GITHUB: githubIcon,
  HUBSPOT: hubspotIcon,
  JIRA_CLOUD: jiraCloudIcon,
  JIRA_SERVER: jiraServerIcon,
  TRELLO: trelloIcon,
  SALESFORCE: salesforceIcon,
  SHOPIFY: shopifyIcon,
  SHOPIFY_V2: shopifyIcon,
  RECHARGE: rechargeIcon,
}

const TOOLTIPS = {
  ATLASSIAN_OAUTH2: 'Jira Cloud',
  GITHUB: 'GitHub',
  HUBSPOT: 'HubSpot',
  JIRA_CLOUD: 'Jira Cloud',
  JIRA_SERVER: 'Jira Server',
  SALESFORCE: 'Salesforce',
  SHOPIFY: 'Shopify',
  SHOPIFY_V2: 'Shopify',
  STRIPE: 'Stripe',
  TRELLO: 'Trello',
  RECHARGE: 'Recharge',
}

export default styled(ProviderIcon)`
  height: ${props => props.theme.spacing['11']};
  margin-left: ${props => props.theme.spacing['3']};
  margin-right: ${props => props.theme.spacing['5']};
  vertical-align: bottom;
  border-radius: ${themeVars.defaultBorderRadius};
`

function ProviderIcon({ className, provider }) {
  const src = IMAGES[provider]
  if (src) {
    return (
      <Tooltip as="span" tooltip={TOOLTIPS[provider]}>
        <img className={className} alt={`${provider} logo`} src={src} />
      </Tooltip>
    )
  }
  return null
}
