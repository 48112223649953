import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'

const PrintableMessageCount = styled('div')`
  margin-top: ${props => props.theme.spacing['5']};
`

export function MessageCount({ ticketId }) {
  const { counts: { messages: messageCount = 0 } = {} } = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )

  if (messageCount === 0) return null
  return (
    <PrintableMessageCount className="visible-only-in-print">
      {messageCount} messages
    </PrintableMessageCount>
  )
}
