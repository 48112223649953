import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectMailboxesById } from 'ducks/mailboxes/selectors/selectMailboxesById'
import { selectKnownMailboxes } from 'ducks/mailboxes/selectors/selectKnownMailboxes'
import { getMailboxNameOrEmail } from 'util/mailboxes'
import { selectCurrentFilterQuery } from 'ducks/searches/selectors'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import { buildId, getRawId } from 'util/globalId'
import { QUERY_PRIMARY_ALIAS } from 'ducks/searches/utils/query'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'
import { selectCurrentAgentsById } from 'ducks/agents/selectors'
import { selectContactsByEmail } from 'ducks/crm/contacts/selectors/base'
import { omit } from 'util/objects'
import { styles } from '../styles'
import FilterBadge from './FilterBadge'

const HIDDEN_BADGES = ['search', 'channel']

const FilterBadges = () => {
  const dispatch = useDispatch()
  const currentQuery = useSelector(selectCurrentFilterQuery)
  const contactsByEmail = useSelector(selectContactsByEmail)
  const mailboxes = useSelector(selectKnownMailboxes)
  const mailboxsById = useSelector(selectMailboxesById)
  const foldersById = useSelector(selectCurrentFoldersById)
  const teamsById = useSelector(selectCurrentTeamsById)
  const agentsById = useSelector(selectCurrentAgentsById)

  const amendFilterBadge = useCallback(
    (queryKey, queryValue) => {
      const value = queryValue
      if (queryKey === QUERY_PRIMARY_ALIAS.from) {
        // Amend from: filter to include customer name

        const customer = contactsByEmail[value]
        if (!customer?.name) return queryValue
        return [`${customer.name} (${value})`].join(':')
      }
      if (queryKey === QUERY_PRIMARY_ALIAS.channelId) {
        // Amend inbox: filter to show mailbox name
        // if there're multiple mailboxes with the same name, show email as well
        const mailbox = mailboxsById[getRawId(value)]
        if (!mailbox) return queryValue
        const mailboxName = getMailboxNameOrEmail(mailbox, mailboxes, {
          nameWithEmail: true,
        })
        return [mailboxName].join(':')
      }
      if (queryKey === QUERY_PRIMARY_ALIAS.groupId) {
        // Amend inbox: filter to show mailbox name
        // if there're multiple mailboxes with the same name, show email as well
        const team = teamsById[getRawId(value)]
        if (!team) return queryValue
        return [team.name].join(':')
      }
      if (
        [
          QUERY_PRIMARY_ALIAS.mentions,
          QUERY_PRIMARY_ALIAS.agentId,
          QUERY_PRIMARY_ALIAS.draftAuthorId,
        ].includes(queryKey)
      ) {
        // Amend inbox: filter to show mailbox name
        // if there're multiple mailboxes with the same name, show email as well
        const agent = agentsById[getRawId(value)]
        if (!agent) return queryValue
        return [agent.name].join(':')
      }
      if (queryKey === QUERY_PRIMARY_ALIAS.folderId) {
        // Amend inbox: filter to show mailbox name
        // if there're multiple mailboxes with the same name, show email as well
        const folder = foldersById[buildId('Folder', value)]
        if (!folder) return queryValue
        return [folder.name].join(':')
      }
      return queryValue
    },
    [
      contactsByEmail,
      mailboxes,
      mailboxsById,
      foldersById,
      teamsById,
      agentsById,
    ]
  )

  const handleClear = useCallback(
    () => {
      dispatch(
        doRedirectToCollectionAndFolderById(null, null, {
          preserveQuery: false,
          ignoreLast: false,
        })
      )
    },
    [dispatch]
  )

  if (Object.keys(omit(HIDDEN_BADGES, currentQuery)).length === 0) return null

  return (
    <div
      className="grui p-5 pl-8 flex flex-wrap items-center"
      css={styles.filterBadges}
    >
      <Button size="small" type="secondary" onClick={handleClear}>
        Clear search filters
      </Button>
      {Object.keys(currentQuery).reduce((filters, queryKey) => {
        if (HIDDEN_BADGES.includes(queryKey)) return filters
        const arrayNormalizedQuery = Array.isArray(currentQuery[queryKey])
          ? currentQuery[queryKey]
          : [currentQuery[queryKey]]
        arrayNormalizedQuery.forEach(val => {
          filters.push(
            <FilterBadge
              key={`${queryKey}-${val}`}
              queryKey={queryKey}
              queryValue={val}
              amendedValue={amendFilterBadge(queryKey, val)}
            />
          )
        })
        return filters
      }, [])}
    </div>
  )
}

export default FilterBadges
