import React from 'react'
import cn from 'classnames'
import { css, useTheme } from '@emotion/react'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import PinIcon from 'components/PinIcon'
import RefreshButton from 'components/App/DesktopView/Layout/Sidebar/RefreshButton'
import { styles as checkboxStyles } from 'components/ConversationList/styles'
import { Flex } from 'shared/ui'
import SortMenu from './SortMenu'
import BulkActionBar from './BulkActionBar'

export default function WideSwitcher(props) {
  const { isSelectionMode, BulkActionBarComponent = BulkActionBar } = props
  const theme = useTheme()

  if (isSelectionMode) {
    return <BulkActionBarComponent theme={theme} {...props} />
  }

  return <WideBar theme={theme} {...props} />
}

const pinIconStyle = css`
  &::before {
    vertical-align: middle;
  }
`

const containerStyle = theme => css`
  min-height: 42px;
  padding: ${`${theme.spacing['5']} ${theme.spacing['6']}
    ${theme.spacing['5']} ${theme.spacing['10']}`};
`

function WideBar(props) {
  const {
    canSeePinnedSearches,
    isSearchPinnable,
    onSelectAllClick,
    isAdminOrOwnerOrAgent,
    SortMenuComponent = SortMenu,
  } = props

  return (
    <Flex
      css={containerStyle}
      className="grui items-center conversation-list-header-title-bar"
    >
      {isAdminOrOwnerOrAgent && (
        <Checkbox
          checked={false}
          id="conversation-group-selection"
          onChange={onSelectAllClick}
          css={checkboxStyles.checkbox}
          withRadius
        />
      )}
      <RefreshButton
        className={cn('grui', { 'ml-10': isAdminOrOwnerOrAgent })}
      />
      {canSeePinnedSearches &&
        isSearchPinnable && <PinIcon iconCss={pinIconStyle} />}
      <SortMenuComponent />
    </Flex>
  )
}
