import { useSelector } from 'react-redux'
import ClosableCard from 'components/ClosableCard'
import { selectIsInInbox } from 'selectors/location'
import { FEATURE_INBOX_CANNED_REPLIES_TIER_1 } from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'

import SortableVisibleControlsWithTitle from '../../../SortableVisibleControlsWithTitle'
import { styles } from './styles'
import WidgetCard from '../../WidgetCard'
import { selectConversationCustomFieldsCard } from '../../selectors'

function NewFeatureIcon({ width = 20, height = 12 }) {
  return (
    <svg
      className="grui mr-5"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`
          @keyframes colorFade {
            0% { fill: #6136FF; }
            50% { fill: #19B4FF; }
            100% { fill: #6136FF; }
          }

          .letter {
            animation: colorFade 1.5s infinite;
          }

          .letter:nth-child(1) { animation-delay: 0s; }
          .letter:nth-child(2) { animation-delay: 0.3s; }
          .letter:nth-child(3) { animation-delay: 0.6s; }
        `}
      </style>

      {/* Box with dashed border */}
      <rect
        x="1"
        y="1"
        width={width - 2}
        height={height - 2}
        fill="transparent"
        stroke="#6136FF"
        strokeWidth="1"
        strokeDasharray="2"
        rx={2}
      />

      {/* Animated "NEW" Text */}
      <text
        x={width / 2}
        y={height * 0.7}
        fontSize={height * 0.4}
        fill="#6136FF"
        fontFamily="Arial, sans-serif"
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan className="letter">N</tspan>
        <tspan className="letter">E</tspan>
        <tspan className="letter">W</tspan>
      </text>
    </svg>
  )
}

export default function ConversationCustomField() {
  const { canUseFeature: channelCanUseFeature } = useFeature(
    FEATURE_INBOX_CANNED_REPLIES_TIER_1
  )
  const conversationCustomFieldsCard = useSelector(
    selectConversationCustomFieldsCard
  )
  const isInInbox = useSelector(selectIsInInbox)

  if (!isInInbox || !channelCanUseFeature) return null

  if (conversationCustomFieldsCard) {
    return (
      <WidgetCard
        reorderable={false}
        widgetCard={conversationCustomFieldsCard}
      />
    )
  }

  return (
    <ClosableCard
      persistenceKey={'customWidget.customFields'}
      title={
        <SortableVisibleControlsWithTitle
          reorderable={false}
          title={
            <div className="grui flex items-center">
              <NewFeatureIcon width={26} height={16} />
              <span>Custom Fields</span>
            </div>
          }
        />
      }
      css={styles.card}
    >
      <div className="grui p-8 text-center">
        <span className="rocket">🚀</span>
        Ready to get started with custom fields? <br /> Learn more{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.groovehq.com/help/custom-fields"
        >
          here
        </a>.
      </div>
    </ClosableCard>
  )
}
