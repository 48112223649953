import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css } from '@emotion/react'
import SpacedItems from 'shared/components/ui/SpacedItems'
import TextSearchMarker from 'components/TextSearchMarker'
import { doStartEditingTicketTitle } from 'actions/app'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { styles } from './styles'

const EditableSubjectNormalState = ({ ticketId }) => {
  const dispatch = useDispatch()
  const { subject: title } = useSelector(state =>
    selectCurrentConversationById(state, ticketId)
  )
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )
  const [hasOverflow, setHasOverflow] = useState(false)
  const titleRef = useRef(null)

  // This method checks whether the subject is overflowing and
  // needs to be truncated. When it does we'll decrease the font
  // size and let it break into multiple lines. To do this we need
  // to store if the content fits its box or not.
  const checkOverflow = useCallback(
    () => {
      const titleElement = titleRef.current && titleRef.current.firstChild
      if (!titleElement) return
      const isOverflown = titleElement.scrollWidth > titleElement.offsetWidth
      if (isOverflown !== hasOverflow) {
        setHasOverflow(isOverflown)
      }
    },
    [hasOverflow]
  )

  const handleStartEditingTicketTitle = useCallback(
    () => {
      if (!isAdminOrOwnerOrAgent) return
      dispatch(doStartEditingTicketTitle(ticketId, title))
    },
    [dispatch, ticketId, title, isAdminOrOwnerOrAgent]
  )

  useEffect(
    () => {
      window.addEventListener('resize', checkOverflow)
      return () => {
        window.removeEventListener('resize', checkOverflow)
      }
    },
    [checkOverflow]
  )

  useEffect(
    () => {
      checkOverflow()
    },
    [title, checkOverflow]
  )

  const subject = title ? <TextSearchMarker text={title} /> : 'Add Subject'

  return (
    <SpacedItems.Container
      verticalAlign="center"
      horizontalAlign="center"
      gap="mini"
    >
      <SpacedItems.Item
        className="fullstack-ticket-title"
        grow={1}
        css={css`
          min-width: 0;
        `}
      >
        {!hasOverflow && (
          <div ref={titleRef}>
            <div
              css={[
                styles.truncateStyle,
                styles.subjectStyle,
                styles.largerSubjectStyle,
              ]}
              onClick={handleStartEditingTicketTitle}
            >
              {subject}
            </div>
          </div>
        )}
        {hasOverflow && (
          <div
            css={[styles.subjectStyle, styles.smallerSubjectStyle]}
            onClick={handleStartEditingTicketTitle}
          >
            {subject}
          </div>
        )}
      </SpacedItems.Item>
      <SpacedItems.Item className="visible-only-in-print">
        <div css={styles.printableTicketNumberStyle}>#{ticketId}</div>
      </SpacedItems.Item>
    </SpacedItems.Container>
  )
}

export default EditableSubjectNormalState
