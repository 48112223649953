import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NewConvoHeader from 'components/App/DesktopView/shared/NewConvoHeader'
import LogConversationForm from 'components/App/DesktopView/CommunicationForms/Reply/LogConversation'
import Scroller from 'components/Scroller'
import ImageLightbox from 'components/ImageLightbox'
import { doFetchCurrentContact } from 'ducks/crm/contacts/operations/fetching/doFetchCurrentContact'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { useDraft } from 'ducks/drafts2/hooks'
import { doOpenMainPage } from 'actions/pages'
import { NEW_CONVERSATION_ID } from 'ducks/tickets/constants'
import { isBlank } from 'util/strings'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { styles } from '../shared/styles'

export default function LogConversationPage() {
  const dispatch = useDispatch()
  const queryId = useSelector(selectCurrentQueryId)
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  const ticketId = NEW_CONVERSATION_ID
  const { draftId, loaded } = useDraft(ticketId, 'note')

  useEffect(
    () => {
      dispatch(doFetchCurrentContact())
    },
    [dispatch, draftId]
  )

  useEffect(
    () => {
      if (!isAdminOrOwnerOrAgent) dispatch(doOpenMainPage())
    },
    [dispatch, isAdminOrOwnerOrAgent]
  )

  useEffect(
    () => {
      if (isBlank(queryId)) {
        dispatch(
          doRedirectToCollectionAndFolderById(null, null, {
            ignoreLast: false,
            preservePage: true,
          })
        )
      }
    },
    [dispatch, queryId]
  )

  if (!loaded || !isAdminOrOwnerOrAgent) return null

  return (
    <React.Fragment>
      <NewConvoHeader isLogging />
      <div css={styles.composeFormContainer}>
        <Scroller>
          <LogConversationForm ticketId={ticketId} />
        </Scroller>
      </div>
      <ImageLightbox />
    </React.Fragment>
  )
}
