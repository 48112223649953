import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePostHog } from 'posthog-js/react'

import {
  button,
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import TopNav from 'components/App/DesktopView/Layout/TopNav'
import Title from 'components/App/Title'
import { capture } from 'ducks/tracking/actions'
import { doSetFlag } from 'ducks/flags/operations'
import { HIDE_AI_AGENT_DOT } from 'ducks/flags/flagNames'
import { selectFlag } from 'ducks/flags/selectors'
import Letter from 'components/AiAgent/letter'
import Scroller from 'components/Scroller'
import useCalendlyWidget from 'util/hooks/useCalendlyWidget'
import InlineVideo from 'components/VideoContainer/InlineVideo'
import { styles } from './styles'

const CALENDLY_URL =
  'https://calendly.com/d/crkc-y4y-fyh/helply-early-access-for-groove-customers'

const AIAgentPage = () => {
  const dispatch = useDispatch()
  const hideAiAgentDot = useSelector(selectFlag)(HIDE_AI_AGENT_DOT)
  const currentUser = useSelector(selectCurrentUser)
  const posthog = usePostHog()
  // eslint-disable-next-line no-underscore-dangle
  const isPosthogLoaded = posthog?.__loaded
  const name = [currentUser.first_name, currentUser.last_name]
    .filter(Boolean)
    .join(' ')

  const handleScheduleSuccess = useCallback(
    () => {
      capture('ai_agent_request_access_scheduled', {
        name,
      })
    },
    [name]
  )

  useCalendlyWidget({
    onScheduleSuccess: handleScheduleSuccess,
  })

  const onScheduleClick = useCallback(
    () => {
      capture('ai_agent_request_access', {
        name,
      })

      if (!window.Calendly) {
        window.open(CALENDLY_URL)
        return
      }
      window.Calendly.initPopupWidget({
        url: CALENDLY_URL,
        hide_gdpr_banner: true,
        prefill: {
          email: currentUser.email,
          name,
        },
      })
    },
    [name, currentUser.email]
  )

  useEffect(
    () => {
      if (isPosthogLoaded) {
        capture('ai_agent_page_view')
      }
    },
    [isPosthogLoaded]
  )

  useEffect(
    () => {
      if (hideAiAgentDot === false) {
        dispatch(doSetFlag(HIDE_AI_AGENT_DOT))
      }
    },
    [dispatch, hideAiAgentDot]
  )

  return (
    <>
      <Title />
      <TopNav active="AI Agent" />
      <Scroller className="grui pt-20 px-8">
        <div css={styles.headingContainer}>
          <span css={styles.tag}>
            Coming soon, powered by our sister company: Helply.
          </span>
          <h1 css={[heading.styles.h5, styles.heading]} className="grui mt-12">
            Chatbots Suck. <br /> We&apos;re Fixing That.
          </h1>
          <p css={styles.subheading}>
            Our mission: to build the easiest-to-train, most personalized AI
            agent on the planet—one that actually works.
          </p>
          <Button
            type="info"
            size="xBig"
            className="grui px-14"
            onClick={onScheduleClick}
          >
            I want early access!
          </Button>
          <a
            href="https://www.youtube.com/watch?v=7ptU-pEv2Ds"
            target="_blank"
            rel="noopener noreferrer"
            css={[
              button.styles.link,
              text.styles.textNormal,
              text.styles.fontMedium,
            ]}
            className="grui underline ml-12"
          >
            Learn more
          </a>
        </div>
        <div css={styles.reportingWrapper}>
          <InlineVideo videoId="y4gypfu5sl" />
        </div>
        <Letter>
          <p>{`Hey, let's talk about chatbots.`}</p>
          <p>
            {`Chatbots get a bad rap, and let’s be honest—they’ve earned it. But they don’t have to suck.`}
          </p>
          <p>
            {`When they work, chatbots are incredible. They save time, give fast answers, and let teams focus on bigger challenges. That’s the promise. But for most teams and their customers, that’s not the reality. `}
          </p>
          <p>
            {`Instead, you get bots that are impossible to train and optimize, stuck giving generic answers, or that can’t handle anything outside of a narrow script. `}
          </p>
          <p>
            {`Customers end up frustrated, and your team gets left to clean up the mess. The whole experience just feels broken. `}
          </p>
          <p>
            {`But here’s the thing: the potential for chatbots is still there. They can be helpful, reliable, even delightful—if they’re done right. `}
          </p>
          <p>
            {`They learn from your knowledge base, improve over time, and adapt to your needs—making them smarter, faster, and more reliable. No endless loops, no generic scripts—just a chatbot that works for your team and your customers. `}
          </p>
          <p>Coming soon.</p>
        </Letter>
      </Scroller>
    </>
  )
}

export default AIAgentPage
