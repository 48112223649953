import { css } from '@emotion/react'

const loginPage = theme => css`
  font-family: ${theme.font.tertiary};
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .sui-inner {
    overflow: unset;
  }

  * {
    letter-spacing: -0.02em;
  }

  .ui.label {
    letter-spacing: normal;
  }

  & > * {
    flex: 1;
  }
`

export const styles = {
  loginPage,
}
