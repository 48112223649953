import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = theme => css`
  letter-spacing: -0.1px;
  max-width: ${rem(572)};
  font-size: ${theme.fontSize.x2large};
  margin: ${rem(80)} auto;

  @media (min-width: 768px) {
    max-width: ${rem(752)};
  }
  @media (min-width: 1024px) {
    max-width: ${rem(992)};
    font-size: ${rem(19)};
  }
  @media (min-width: 1440px) {
    max-width: ${rem(1172)};
  }
`

const letter = theme => css`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  border-radius: ${rem(12)};
  background-color: ${theme.color.info['100']};
  padding: ${rem(60)} ${rem(16)} ${rem(60)} ${rem(16)};
  @media (min-width: 768px) {
    padding-left: ${rem(60)};
    padding-right: ${rem(60)};
  }
  @media (min-width: 1024px) {
    max-width: 83%;
    padding-left: ${rem(105)};
    padding-right: ${rem(105)};
    padding-bottom: ${rem(78)};
  }
`

const avatar = theme => css`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 4px solid ${theme.color.monochrome.white};
`

const fist = theme => css`
  position: absolute;
  right: 0;
  top: ${rem(100)};
  display: none;
  transform: translateX(63%);

  & g path:first-child {
    fill: ${theme.color.info['50']};
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

const hands = theme => css`
  position: absolute;
  left: 0;
  bottom: ${rem(180)};
  display: none;
  transform: translateX(-35%);

  & g path:first-of-type {
    fill: ${theme.color.warningAlt['200']};
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

const content = css`
  & > *:not(:last-child) {
    margin-bottom: ${rem(28)};
  }
`

export const styles = {
  container,
  letter,
  avatar,
  fist,
  hands,
  content,
}
