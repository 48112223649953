import { useCallback, useState, useMemo } from 'react'

import { Dropdown } from 'util/ui'

export default function CustomFieldEditDropdown({
  fieldKey,
  options: inputOptions,
  title,
  value: valueProp,
  multiple,
}) {
  const [valueState, setValue] = useState(valueProp)
  const onChange = useCallback(
    (event, { value }) => {
      setValue(value)
    },
    [setValue]
  )
  const options = useMemo(
    () => inputOptions.map(o => ({ text: o.label, value: o.value })),
    [inputOptions]
  )
  return (
    <>
      <Dropdown
        fluid
        onChange={onChange}
        options={options}
        placeholder={`Select ${title}...`}
        selection
        value={valueState}
        multiple={multiple}
      />

      {!multiple && (
        <input
          type="hidden"
          name={`${fieldKey}.content`}
          value={valueState || ''}
        />
      )}
      {multiple &&
        valueState.map((v, i) => {
          const key = `${fieldKey}[${i}].content`
          return (
            <input
              key={key}
              type="hidden"
              name={`${fieldKey}[${i}].content`}
              value={v || ''}
            />
          )
        })}
    </>
  )
}
