import React from 'react'
import { string } from 'prop-types'
import { styles } from './styles'
import { useVideoElement } from './hooks'

const InlineVideo = ({ className, videoId, ratioPercent = 68.7 }) => {
  useVideoElement(videoId)

  if (!videoId) return null

  return (
    <div
      css={styles.inlineVideoWrapper}
      className={className}
      style={{ paddingBottom: `${ratioPercent}%` }}
    >
      <div
        className={`wistia_embed wistia_async_${videoId} videoFoam=true controlsVisibleOnLoad=false playerColor=6136ff`}
        css={styles.video}
      >
        &nbsp;
      </div>
    </div>
  )
}

InlineVideo.propTypes = {
  className: string,
  videoId: string,
}

InlineVideo.defaultProps = {
  className: undefined,
  videoId: undefined,
}

export default InlineVideo
