import { connect } from 'react-redux'
import { selectLastSnoozedDate } from 'selectors/tickets'
import SnoozeIcon from 'components/App/DesktopView/shared/Snooze/Icon'
import { doBulkSnooze } from 'ducks/tickets/actions/doBulkSnooze'

// we can't use a performFactory here since the ticketIds prop can change
const perform = dispatch => ({
  onOptionClickCurried: ticketIds => until => {
    dispatch(doBulkSnooze(ticketIds, until))
  },
})

const select = state => ({
  lastSnoozedDate: selectLastSnoozedDate(state),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { onOptionClickCurried, ...dispatchRest } = dispatchProps
  const { conversationIds, ...ownRest } = ownProps

  return {
    ...stateProps,
    ...dispatchRest,
    ...ownRest,
    onOptionClick: onOptionClickCurried(conversationIds),
  }
}
export default connect(select, perform, mergeProps)(SnoozeIcon)
