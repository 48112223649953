import { getClosest, getParents } from 'util/dom'

export const handlePrintClick = (e, printableContainer) => {
  const startingNode = !e?.target
    ? printableContainer
    : getClosest(e.target, '[data-print-target="message-or-note"]')
  if (!startingNode) return
  const parents = getParents(startingNode)
  const allChildren = Array.from(startingNode.querySelectorAll('*'))
  const nodes = parents
    .concat(allChildren)
    .filter(
      node =>
        !node.classList.contains('hidden-in-print') &&
        !node.classList.contains('visible-only-in-print')
    )

  nodes.forEach(node => {
    let display
    if (typeof node.className === 'string' && node.className.match('meta')) {
      display = 'flex'
    } else {
      const computedStyle = getComputedStyle(node)
      display = computedStyle.display
    }
    node.style.setProperty('display', display, 'important')
  })

  const style = document.createElement('style')
  style.appendChild(document.createTextNode('')) // webkit hack
  document.head.appendChild(style)
  style.sheet.insertRule(
    '@media print { :not(.visible-only-in-print) { display: none; } }'
  )
  style.sheet.insertRule(
    '@media print { .message_actions { opacity: 0 !important; } }'
  )

  function cleanup() {
    style.parentNode.removeChild(style)
    startingNode.style.display = ''
    nodes.forEach(node => {
      if (typeof node.className === 'string' && node.className.match('meta')) {
        node.style.removeProperty('display')
      }
    })
    window.removeEventListener('afterprint', cleanup)
  }
  window.addEventListener('afterprint', cleanup)
  window.print()
}

export const shouldStartExpanded = (
  isForward,
  isFirstMessage,
  parsedBody,
  index
) => {
  // If the following part is text then we don't want to collapse
  // the quoted part. This is a way to try to stop us collapsing
  // quoted text in the middle of message bodies, which was
  // originally intended functionality.
  // Now we are only collapsing quoted parts after the final text
  // part, which should just be the quoted replies.
  //
  const nextPart = parsedBody[index + 1]
  const nextPartText = nextPart && nextPart.type === 'text'

  // If this is an enduser forward and it's the first message in
  // a ticket then we need to expand the quoted part, because that
  // is the forwarded message. This is a bit of a hack but I can't
  // think of a better way right now.
  const firstMessageAndEnduserForward = isForward && isFirstMessage

  return nextPartText || firstMessageAndEnduserForward
}

export const getIntegrationName = provider => {
  if (provider === 'ATLASSIAN_OAUTH2') return 'Jira Cloud'
  if (provider === 'GITHUB') return 'Github'
  if (provider === 'HUBSPOT') return 'Hubspot'
  if (provider === 'JIRA_SERVER') return 'Jira Server'
  if (provider === 'SALESFORCE') return 'Salesforce'
  if (provider === 'SHOPIFY') return 'Shopify'
  if (provider === 'SHOPIFY_V2') return 'Shopify'
  if (provider === 'STRIPE_CONNECT') return 'Stripe'
  if (provider === 'TRELLO') return 'Trello'

  return 'Integration'
}
