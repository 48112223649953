import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { SUI, Dropdown } from 'shared/ui'

const ChangeInboxDropdown = ({
  className,
  doChangeMailbox,
  mailboxes,
  trigger,
  ...rest
}) => {
  const options = useMemo(
    () => {
      return mailboxes.map(mailbox => {
        return {
          text: mailbox.name, // for searchInMenu
          description: `<${mailbox.email}>`, // for searchInMenu
          key: mailbox.id,
          // No propfunc or use callbacked required user because this whole array is
          // cached and will only change when mailboxes or doChangeMailbox changes
          // cached
          onClick: () => doChangeMailbox(mailbox.id),
          value: mailbox.id,
        }
      })
    },
    [mailboxes, doChangeMailbox]
  )
  return (
    <Dropdown
      open
      className={className}
      header={`Change ${app.t('Mailbox')}`}
      icon={null}
      noResultsText={`No ${app.t('mailboxes')} found`}
      options={options}
      placeholder="Filter by name, email..."
      searchInMenu // CAVEAT - if you pass this, options need text[/description] (not just content)
      selectOnBlur={false}
      trigger={trigger}
      {...rest}
    />
  )
}

const ChangeInboxDropdownContainer = ({
  trigger,
  open,
  className,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(!!open)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  return (
    <SUI>
      {!isOpen && (
        <span className={className} onClick={onOpen}>
          {trigger}
        </span>
      )}
      {isOpen && (
        <ChangeInboxDropdown
          {...rest}
          className={className}
          onClose={onClose}
          trigger={trigger}
        />
      )}
    </SUI>
  )
}

export default styled(ChangeInboxDropdownContainer)`
  > .menu {
    max-width: 320px;
  }
`
