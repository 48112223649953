module.exports = {
  appUrl: 'https://groovehq.docker/',
  appDomain: 'groovehq.docker',
  api_url: 'https://api.groovehq.docker',
  chatServerUrl: 'https://chat-server-cluster.staging.groovehq.com',
  graphql_url: 'https://graphql.groovehq.docker/graphql',
  report_url: 'https://graphql.groovehq.docker/reports',
  embeddable_cards_url:
    'https://graphql.groovehq.docker/embeddable_cards/proxy',
  realtime_url: 'realtime.groovehq.docker',
  s3_url: 's3.docker',
  vapidPublicKey:
    'BFrWN1nYNJMotQyCNsajb7QQCL7CKGvhmVr_UgoCeZYJXohTZd-4bJZ1SzOgsN49wh6GBroEF90Tz9oHCbzbwe8=',
  stripePublicKey: 'pk_test_W2m1TzZq858q0esR0oFeMl0V',
  fileStackKey: 'AbnxTnzehQ4KY9hgqQ767z',
  showReports: true,
  showSettings: true,
  api_concurrency: 4,
  gtm_segment: 'Application',
  gtm_version: 'test',
  gtm_containerid: 'GTM-MQ94GV3',
  gtm_auth: 'fG3FotkaRedMYw-ladIp9w',
  gtm_preview: 'env-5',
  google_recaptcha_site_key: '6LdhCn4UAAAAAH0OXvFAtu8mqe3tSwNsiVc3AO_n',
  emojiWidth: 280,
  emojiHeight: 399,
  trialDays: 90,
  trialShowNoticeBannerDaysLeft: 3,
  disableSW: process.env.OFFLINE_RUNTIME === 'off',
  frillWidgets: {
    ideas: {
      key: 'f3249687-32c0-4697-a247-7029260a0535',
    },
    announcements: {
      key: 'ec26475a-3dd8-4012-9106-d1295b6b8719',
    },
    roadmap: {
      key: '4d0a5ca0-2f67-453c-985a-d54e0682d5b4',
    },
  },
  showFrillAnnoucements: true,
  shopify: {
    graphQLApiVersion: '2024-01',
    restApiVersion: '2024-01',
    appStoreGrooveAppUrl: 'https://apps.shopify.com/support-software',
  },
  rechargeClientId:
    'b4ba2d6b12310f7db3c4d27919b7b8e98a87b61cd37f51aa98e9b66cc1bbc009',
  posthog: {
    enabled: false,
    apiKey: 'phc_K6yyzb1xan1e8yaOK9YDgu44kKKfaWTYZfBaUwyRvsy',
    apiHost: 'https://observe-cluster.production.groovehq.com',
  },
  statusPageIOPageId: '73nj5jhb3tk4',
  hotjar: {
    hjid: '3237486',
    hjsv: '7',
  },
}
