import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { dumbLinkify } from 'util/strings'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { doOpen as onOpenImage } from 'actions/lightbox'
import BodySearchMarker from '../BodySearchMarker'

const transformImageArguments = image => [{ url: image.src }]

const TextBody = ({ text }) => {
  const [bodyNode, setBodyNode] = useState(null)
  const imagesRef = useRef([])

  useEffect(() => {
    // Cleanup listener when the component unmounts
    return () => {
      imagesRef.current.forEach(image => {
        if (image.lightboxListener) {
          image.removeEventListener('click', image.lightboxListener)
          // eslint-disable-next-line no-param-reassign
          image.lightboxListener = null
        }
      })
      imagesRef.current = []
    }
  }, [])

  const onBodyNode = useCallback(node => {
    if (!node || !node.querySelectorAll) return
    setBodyNode(node)
    const images = node.querySelectorAll('img')
    const newImages = []

    images.forEach(image => {
      if (!image.lightboxListener) {
        const listener = () => onOpenImage(transformImageArguments(image))
        // eslint-disable-next-line no-param-reassign
        image.lightboxListener = listener
        image.addEventListener('click', listener)
        newImages.push(image)
      }
    })

    imagesRef.current = [...imagesRef.current, ...newImages]
  }, [])

  const formattedText = useMemo(
    () => {
      let processedText = text || ''
      processedText = dumbLinkify(processedText, { showFullUrl: true })
      return processedText
    },
    [text]
  )

  return (
    <>
      <SanitizedHTML
        className="body"
        html={formattedText}
        onBodyNode={onBodyNode}
      />
      <BodySearchMarker node={bodyNode} />
    </>
  )
}

export default TextBody
