import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from 'shared/ui'
import TagsDropdown from 'subapps/settings/components/TagsDropdown'
import { DROPDOWN_MODE_INDETERMINATE } from 'subapps/settings/components/MultiSelectMenu/useMultiSelect'
import { selectTagsWithIntermediateBySelectedTicketIds } from 'ducks/tickets/selectors/selectTagsWithIntermediateBySelectedTicketIds'
import { doBulkUpdateTags } from 'ducks/tickets/actions/doBulkUpdateTags'
import { selectSelectedTicketIds } from 'selectors/ticket_list'
import { TAG_DROPDOWN_VARIANTS } from 'subapps/settings/components/TagsDropdown/constants'

const BulkTagIcon = () => {
  const dispatch = useDispatch()

  const selectedTicketIds = useSelector(selectSelectedTicketIds)
  const ticketTagSelections = useSelector(
    selectTagsWithIntermediateBySelectedTicketIds
  )
  const [selectedIds, setSelectedIds] = useState(ticketTagSelections)
  const [visible, setVisible] = useState(false)
  const [dropdownId, setDropdownId] = useState(1)
  const handleOnVisibleChange = useCallback(inputVisible => {
    setVisible(inputVisible)
  }, [])
  const handleOnSetValue = useCallback(
    newTags => {
      dispatch(doBulkUpdateTags(selectedTicketIds, newTags))
    },
    [dispatch, selectedTicketIds]
  )
  useEffect(
    () => {
      // When the tags dropdown gets closed without saving
      // the changes (clicks cancel, or in open space) we need
      // to reset the current selection
      if (!visible) {
        setDropdownId(currentId => currentId + 1)
      }
    },
    [visible]
  )

  useEffect(
    () => {
      if (!visible) {
        setSelectedIds(ticketTagSelections)
      }
    },
    [dispatch, ticketTagSelections, visible]
  )

  return (
    <TagsDropdown
      // This component caches the initial selectedIds and produces diffs based
      // on those ids and the current user selection. Once the dropdown closes
      // this cache must be invalidated. Its much easier to just change the key
      // and let react deal with cleanup than having to manually do component
      // gymnastics
      key={dropdownId}
      fieldType="ids"
      onSelectedIdsChange={setSelectedIds}
      selectedIds={selectedIds}
      value={selectedIds}
      setValue={handleOnSetValue}
      onVisibleChange={handleOnVisibleChange}
      offset={9}
      mode={DROPDOWN_MODE_INDETERMINATE}
      variant={TAG_DROPDOWN_VARIANTS.INBOX}
    >
      <IconButton
        tooltip="Tag"
        name="tag"
        size="medium"
        svg
        className="menu-button"
      />
    </TagsDropdown>
  )
}

export default BulkTagIcon
