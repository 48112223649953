import React, { useCallback } from 'react'
import { css } from '@emotion/react'
import StateBadge from 'components/StateBadge'
import { useDispatch, useSelector } from 'react-redux'
import { selectCommonStateForSelected } from 'selectors/ticket_list'
import { doBulkToggleStateSelectedTickets } from 'actions/tickets'
import { OPENED } from 'ducks/tickets/constants'

const CloseableStyle = css`
  &&:not(.disabled).closeable {
    padding: 8px;
  }
`

const BulkStateBadge = ({ variant }) => {
  const dispatch = useDispatch()
  const commonState = useSelector(selectCommonStateForSelected) || OPENED

  const handleOnClick = useCallback(
    () => {
      dispatch(doBulkToggleStateSelectedTickets(commonState))
    },
    [dispatch, commonState]
  )

  return (
    <StateBadge
      onClick={handleOnClick}
      state={commonState}
      label={commonState}
      variant={variant}
      size="medium"
      css={CloseableStyle}
    />
  )
}

export default BulkStateBadge
