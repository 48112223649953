import { useCallback, useMemo } from 'react'
import { extension } from 'mime-types'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { IconButton, Icon } from 'shared/ui'
import { humanFileSize } from 'util/strings'
import { strftime } from 'util/date'
import { fixDownloadUrl } from 'util/url'
import { getRawId } from 'util/globalId'

import FileIcon from './FileIcon'
import { styles } from './styles'

export default function Attachment({
  attachment,
  ticketId,
  subdomain,
  isImage,
  onClick: onAttachmentClick,
}) {
  const fileExtension = extension(attachment.fileType)
  const isPDF = /pdf/.test(fileExtension)
  const isMalicious = attachment.type === 'MALICIOUS_ATTACHMENT'
  const isSuspicious = attachment.type === 'SUSPICIOUS_ATTACHMENT'

  const onOpen = useCallback(
    () => {
      const isChangesetInCurrentTicket =
        ticketId === getRawId(attachment.ticketId)

      if (isChangesetInCurrentTicket) {
        // Abit of a hack here, we're intentially not using the changeset-uuid format here
        // to prevent the default browser behavior from jumping to the element with the id
        // because we rather want our custom scroll into view logic to hand it as it takes
        // some additional spacing aspects into account.
        window.location.hash = attachment.changesetId
      } else {
        window.open(
          `/tickets/${getRawId(attachment.ticketId)}#changeset-${
            attachment.changesetId
          }`,
          '_blank'
        )
      }
    },
    [ticketId, attachment]
  )

  const onClick = useCallback(
    () => {
      if (!isImage || isMalicious) {
        return
      }

      onAttachmentClick(attachment.id)
    },
    [onAttachmentClick, attachment, isImage, isMalicious]
  )

  const tooltipTitle = useMemo(
    () => {
      if (isMalicious) {
        return '⛔ Downloading this attachment is disabled. This file is likely a virus/malware.'
      }

      if (isSuspicious) {
        return '⚠️ Be careful downloading this attachment. Flagged as suspicious by Malware scanner'
      }

      return 'Download'
    },
    [isMalicious, isSuspicious]
  )

  return (
    <Tooltip disabled={!isSuspicious && !isMalicious} title={tooltipTitle}>
      <div css={styles.attachment}>
        <div css={styles.content}>
          <FileIcon
            fileType={attachment.fileType}
            fileName={attachment.fileName}
            url={attachment.url}
            onClick={onClick}
            disabled={isMalicious}
            isImage={isImage}
            fileSize={attachment.fileSize}
          />

          <div css={styles.textContent}>
            <div className="grui w-100 flex items-center">
              <span
                css={[styles.fileName, isImage ? styles.imageFileName : null]}
                onClick={onClick}
              >
                <Tooltip
                  title={attachment.fileName}
                  position="top"
                  strategy="fixed"
                >
                  {isPDF && !isMalicious ? (
                    <a
                      href={attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="grui"
                    >
                      <span>{attachment.fileName}</span>
                    </a>
                  ) : (
                    <span>{attachment.fileName}</span>
                  )}
                </Tooltip>
              </span>
              <span css={styles.fileSize}>
                ({humanFileSize(attachment.fileSize)})
              </span>
              {isSuspicious && (
                <Icon
                  className="grui ml-3"
                  size="xsmall"
                  color="color.primary.warning"
                  name="error"
                />
              )}
              {isMalicious && (
                <Icon
                  className="grui ml-3"
                  size="xsmall"
                  color="color.primary.negative"
                  name="error"
                />
              )}
            </div>
            <div className="grui w-100 flex items-center" css={styles.dateText}>
              {attachment.creator && `${attachment.creator.name} - `}
              {strftime('%d %b %Y', attachment.createdAt)}
            </div>
          </div>
        </div>

        <div className="grui hidden attachment-icon-buttons">
          <IconButton
            name="downloadAlt"
            svg
            size="tiny"
            iconSize={14}
            tooltip={isMalicious ? undefined : 'Download'}
            as="a"
            target="_blank"
            href={fixDownloadUrl(attachment.downloadUrl, subdomain)}
            download={attachment.fileName}
            disabled={isMalicious}
          />

          <IconButton
            name="externalLink"
            svg
            onClick={onOpen}
            size="tiny"
            iconSize={14}
            tooltip="Open message"
          />
        </div>
      </div>
    </Tooltip>
  )
}
