import React, { useCallback, useEffect, useState } from 'react'
// import Link from 'redux-first-router-link'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { Help as HelpIcon } from '@groovehq/internal-design-system/lib/assets/icons'

// import logoLight from 'assets/lettermark-light.svg'
// import logoChristmasLight from 'assets/lettermark-chrismas-light.png'
import UserItem from 'components/App/DesktopView/Layout/GlobalNav/UserItem'
// import { doOpenMainPage } from 'actions/pages'
import { shortcutsNew as ShortcutsV2 } from 'assets/icons/groove/v2'
import { doShowHotkeysModal } from 'ducks/modals/hotkeys'
import Search from 'subapps/ticketing/components/TicketList/Header/Search'
import { doFetchBootstrapData } from 'actions/app'
import { isBoostrappedSelector } from 'selectors/app'
import { selectShowAnnualOffer } from 'ducks/billing/selectors/selectShowAnnualOffer'
import AnnualOffer from 'components/AnnualOffer'
import { propFunc } from 'util/functions'
import { selectFlag } from 'ducks/flags/selectors'
import { doSetFlag } from 'ducks/flags/operations'
import { selectIsTestAccount } from 'selectors/account'
import { HIDE_ANNUAL_OFFER } from 'ducks/flags/flagNames'
import { selectIsInInbox, selectIsInChat } from 'selectors/location'
import useOpenContact from 'util/hooks/useOpenContact'

import IconItem from '../GlobalNav/IconItem'
import Help from '../GlobalNav/Help'
import Nav from './Nav'
import { styles } from './styles'
import BurgerMenu from './BurgerMenu'
import Notifications from '../GlobalNav/Notifications'
import DiscountLink from './DiscountLink'

// Don't show G logo for now
// const toMainPage = doOpenMainPage()
const showChristmasLogo = new Date().getMonth() === 11
// const currentLogo = showChristmasLogo ? logoChristmasLight : logoLight

const TopNav = ({ active }) => {
  const dispatch = useDispatch()
  const isAppBootstrapped = useSelector(isBoostrappedSelector)
  const [openAnnualOfferModal, setOpenAnnualOfferModal] = useState(null)
  const shouldAnnualOfferHidden = useSelector(selectFlag)(HIDE_ANNUAL_OFFER)
  const canOpenAnnualOffer =
    openAnnualOfferModal ||
    (openAnnualOfferModal === null && shouldAnnualOfferHidden === false)
  const isTestAccount = useSelector(selectIsTestAccount)
  const isInbox = useSelector(selectIsInInbox)
  const IsChat = useSelector(selectIsInChat)
  const showHotkeysIcon = isInbox || IsChat

  const showAnnualOffer = useSelector(selectShowAnnualOffer) && !isTestAccount

  const openContact = useOpenContact()
  // isFeatureEnabled won't trigger re-render, so we use useFeatureFlagEnabled
  const isHelplyAgentReady = useFeatureFlagEnabled('helply-agent-ready')

  const handleShowHotkeys = useCallback(
    () => {
      dispatch(doShowHotkeysModal())
    },
    [dispatch]
  )

  const handleExitAnnualOffer = useCallback(
    () => {
      setOpenAnnualOfferModal(false)
      if (shouldAnnualOfferHidden !== true) {
        dispatch(doSetFlag(HIDE_ANNUAL_OFFER))
      }
    },
    [dispatch, shouldAnnualOfferHidden]
  )

  useEffect(
    () => {
      if (!isAppBootstrapped) {
        dispatch(doFetchBootstrapData())
      }
    },
    [dispatch, isAppBootstrapped]
  )

  const annualOfferOpen = showAnnualOffer && canOpenAnnualOffer

  return (
    <>
      {showAnnualOffer && (
        <AnnualOffer
          // Will set the feature flag if called onExit
          onExit={handleExitAnnualOffer}
          onClose={propFunc(setOpenAnnualOfferModal, false)}
          open={annualOfferOpen}
        />
      )}
      <div css={styles.base} data-test-id="TopNav">
        {/* <Link
          css={[styles.logo, showChristmasLogo && styles.christmasLogo]}
          to={toMainPage}
        >
          <img src={currentLogo} alt="logo" />
        </Link> */}
        <div className="grui ml-7" />
        <BurgerMenu className="grui mx-5" active={active} />
        <Nav className="grui mx-5" active={active} css={styles.navMenu} />
        <div className="grui flex items-center flex-1 pr-12">
          <Search className="grui mr-16" css={styles.search} />
          <div className="grui ml-auto flex items-center">
            <DiscountLink className="grui mr-6" />
            {showAnnualOffer && (
              <button
                type="button"
                onClick={propFunc(setOpenAnnualOfferModal, true)}
                className="grui px-4 py-2 mr-4"
                css={[button.styles.preflight, styles.present]}
              >
                🎁
              </button>
            )}
            <Notifications />
            {showHotkeysIcon && (
              <IconItem
                onClick={handleShowHotkeys}
                title="Shortcuts"
                css={styles.icon}
              >
                <ShortcutsV2 />
              </IconItem>
            )}
            {!isHelplyAgentReady ? (
              <Help />
            ) : (
              <IconItem className="grui ml-7" onClick={openContact}>
                <HelpIcon />
              </IconItem>
            )}
            <UserItem santa={showChristmasLogo} />
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(TopNav)
