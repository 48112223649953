import React from 'react'
import cn from 'classnames'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'

import Folders from 'subapps/ticketing/components/Folders'
import PinnedSearches from 'components/Folders/Collection/PinnedSearches'

import { styles } from './styles'
import BookDemoCard from './BookDemoCard'
import TeammatesCard from './TeammatesCard'
import { useLeftNav } from './useLeftNav'
import { HeaderWithComposeButton } from './HeaderWithComposeButton'
import OnboardingWorkflow from '../OnboardingWorkflow'

const Leftnav = () => {
  const {
    mailboxes,
    showBookDemoCard,
    showTeammatesCard,
    showOnboardingWorkflow,
  } = useLeftNav()

  if (!mailboxes) return null

  const showFooter =
    !showOnboardingWorkflow && (showBookDemoCard || showTeammatesCard)
  const footer = showFooter ? (
    <>
      {showBookDemoCard && <BookDemoCard />}
      {!showBookDemoCard && showTeammatesCard && <TeammatesCard />}
    </>
  ) : null

  return (
    <Menu
      header={<HeaderWithComposeButton />}
      tight
      animate={false}
      footer={footer}
      className={cn('grui relative', footer && 'with-footer')}
      css={styles.menu}
      dataTestId="inbox-left-nav"
    >
      <>
        <PinnedSearches />
        <Folders type="mailbox" />
        <OnboardingWorkflow.ProgressIndicator className="grui mt-6" />
      </>
    </Menu>
  )
}

export default Leftnav
