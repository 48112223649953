import { useSelector } from 'react-redux'
import logo from 'assets/integrations/jira-cloud/icon-256.png'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import NavigatableIntegrationWidgetCard from '../NavigatableIntegrationWidgetCard'
import LinkedJiraCloudIssuesIndex from './LinkedJiraCloudIssuesIndex'
import LinkedJiraCloudIssue from './LinkedJiraCloudIssue'

export default function JiraCloudWidgetCard({ doHideCard }) {
  const conversationId = useSelector(selectCurrentTicketId)
  if (!conversationId) return null
  return (
    <NavigatableIntegrationWidgetCard
      detailComponent={LinkedJiraCloudIssue}
      doHideCard={doHideCard}
      image={logo}
      indexComponent={LinkedJiraCloudIssuesIndex}
      title="Jira Cloud"
      persistenceKey="jiraCloud"
    />
  )
}
