import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAi } from 'ducks/ai/hooks'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { CONVERSATION_TYPE, COMPOSER_TYPE } from 'ducks/ai/types'

import MessageView from './MessageView'

const AIMessage = ({ onLoading, className }) => {
  const ticketId = useSelector(selectCurrentTicketId)
  const [requestType, setRequestType] = useState()
  const composerTypeAi = useAi(COMPOSER_TYPE)
  const conversationTypeAi = useAi(CONVERSATION_TYPE)
  const clearConversationState = conversationTypeAi.clearState
  const conversationRequestState = conversationTypeAi.requestState
  const canShowConversationTypeView = conversationRequestState.loaded

  const loadingType = useMemo(
    () => {
      const typeMap = {
        [COMPOSER_TYPE]: composerTypeAi,
        [CONVERSATION_TYPE]: conversationTypeAi,
      }
      return Object.keys(typeMap).find(key => typeMap[key].requestState.loading)
    },
    [composerTypeAi, conversationTypeAi]
  )

  useEffect(
    () => {
      if (loadingType) {
        setRequestType(loadingType)
      }
    },
    [loadingType]
  )

  useEffect(
    () => {
      clearConversationState()
    },
    [ticketId, clearConversationState]
  )

  if (!requestType) return null
  return (
    <MessageView
      requestType={requestType}
      className={className}
      canShowConversationTypeView={canShowConversationTypeView}
      clearConversationState={clearConversationState}
      conversationRequestState={conversationRequestState}
      onLoading={onLoading}
      setRequestType={setRequestType}
    />
  )
}

export default AIMessage
