import { connect } from 'react-redux'
import {
  selectEntityCountByQueryId,
  selectQueryIdFromProps,
} from 'ducks/searches/selectors'
import SearchRawCountView from 'components/App/DesktopView/Layout/SearchRawCount/view'

const select = (state, props) => {
  const queryId = selectQueryIdFromProps(state, props)
  return {
    count: selectEntityCountByQueryId(state, queryId),
  }
}

export default connect(select, null)(SearchRawCountView)
