import { connect } from 'react-redux'
import { doBulkUpdateChannelSelectedTickets } from 'actions/tickets'
import { selectPossibleMailboxesForChange } from 'ducks/mailboxes/selectors/selectPossibleMailboxesForChange'
import ChangeInboxDropdown from './view'

const select = state => ({
  mailboxes: selectPossibleMailboxesForChange(state),
})

const perform = {
  doChangeMailbox: doBulkUpdateChannelSelectedTickets,
}

export default connect(select, perform)(ChangeInboxDropdown)
