import { useCallback, useState } from 'react'
import {
  ADDRESS,
  DATE,
  DROPDOWN,
  MULTI_SELECT,
  SINGLE_LINE,
  LINK,
  NUMBER,
  MULTI_LINE,
  TIMEZONE,
} from 'ducks/crm/customFields'
import useDoUpdateCustomFieldValuesFromForm from '../useDoUpdateCustomFieldValuesFromForm'

const EDITABLE_FIELDS = [
  ADDRESS,
  DATE,
  DROPDOWN,
  MULTI_SELECT,
  LINK,
  MULTI_LINE,
  NUMBER,
  SINGLE_LINE,
  TIMEZONE,
]
export default function useEditableCustomFieldKeyValue({
  isEditableProp,
  subjectId,
  type,
}) {
  const isEditable =
    subjectId && isEditableProp !== false && EDITABLE_FIELDS.includes(type)
  const doSaveForm = useDoUpdateCustomFieldValuesFromForm(subjectId)
  const [isOpen, setOpen] = useState(false)
  const doClose = useCallback(
    event => {
      setOpen(false)
      event.stopPropagation()
    },
    [setOpen]
  )
  const doOpen = useCallback(() => setOpen(true), [setOpen])
  if (!isEditable) return { isEditable }
  return {
    isEditable,
    isOpen,
    doClose,
    doOpen,
    doSave: doSaveForm,
  }
}
