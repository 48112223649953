import { connect } from 'react-redux'

import { selectAgents } from 'selectors/agents/base'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import {
  selectSelectedGroup,
  selectSelectedGroupAgentsCount,
  selectSelectedAgent,
  selectFilteredAgentsInSelectedGroup,
  selectTicketAssigning,
  selectIsFilteringPossibleAssignees,
  selectFilteredAgentsInAllGroups,
  selectFilteredGroups,
  selectFilteredGroupsFromAgentFilter,
  selectSuggestedAgents,
} from 'selectors/assignments'
import { selectIsInChat } from 'selectors/location'
import { doBulkAssign } from 'ducks/tickets/actions/doBulkAssign'

import Menu from '../'

const select = state => ({
  isFilteringPossibleAssignees: selectIsFilteringPossibleAssignees(state),
  filteredAgents: selectFilteredAgentsInSelectedGroup(state),
  filteredAgentsAllGroups: selectFilteredAgentsInAllGroups(state),
  filteredGroups: selectFilteredGroups(state),
  filteredGroupsFromAgentFilter: selectFilteredGroupsFromAgentFilter(state),
  isOpen: selectTicketAssigning(state),
  conversationIds: selectSelectedTicketIds(state),
  selectedGroup: selectSelectedGroup(state),
  selectedGroupAgentsCount: selectSelectedGroupAgentsCount(state),
  selectedAgent: selectSelectedAgent(state),
  allAgents: selectAgents(state),
  suggestedAgents: selectSuggestedAgents(state),
  unmountedOnClose: true,
  canShowPresence: selectIsInChat(state),
})

const perform = {
  onGroupSelect: doBulkAssign,
  onAgentSelect: doBulkAssign,
}

export default connect(select, perform)(Menu)
