import cn from 'classnames'

const ExpandedChangesetPlaceholder = ({ className }) => {
  return (
    <div className={cn(className, 'placeholder-collapsed-changeset')}>
      <div className="placeholder-byline" />
      <div className="placeholder-date" />
      <div className="placeholder-cc" />
      <div className="placeholder-content" />
      <div className="placeholder-content" />
      <div className="placeholder-content" />
    </div>
  )
}

export default ExpandedChangesetPlaceholder
