/* eslint-disable react/sort-comp */

import React from 'react'
import cn from 'classnames'

import PropTypes from 'prop-types'
import { notImplemented } from 'util/functions'
import ListenToKeyboard from 'components/ListenToKeyboard'

import StateButtonView from './Buttons/StateButton'
import TagsButtonView from './Buttons/TagsButton'
import SnoozeButtonView from './Buttons/SnoozeButton'
import StarButtonView from './Buttons/StarButton'
import BackButtonView from './Buttons/BackButton'
import MergeButtonView from './Buttons/MergeButton'
import SpamButtonView from './Buttons/SpamButton'
import DeleteButtonView from './Buttons/DeleteButton'
import AssignmentButtonView from './Buttons/AssignmentButton'
import MoreButtonView from './Buttons/MoreButton'
import PagingButtonsView from './Buttons/PagingButtons'
import HardDeleteButtonView from './Buttons/HardDeleteButton'
import RestoreButtonView from './Buttons/RestoreButton'
import CollectionButtonView from './Buttons/CollectionButton'
import SidebarPlaceholderButtonView from './Buttons/SidebarPlaceholderButton'

import HeaderEditingTitleState from './HeaderEditingTitleState'
import styles from './styles.less'
import CopyConversationId from './CopyConversationId'

class ConversationHeader extends React.PureComponent {
  static propTypes = {
    conversationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    isAssigned: PropTypes.bool,
    assignmentAgentName: PropTypes.string,
    assignedGroupId: PropTypes.number,
    isEditingTitle: PropTypes.bool,
    isRightSidebarCollapsed: PropTypes.bool,
    isStarred: PropTypes.bool,
    is3ColumnView: PropTypes.bool,
    isMergeOpen: PropTypes.bool,
    isSnoozed: PropTypes.bool,
    stateLabel: PropTypes.string,
    isCloseable: PropTypes.bool,
    isDeleted: PropTypes.bool,
    isExpired: PropTypes.bool,
    expiresAt: PropTypes.number,
    snoozedUntil: PropTypes.number,
    onStopEditingTitle: PropTypes.func,
    onToggleState: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onStar: PropTypes.func,
    onUnstarred: PropTypes.func,
    onOpenChangeInboxMenu: PropTypes.func,
    onOpenConversationOptionsMenu: PropTypes.func,
    onSnooze: PropTypes.func,
    onOpenMerge: PropTypes.func,
    onUnsnooze: PropTypes.func,
    onSoftDelete: PropTypes.func,
    onHardDelete: PropTypes.func,
    onRestoreDeleted: PropTypes.func,
    onRestoreSpam: PropTypes.func,
    onSpam: PropTypes.func,
    disableMerge: PropTypes.bool,
    disableConversationId: PropTypes.bool,
    disableStar: PropTypes.bool,
    disableTags: PropTypes.bool,
    disableSpam: PropTypes.bool,
    disableDelete: PropTypes.bool,
    disablePaging: PropTypes.bool,
    disableMore: PropTypes.bool,
    disableCollection: PropTypes.bool,
    disableState: PropTypes.bool,
    disableAssignment: PropTypes.bool,
    disableHardDelete: PropTypes.bool,
    disableRestore: PropTypes.bool,
    isTagsAssignModalOpen: PropTypes.bool,
    onShowTagsAssignModal: PropTypes.func,
    onHideTagsAssignModal: PropTypes.func,
  }

  static defaultProps = {
    isAssigned: false,
    assignmentAgentName: '',
    assignedGroupId: null,
    isDeleted: false,
    isSpam: false,
    isStarred: false,
    isSnoozed: false,
    isExpired: false,
    expiresAt: null,
    isEditingTitle: false,
    isRightSidebarCollapsed: false,
    is3ColumnView: false,
    snoozedUntil: null,
    isCloseable: false,
    isMergeOpen: false,
    stateLabel: null,
    onStopEditingTitle: notImplemented('onStopEditingTitle'),
    onToggleState: notImplemented('onToggleState'),
    onClose: notImplemented('onClose'),
    onOpen: notImplemented('onOpen'),
    onStar: notImplemented('onStar'),
    onUnstarred: notImplemented('onUnstarred'),
    onOpenChangeInboxMenu: notImplemented('onOpenChangeInboxMenu'),
    onOpenConversationOptionsMenu: notImplemented(
      'onOpenConversationOptionsMenu'
    ),
    onSnooze: notImplemented('onSnooze'),
    onOpenMerge: notImplemented('onOpenMerge'),
    onUnsnooze: notImplemented('onUnsnooze'),
    onSoftDelete: notImplemented('onSoftDelete'),
    onHardDelete: notImplemented('onHardDelete'),
    onRestoreDeleted: notImplemented('onRestoreDeleted'),
    onRestoreSpam: notImplemented('onRestoreSpam'),
    onSpam: notImplemented('onSpam'),
    onOpenNextConversation: notImplemented('onOpenNextConversation'),
    onOpenPreviousConversation: notImplemented('onOpenPreviousConversation'),
    disableSnooze: false,
    disableMerge: false,
    disableToggleRightSidebar: false,
    disableConversationId: false,
    disableStar: false,
    disableTags: false,
    disableSpam: false,
    disableDelete: false,
    disablePaging: false,
    disableMore: false,
    disableCollection: true,
    disableState: false,
    disableAssignment: false,
    disableHardDelete: false,
    disableRestore: false,
    isTagsAssignModalOpen: false,
    onShowTagsAssignModal: notImplemented('onShowTagsAssignModal'),
    onHideTagsAssignModal: notImplemented('onHideTagsAssignModal'),
  }

  state = {
    snoozeOpen: false,
    assignmentOpen: false,
    moreOpen: false,
  }

  onTagIconClick = () => {
    const { onShowTagsAssignModal } = this.props
    onShowTagsAssignModal()
  }

  onTagDropdownClose = () => {
    const { onHideTagsAssignModal } = this.props
    onHideTagsAssignModal()
  }

  onSnoozeIconClick = () => {
    const { isSnoozed, onUnsnooze, conversationId } = this.props
    if (isSnoozed) {
      onUnsnooze(conversationId)
    } else {
      this.setState({ snoozeOpen: true })
    }
  }

  onSnoozeDropdownClose = () => {
    this.setState({ snoozeOpen: false })
  }

  onSnoozeOptionClick = until => {
    const { onSnooze, conversationId } = this.props
    onSnooze(conversationId, until)
  }

  onMergeIconClick = () => {
    const { onOpenMerge, conversationId } = this.props
    onOpenMerge(conversationId)
  }

  onAssignmentIconClick = () => {
    this.setState({ assignmentOpen: true })
  }

  onAssignmentDropdownClose = () => {
    this.setState({ assignmentOpen: false })
  }

  onMoreIconClick = () => {
    this.setState({ moreOpen: true })
  }

  openOrCloseConversation = () => {
    const {
      conversationId,
      stateLabel,
      isSnoozed,
      isDeleted,
      onToggleState,
    } = this.props
    onToggleState(conversationId, stateLabel, isSnoozed, isDeleted)
  }

  openConversation = () => {
    const { conversationId, onOpen } = this.props
    onOpen(conversationId)
  }

  closeConversation = () => {
    const { conversationId, onClose } = this.props
    onClose(conversationId)
  }

  onStarIconClick = () => {
    const { isStarred } = this.props
    // eslint-disable-next-line no-unused-expressions
    isStarred ? this.unstarConversation() : this.starConversation()
  }

  starConversation = () => {
    const { conversationId, onStar } = this.props
    onStar(conversationId)
  }

  unstarConversation = () => {
    const { conversationId, onUnstarred } = this.props
    onUnstarred(conversationId)
  }

  openMoreDropdown = () => {
    const { onOpenConversationOptionsMenu, conversationId } = this.props
    onOpenConversationOptionsMenu(conversationId)
  }

  openChangeInboxMenu = () => {
    const { onOpenChangeInboxMenu, conversationId } = this.props
    onOpenChangeInboxMenu(conversationId)
  }

  spamConversation = () => {
    const { onSpam, conversationId } = this.props
    onSpam(conversationId)
  }

  restoreSpamConversation = () => {
    const { onRestoreSpam, conversationId } = this.props
    onRestoreSpam(conversationId)
  }

  restoreDeletedConversation = () => {
    const { onRestoreDeleted, conversationId } = this.props
    onRestoreDeleted(conversationId)
  }

  softDeleteConversation = () => {
    const { onSoftDelete, conversationId } = this.props
    onSoftDelete(conversationId)
  }

  hardDeleteConversation = () => {
    const { onHardDelete, conversationId } = this.props
    onHardDelete(conversationId)
  }

  hardDeleteConversation = () => {
    const { onHardDelete, conversationId } = this.props
    onHardDelete(conversationId)
  }

  openNextConversation = () => {
    const { onOpenNextConversation, conversationId } = this.props
    onOpenNextConversation(conversationId)
  }

  openPreviousConversation = () => {
    const { onOpenPreviousConversation, conversationId } = this.props
    onOpenPreviousConversation(conversationId)
  }

  render() {
    const { snoozeOpen, customSnoozeOpen, assignmentOpen } = this.state
    const {
      isMergeOpen,
      isCloseable,
      conversationId,
      assignmentAgentName,
      assignedGroupId,
      isAssigned,
      isSnoozed,
      isStarred,
      isDeleted,
      isSpam,
      isExpired,
      expiresAt,
      isRightSidebarCollapsed,
      isEditingTitle,
      is3ColumnView,
      snoozedUntil,
      onStopEditingTitle,
      isTagsAssignModalOpen,
      BackButton = BackButtonView,
      StateButton = StateButtonView,
      TagsButton = TagsButtonView,
      SnoozeButton = SnoozeButtonView,
      MergeButton = MergeButtonView,
      AssignmentButton = AssignmentButtonView,
      StarButton = StarButtonView,
      MoreButton = MoreButtonView,
      SpamButton = SpamButtonView,
      DeleteButton = DeleteButtonView,
      PagingButtons = PagingButtonsView,
      HardDeleteButton = HardDeleteButtonView,
      RestoreButton = RestoreButtonView,
      CollectionButton = CollectionButtonView,
      SidebarPlaceholderButton = SidebarPlaceholderButtonView,
      disableSnooze,
      disableMerge,
      disableConversationId,
      disableStar,
      disableTags,
      disableSpam,
      disableDelete,
      disablePaging,
      disableMore,
      disableState,
      disableAssignment,
      disableCollection,
      disableHardDelete,
      disableRestore,
      children,
    } = this.props

    if (!conversationId) return null

    if (isEditingTitle) {
      return (
        <HeaderEditingTitleState
          conversationId={conversationId}
          onStopEditingTitle={onStopEditingTitle}
          isRightSidebarCollapsed={isRightSidebarCollapsed}
        />
      )
    }
    const leftActions = []
    const rightActions = []

    if (is3ColumnView) {
      leftActions.push(<BackButton key="back-button" />)
      leftActions.push(<div key="divider-backbutton" className="divider" />)
    }
    if (isDeleted || isSpam) {
      if (!disableHardDelete) {
        leftActions.push(
          <HardDeleteButton
            key="hard-delete-button"
            deleteConversation={this.hardDeleteConversation}
          />
        )
        leftActions.push(
          <div key="divider-delete-restore" className="divider" />
        )
      }
      if (!disableRestore) {
        leftActions.push(
          <RestoreButton
            key="restore-button"
            restoreFromSpam={this.restoreSpamConversation}
            restoreFromDeleted={this.restoreDeletedConversation}
            isDeleted={isDeleted}
            isSpam={isSpam}
          />
        )
      }
    } else {
      if (!disableState) {
        leftActions.push(
          <StateButton
            key="state-button"
            openOrCloseConversation={this.openOrCloseConversation}
            isCloseable={isCloseable}
            isExpired={isExpired}
          />
        )

        leftActions.push(
          <div key="divider-left-actions-1" className="divider" />
        )
      }

      if (!disableStar) {
        leftActions.push(
          <StarButton
            key="star-button"
            isStarred={isStarred}
            onStarIconClick={this.onStarIconClick}
          />
        )
      }
      if (!disableTags) {
        leftActions.push(
          <TagsButton
            key="tag-button"
            conversationId={conversationId}
            tagsOpen={isTagsAssignModalOpen}
            onTagIconClick={this.onTagIconClick}
            onTagDropdownClose={this.onTagDropdownClose}
          />
        )
      }
      if (!disableSnooze) {
        leftActions.push(
          <SnoozeButton
            key="snooze-button"
            snoozeOpen={snoozeOpen}
            customSnoozeOpen={customSnoozeOpen}
            isSnoozed={isSnoozed}
            snoozedUntil={snoozedUntil}
            onSnoozeIconClick={this.onSnoozeIconClick}
            onSnoozeDropdownClose={this.onSnoozeDropdownClose}
            onSnoozeOptionClick={this.onSnoozeOptionClick}
            isExpired={isExpired}
            expiresAt={expiresAt}
          />
        )
      }

      if (!(disableStar && disableTags && disableSnooze)) {
        leftActions.push(
          <div key="divider-left-actions-2" className="divider" />
        )
      }

      if (!disableMerge) {
        leftActions.push(
          <MergeButton
            key="merge-button"
            mergeOpen={isMergeOpen}
            onMergeIconClick={this.onMergeIconClick}
            // NOTE (mbunsch)
            // This is a performance hack
            // this button re-renders when conversationId changes
            // however, there is no point of re-rendering if the dropdown is closed
            // I don't want to rework the modal now to not break it, so I'm just not sending
            // the conversation id when the modal is closed, so it's almost always null, hence no re-render
            conversationId={isMergeOpen && conversationId}
          />
        )
      }

      if (!disableSpam) {
        leftActions.push(
          <SpamButton
            key="spam-button"
            spamConversation={this.spamConversation}
            isExpired={isExpired}
          />
        )
      }

      if (!disableDelete) {
        leftActions.push(
          <DeleteButton
            key="delete-button"
            deleteConversation={this.softDeleteConversation}
            isExpired={isExpired}
          />
        )
      }

      if (!disableSpam || !disableDelete) {
        leftActions.push(
          <div key="divider-left-actions-3" className="divider divider3" />
        )
      }

      if (!disableMore) {
        leftActions.push(
          <MoreButton key="more-button" conversationId={conversationId} />
        )
      }
      if (!disableAssignment) {
        leftActions.push(
          <AssignmentButton
            key="assignment-button"
            assignmentOpen={assignmentOpen}
            isAssigned={isAssigned}
            assignmentAgentName={assignmentAgentName}
            assignedGroupId={assignedGroupId}
            onAssignmentIconClick={this.onAssignmentIconClick}
            onAssignmentDropdownClose={this.onAssignmentDropdownClose}
            isExpired={isExpired}
          />
        )
      }
    }
    if (!disableCollection) {
      rightActions.push(
        <CollectionButton
          conversationId={conversationId}
          key="collection-button"
        />
      )
    }
    if (!disableConversationId) {
      rightActions.push(
        <CopyConversationId
          conversationId={conversationId}
          key="number-button"
        />
      )
    }
    if (!disablePaging && is3ColumnView) {
      rightActions.push(<div key="divider-pading" className="divider" />)
      rightActions.push(<PagingButtons key="paging-buttons-button" />)
    }
    rightActions.push(
      <SidebarPlaceholderButton
        key="sidebarplaceholder-button"
        isRightSidebarCollapsed={isRightSidebarCollapsed}
      />
    )
    return (
      <div
        className={cn('conversation-header', styles['conversation-header'], {
          'right-sidebar-collapsed': isRightSidebarCollapsed,
        })}
      >
        <ListenToKeyboard
          disableForInput
          onShiftO={!disableState && this.openConversation}
          onShiftC={!disableState && this.closeConversation}
          onShiftEquals={!disableStar && this.starConversation}
          onAdd={!disableStar && this.starConversation}
          onDash={!disableStar && this.unstarConversation}
          onSubtract={!disableStar && this.unstarConversation}
          onT={!disableTags && this.onTagIconClick}
          onS={!disableSnooze && this.onSnoozeIconClick}
          onM={!disableMerge && this.onMergeIconClick}
          onA={!disableAssignment && this.onAssignmentIconClick}
          onV={!disableState && this.openChangeInboxMenu}
          onK={this.openNextConversation}
          onJ={this.openPreviousConversation}
          onPeriod={!disableMore && this.openMoreDropdown}
          onShiftNum1={!disableSpam && this.spamConversation}
        />
        <div className="actions left">{leftActions}</div>
        <div className="filler" />
        <div className="actions right">{rightActions}</div>
        {children && children}
      </div>
    )
  }
}

export default ConversationHeader
