import React from 'react'
import { useSelector } from 'react-redux'
import { selectGroupById } from 'ducks/folders/selectors/groups'
import { LABEL_GROUP_ID } from 'ducks/folders/constants'
import TagsGroup from './Tags'
import FolderGroup from './Group'

const FolderGroupSelector = props => {
  const { groupId } = props
  const { resourceId } = useSelector(state => selectGroupById(state, groupId))

  if (resourceId === LABEL_GROUP_ID) {
    return <TagsGroup {...props} />
  }
  return <FolderGroup {...props} />
}

export default FolderGroupSelector
