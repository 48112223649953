import React from 'react'
import { useSelector } from 'react-redux'
import emptyFolder from 'assets/empty_folder.png'
import emptySpam from 'assets/empty_spam.png'
import emptyTrash from 'assets/empty_trash.png'
import StartContent from 'components/StartContent'
import { selectCurrentFolderItem } from 'ducks/folders/selectors/items'
import { styles } from './styles'

const emptyContent = {
  trash: {
    title: 'No trash here!',
    subtitle: '',
    imgSrc: emptyTrash,
  },
  spam: {
    title: 'No spam here!',
    subtitle: '',
    imgSrc: emptySpam,
  },
  other: {
    title: 'This folder is empty',
    subtitle: 'Time to put your feet up and relax for a bit.',
    imgSrc: emptyFolder,
  },
}

const EmptyFolder = () => {
  const currentFolderItem = useSelector(selectCurrentFolderItem)
  const content = emptyContent[currentFolderItem?.kind] || emptyContent.other
  return (
    <div css={styles.emptyFolderContainer}>
      <div css={styles.innerContainer}>
        <div css={styles.imgWrapper}>
          <img src={content.imgSrc} alt="empty" width={300} height={236} />
        </div>
        <StartContent
          title={content.title}
          subtitle={content.subtitle}
          css={styles.content}
        />
      </div>
    </div>
  )
}

export default EmptyFolder
