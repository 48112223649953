import { css } from '@emotion/react'

const questionIcon = theme => css`
  line-height: 1;
  vertical-align: middle;
  &::after {
    content: '?';
    display: inline-block;
    width: ${theme.spacing['7']};
    height: ${theme.spacing['7']};
    border-radius: 50%;
    background-color: ${theme.color.info['900']};
    font-weight: 700;
    font-size: 9px;
    line-height: ${theme.spacing['7']};
    color: ${theme.color.monochrome.white};
    text-align: center;
    vertical-align: text-top;
  }
`

export const styles = {
  questionIcon,
}
