import { connect } from 'react-redux'

import { doAssign } from 'ducks/tickets/actions/doAssign'

import {
  selectFilteredAgentsInAllGroups,
  selectFilteredAgentsInSelectedGroup,
  selectFilteredGroups,
  selectFilteredGroupsFromAgentFilter,
  selectIsFilteringPossibleAssignees,
  selectSelectedAgent,
  selectSelectedGroup,
  selectSelectedGroupAgentsCount,
  selectSuggestedAgents,
  selectTicketAssigning,
} from 'selectors/assignments'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectIsInChat } from 'selectors/location'
import {
  selectCurrentAgents,
  selectCurrentAgentsById,
} from 'ducks/agents/selectors'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'

import Menu from '../'

const select = state => ({
  agentsById: selectCurrentAgentsById(state),
  allAgents: selectCurrentAgents(state),
  filteredAgents: selectFilteredAgentsInSelectedGroup(state),
  filteredAgentsAllGroups: selectFilteredAgentsInAllGroups(state),
  filteredGroups: selectFilteredGroups(state),
  filteredGroupsFromAgentFilter: selectFilteredGroupsFromAgentFilter(state),
  groupsById: selectCurrentTeamsById(state),
  isFilteringPossibleAssignees: selectIsFilteringPossibleAssignees(state),
  isOpen: selectTicketAssigning(state),
  selectedAgent: selectSelectedAgent(state),
  selectedGroup: selectSelectedGroup(state),
  selectedGroupAgentsCount: selectSelectedGroupAgentsCount(state),
  suggestedAgents: selectSuggestedAgents(state),
  conversationIds: selectCurrentTicketId(state),
  canShowPresence: selectIsInChat(state),
})

const perform = {
  onGroupSelect: doAssign,
  onAgentSelect: doAssign,
}

export default connect(select, perform)(Menu)
