import { connect } from 'react-redux'
import {
  selectIntegrationCredentialsForId,
  selectCanDisplayForCurrentConversation,
} from 'ducks/integrations/selectors'
import { selectIsLoading } from 'ducks/integrations/shopify'
import logo from 'assets/integrations/shopify.png'
import withCardDataLoading from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/withCardDataLoading'
import {
  doFetchOrders,
  doFetchShopInfo,
  doFetchEvents,
  doLookupCustomerGidAndFetch,
} from 'ducks/integrations/shopify/operations'
import {
  RECENT_ORDERS_LIMIT,
  EVENTS_LIMIT,
} from 'ducks/integrations/shopify/constants'
import { doFetchCustomerById } from 'ducks/integrations/shopify/operations/doFetchCustomerId'
import { capture } from 'ducks/tracking/actions'
import { doContactResyncIntegrationData } from 'ducks/crm/contacts'

import View from './view'

const selectFactory = (_, initialOwnProps) => {
  const { id: cardId } = initialOwnProps
  const openStateKey = `shopify-${cardId}`
  return (state, { contactId }) => {
    return {
      errorMessage:
        'Please check your Shopify credentials in your settings and try again.',
      image: logo,
      key: openStateKey,
      openStateKey,
      integration: selectIntegrationCredentialsForId(state, {
        id: cardId,
      }),
      showCard: selectCanDisplayForCurrentConversation(state, cardId),
      isShopifyLoading: selectIsLoading(state, cardId, contactId),
    }
  }
}

const performFactory = (_, initialOwnProps) => {
  const { id: integrationId } = initialOwnProps
  return dispatch => ({
    load: async (contactId, { isReload = false } = {}) => {
      if (isReload) {
        capture('Shopify Card Refreshed')
        await dispatch(doContactResyncIntegrationData(contactId, integrationId))
      }

      // We intentionally do this one outside the promise.all to prevent multiple hits
      // to the shopify api to lookup the customerGid using the email
      await dispatch(
        doLookupCustomerGidAndFetch(integrationId, contactId, doFetchShopInfo, {
          skipLoaded: !isReload,
        })
      )

      return Promise.all([
        dispatch(
          doLookupCustomerGidAndFetch(integrationId, contactId, doFetchOrders, {
            limit: RECENT_ORDERS_LIMIT,
            skipLoaded: !isReload,
          })
        ),
        dispatch(
          doLookupCustomerGidAndFetch(integrationId, contactId, doFetchEvents, {
            limit: EVENTS_LIMIT,
            skipLoaded: !isReload,
          })
        ),
        isReload && dispatch(doFetchCustomerById(integrationId)),
      ])
    },
  })
}

export default connect(selectFactory, performFactory)(
  withCardDataLoading(View, {
    useInAppCardView: false,
    canReload: true,
  })
)
