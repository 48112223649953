import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { last } from 'util/arrays'
import {
  selectConversationEventGroupById,
  selectConversationEventsToRenderByGroupId,
} from 'ducks/tickets/selectors'
import { emptyObj } from 'util/objects'

import Rating from '../Rating'
import Action from './Action'

const SubsequentActions = ({
  eventGroupId,
  maxCollapsibleActionsCount = 2,
}) => {
  const { summary: messageId, isFirstMessage } = useSelector(
    state => selectConversationEventGroupById(state, eventGroupId) || emptyObj
  )
  const events = useSelector(state =>
    selectConversationEventsToRenderByGroupId(state, eventGroupId)
  )
  const subsequentActionsCount = events.length
  const showToggleCollapseActions =
    subsequentActionsCount > maxCollapsibleActionsCount

  const [isCollapsed, setIsCollapsed] = useState(showToggleCollapseActions)

  const onToggleClick = useCallback(
    () => {
      setIsCollapsed(!isCollapsed)
    },
    [isCollapsed]
  )

  const renderEvents = useMemo(
    () => {
      if (events.length === 0) return events
      if (!isCollapsed) {
        if (isFirstMessage) return events

        return events.filter(e => e.change?.id !== messageId)
      }

      return [last(events)]
    },
    [isCollapsed, events, messageId, isFirstMessage]
  )

  return (
    <div className="subsequent-list">
      <Rating eventGroupId={eventGroupId} />
      {showToggleCollapseActions && (
        <div
          className="action subsequent toggle-expand-actions"
          onClick={onToggleClick}
        >
          <div className="contents">
            <div className="header">
              <div className="author">
                <div className="byline">
                  {isCollapsed ? 'Show' : 'Hide'} All Actions ({subsequentActionsCount -
                    1})
                </div>
              </div>
              <div className="right-side">
                <div className="datetime" />
              </div>
            </div>
          </div>
        </div>
      )}
      {renderEvents.map(event => {
        const { id: eventId } = event
        return (
          <Action key={eventId} eventGroupId={eventGroupId} eventId={eventId} />
        )
      })}
    </div>
  )
}

export default SubsequentActions
