const CollapsedChangesetPlaceholder = () => {
  return (
    <div className="placeholder-collapsed-changeset">
      <div className="placeholder-byline" />
      <div className="placeholder-date" />
      <div className="placeholder-content" />
    </div>
  )
}

export default CollapsedChangesetPlaceholder
