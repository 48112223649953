import { memo } from 'react'
import cn from 'classnames'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import ResetIcon from '@groovehq/internal-design-system/lib/assets/icons/Reset'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { styles } from '../styles'

const RegenerateButton = ({
  isLoading,
  limitReached,
  onGenerateFullOverview,
}) => {
  return (
    <Button
      disabled={isLoading || limitReached}
      onClick={onGenerateFullOverview}
      customIcon={isLoading ? undefined : <ResetIcon />}
      className={cn('grui w-100 flex mx-auto mt-10', isLoading && 'loading')}
      type="tertiary"
      size="small"
      css={[styles.actionButton, styles.regenerationButton]}
    >
      <span>{isLoading ? 'Generating' : 'Regenerate AI overview'}</span>
      {isLoading && <AnimatedEllipsis />}
    </Button>
  )
}

export default memo(RegenerateButton)
