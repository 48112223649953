import { useCallback, useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doOpenNotePage } from 'actions/pages'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import EditorButton from 'components/EditorButton'

import ScrollerContext from 'components/Scroller/Context'

import { doUpdateEditorVisibility } from 'ducks/editor/operations'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { useAiDraftGenerationAction } from 'ducks/ai/hooks'

import styles from './styles.less'
import AgentCollision from '../AgentCollision'

const Selector = ({ onClick }) => {
  const { getScrollerAPI } = useContext(ScrollerContext)
  const containerRef = useRef(null)
  const iObserverRef = useRef(null)
  const detectorRef = useRef(null)
  const dispatch = useDispatch()
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )
  const ticketId = useSelector(selectCurrentTicketId)

  const {
    isAiDraftGenerationFeatureEnabled,
    aiDraftGenerationIsLoading,
    onAiDraftGenerationClick,
    aiDraftWalletsIsLoaded,
  } = useAiDraftGenerationAction()

  const handleOnNoteClick = useCallback(
    () => {
      dispatch(doUpdateEditorVisibility(true))
      if (onClick) {
        onClick()
      }
      dispatch(doOpenNotePage(ticketId))
    },
    [dispatch, ticketId, onClick]
  )

  const handleClick = useCallback(
    () => {
      dispatch(doUpdateEditorVisibility(true))
      if (onClick) {
        onClick()
      }
    },
    [onClick, dispatch]
  )

  const handleAiClick = useCallback(
    () => {
      onAiDraftGenerationClick()
      if (onClick) {
        onClick()
      }
    },
    [onClick, onAiDraftGenerationClick]
  )

  const handleIntersection = useCallback(
    entry => {
      const container = containerRef.current
      if (!container) return
      // NOTE (jscheel): We don't use state here because react can async and
      // batch update the state, and also updating state requires a re-render.
      // We need this class update to happen *as fast as possible*.
      if (entry.intersectionRatio > 0) {
        container.classList.toggle('floating', false)
      } else {
        container.classList.toggle('floating', true)
      }
    },
    [containerRef]
  )

  useEffect(
    () => {
      const container = getScrollerAPI().getElement()
      let iObserver = iObserverRef.current
      if (container && !iObserver && window.IntersectionObserver) {
        iObserver = new window.IntersectionObserver(
          entries => {
            entries.forEach(handleIntersection)
          },
          {
            root: container,
            threshold: [0, 1],
            rootMargin: '0px 0px 1px 0px',
          }
        )
        iObserverRef.current = iObserver
      }

      return () => {
        if (iObserver) {
          iObserver.disconnect()
        }
      }
    },
    [iObserverRef, getScrollerAPI, handleIntersection]
  )

  useEffect(
    () => {
      const iObserver = iObserverRef.current
      if (!iObserver) return
      const detector = detectorRef.current
      if (detector) iObserver.observe(detector)
    },
    [iObserverRef, detectorRef]
  )

  return (
    <>
      <div
        ref={containerRef}
        className={styles.Selector}
        data-test-id="composer-selector"
      >
        <div className={styles.btns}>
          {isAdminOrOwnerOrAgent && (
            <>
              <EditorButton
                type="info"
                data-test-id="composer-selector-reply-add"
                className={styles.SelectorButton}
                onClick={handleClick}
                size="small"
              >
                Reply <span className={styles.HotKeyIndicator}>[r]</span>
              </EditorButton>
              {isAiDraftGenerationFeatureEnabled && (
                <EditorButton
                  type="infoOutline"
                  data-test-id="composer-selector-reply-add"
                  className={styles.SelectorButton}
                  onClick={handleAiClick}
                  size="small"
                  disabled={
                    !aiDraftWalletsIsLoaded || aiDraftGenerationIsLoading
                  }
                >
                  ✨ {app.t('AI_Draft')}{' '}
                  <span className={styles.HotKeyIndicator}>[d]</span>
                </EditorButton>
              )}
            </>
          )}

          <EditorButton
            type="noteOutlineFilledHover"
            editorButtonType="note"
            className={styles.SelectorButton}
            size="small"
            onClick={handleOnNoteClick}
            data-test-id="composer-selector-comment-add"
          >
            Note <span className={styles.HotKeyIndicator}>[n]</span>
          </EditorButton>
        </div>
        <AgentCollision className="grui ml-auto" />
      </div>
      <div className={styles.SelectorBottomDetector} ref={detectorRef} />
    </>
  )
}

export default Selector
