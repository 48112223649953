import React, { useCallback } from 'react'
import styles from './styles.css'

const UpdateBanner = () => {
  const handleOnClick = useCallback(e => {
    e.preventDefault()
    window.location.reload()
  }, [])

  return (
    <div className={styles.update}>
      <a href="#reload" onClick={handleOnClick}>
        <b>Update available.</b>&nbsp;
        <span>Click here to refresh</span>
      </a>
    </div>
  )
}

export default UpdateBanner
