import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import CollapsableCard from 'components/CollapsableCard'
import ReorderableAndHideableComponents from 'components/ReorderableAndHideableComponents'
import { selectCurrentContactIdOrTemp } from 'ducks/crm/contacts'
import { selectSubjectIdForCategoryType } from 'ducks/crm/customFieldCategories'
import {
  selectCustomFieldIdsForCategoryId,
  selectCustomFieldOrderAndVisibilityForCategoryId,
} from 'ducks/crm/customFields/selectors'
import Negative from '@groovehq/internal-design-system/lib/assets/icons/Negative'
import { selectIsRequiredConversationFieldsFilled } from 'ducks/crm/contacts/selectors/customFields'

import SortableVisibleControlsWithTitle from '../../../SortableVisibleControlsWithTitle'
import {
  selectAreCompanyCustomFieldsLoading,
  selectAreCustomFieldsLoading,
} from '../../../WidgetCards/selectors'

import CustomField from './CustomField'
import ContactHeaderFields from './ContactHeaderFields'
import CompanyHeaderFields from './CompanyHeaderFields'

export default function CustomFieldCategory({
  doHideCard,
  categoryId,
  categoryKey,
  categoryName,
  categoryType,
  className,
  hideable,
  reorderable,
}) {
  const contactId = useSelector(selectCurrentContactIdOrTemp)
  const customFieldIds = useSelector(state =>
    selectCustomFieldIdsForCategoryId(state, { categoryId })
  )
  const defaultFieldOrder = useSelector(state =>
    selectCustomFieldOrderAndVisibilityForCategoryId(state, {
      categoryKey,
      categoryId,
    })
  )
  const subjectId =
    useSelector(state =>
      selectSubjectIdForCategoryType(state, { type: categoryType })
    ) || ''

  const showValidationStatus = subjectId?.startsWith('cnv_')

  const isCustomFieldsValid = useSelector(
    selectIsRequiredConversationFieldsFilled
  )

  const showRequired = showValidationStatus && !isCustomFieldsValid

  const isCompanyLoading =
    useSelector(selectAreCompanyCustomFieldsLoading) &&
    categoryType === 'COMPANY'
  const isLoading =
    useSelector(selectAreCustomFieldsLoading) || isCompanyLoading
  const collection = `category:${categoryId}`
  const itemProps = useMemo(
    () => ({
      categoryType,
      subjectId,
    }),
    [categoryType, subjectId]
  )

  if (!contactId) return null
  if (
    !isLoading &&
    (!customFieldIds || customFieldIds.length === 0) &&
    !['contact_information', 'company_information'].includes(categoryKey)
  ) {
    return null
  }
  const content = (
    <>
      {categoryKey === 'contact_information' && <ContactHeaderFields />}
      {categoryKey === 'company_information' && <CompanyHeaderFields />}
      <ReorderableAndHideableComponents
        className="SortableCustomFieldsContainer"
        collection={collection}
        initialOrder={defaultFieldOrder}
        itemComponent={CustomField}
        itemProps={itemProps}
        itemNoun="field"
        namespace="contact_details_column"
      />
    </>
  )
  if (categoryKey === 'contact_information') {
    return (
      <div className="WidgetCard">
        <div className="ContactInformationCategory">{content}</div>
      </div>
    )
  }
  return (
    <CollapsableCard
      className={className}
      isLoading={isLoading}
      persistenceKey={categoryKey}
      titleIcon={showRequired ? <Negative /> : null}
      title={
        <SortableVisibleControlsWithTitle
          hideable={hideable}
          onHide={doHideCard}
          reorderable={reorderable}
          title={categoryName}
        />
      }
    >
      {content}
    </CollapsableCard>
  )
}
