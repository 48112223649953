import cn from 'classnames'
import AnimateHeight from 'react-animate-height'
import styled from '@emotion/styled'
import { Loader } from 'shared/ui'
import withOpenState from 'shared/ui/withOpenState'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Icon } from 'util/ui'

export default withOpenState(ClosableCard, {
  defaultInitiallyOpen: true,
  persist: true,
})
const transitionDuration = '250ms'
const Styles = styled('div')`
  border: 1px solid transparent;
  border-radius: ${themeVars.defaultBorderRadius};
  background: transparent;
  transition: background ease-in ${transitionDuration},
    border-color ease-in ${transitionDuration};
  margin-bottom: ${props => props.theme.spacing['5']};

  &.sui {
    height: auto;
  }

  &.open,
  &:hover {
    background: white;
    border-color: ${props =>
      `rgba(${props.theme.color.monochrome.black_rgba}, 0.1)`};
    .ClosableCard-title {
      border-color: ${props =>
        `rgba(${props.theme.color.monochrome.black_rgba}, 0.1)`};
    }
  }

  &:hover {
    .ClosableCard-icon {
      opacity: 1;
    }
  }

  &.open .ClosableCard-title .icon {
    transform: rotate(90deg) translate(2px, -4px);
  }

  .ClosableCard-titleIcon {
    display: flex;

    > svg {
      width: 17px;
      height: 17px;
      margin-right: 4px;
      color: ${props => props.theme.color.primary.negative};
      padding-bottom: 2px;
    }
  }

  .ClosableCard-title {
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: ${props => props.theme.color.monochrome.black};
    display: flex;
    font-size: 11px;
    font-family: ${props => props.theme.font.quaternary};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: ${props => props.theme.spacing['10']};
    padding: ${props => props.theme.spacing['7']} ${themeVars.small};
    text-transform: uppercase;
    transition: background ${transitionDuration} ease-in,
      border-color ${transitionDuration} ease-in;
  }

  .ClosableCard-image {
    height: 16px;
    margin-right: ${props => props.theme.spacing['5']};
  }
  .ClosableCard-image,
  .ClosableCard-icon {
    flex-grow: 0;
  }
  .ClosableCard-text {
    flex-grow: 1;
    letter-spacing: ${themeVars['1px']};
    line-height: ${props => props.theme.spacing['10']};
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px 0;
    margin: -5px 0;
    white-space: nowrap;
    padding-right: ${props => props.theme.spacing['5']};
    max-width: 100%;
  }

  .ClosableCard-icon {
    opacity: 0;
    transition: opacity ${transitionDuration} ease-in-out;
    margin-left: 4px;
    margin-right: 3px;
    i.icon {
      transition: transform ${transitionDuration} ease-in-out;
      transform: rotate(0deg) translate(3px, 0px);
    }
  }

  .ClosableCard-content > div {
    display: block !important;
  }

  .ClosableCard-contentInternal {
    padding: ${props => props.theme.spacing['7']};

    &.hideOverflow {
      overflow: hidden;
    }

    &.hidePadding {
      padding: 0;
    }
  }

  .ClosableCard-Loader {
    margin: ${props => props.theme.spacing['10']} auto;
  }
`

function ClosableCard({
  children,
  className,
  hideOverflow = true,
  hidePadding = false,
  image,
  titleIcon,
  isLoading = false,
  open,
  onToggleOpen,
  title,
}) {
  if (!open) return null
  const height = open ? 'auto' : 0
  return (
    <Styles className={cn('sui ClosableCard', className, { open })}>
      <div className="ClosableCard-title" onClick={onToggleOpen}>
        {image && <img className="ClosableCard-image" alt="" src={image} />}
        {titleIcon && <div className="ClosableCard-titleIcon">{titleIcon}</div>}
        <div className="ClosableCard-text">{title}</div>
        <div className="ClosableCard-icon">
          <Icon name="close" />
        </div>
      </div>
      <AnimateHeight
        animateOpacity
        className="ClosableCard-content"
        height={height}
      >
        {isLoading && <Loader className="ClosableCard-Loader" />}
        {!isLoading && (
          <div
            className={cn('ClosableCard-contentInternal', {
              hideOverflow,
              hidePadding,
            })}
          >
            {children}
          </div>
        )}
      </AnimateHeight>
    </Styles>
  )
}
