import React, { useEffect, useRef } from 'react'
import { getParents } from 'util/dom'

const Printable = React.forwardRef(({ ...rest }, ref) => {
  const nodeRef = useRef(null)

  const observer = useRef(null)

  const markPrintable = () => {
    if (!nodeRef.current) return

    const parents = getParents(nodeRef.current)
    const subelements = Array.from(nodeRef.current.querySelectorAll('*'))
    const nodes = parents.concat(subelements)

    nodes.forEach(element => element.classList.add('printable'))
  }

  const setupMutationObserver = () => {
    if (!nodeRef.current) return
    observer.current = new MutationObserver(markPrintable)
    observer.current.observe(nodeRef.current, {
      childList: true,
      subtree: true,
    })
  }

  const saveRef = node => {
    nodeRef.current = node
    // eslint-disable-next-line no-param-reassign
    if (ref) ref.current = node
  }

  useEffect(() => {
    markPrintable()
    setupMutationObserver()

    return () => {
      if (nodeRef.current && observer.current) {
        observer.current.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div {...rest} ref={saveRef} />
})

export default Printable
