import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { css } from '@emotion/react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { SUI, IconButton } from 'shared/ui'
import { notImplemented } from 'util/functions'

import SnoozeDropdown from './Dropdown'
import CustomSnoozeDropdown from './CustomSnooze'

const SUIStyle = css`
  height: 0 !important;
`

const SnoozeDropdownStyle = css`
  height: 0;
`

const SpoofDropdownButtonStyle = css`
  border-top-right-radius: 0.30769231rem !important;
  border-bottom-right-radius: 0.30769231rem !important;
`

const iconButtonUnclickable = css`
  pointer-events: none;
`

// wrap in <SUI> if suiWrap = true
const SUIWrapper = ({ condition, children }) => {
  return condition ? <SUI css={SUIStyle}>{children}</SUI> : children
}

const DropdownSwitcher = ({
  onOptionClick,
  onClose,
  size,
  iconSize,
  iconButtonClassName,
  iconButtonCss,
  iconTrigger,
  open,
  tooltip,
  suiWrap,
  upward,
  spoofDropdownButton,
  customSnoozeModalDirection,
  snoozeButtonLabel,
  expiresAt,
  disabled,
  snoozeDropdownNeedOverflow,
  ...rest
}) => {
  const [isSnoozeOpen, setIsSnoozeOpen] = useState(!!open)
  const [isCustomSnoozeOpen, setIsCustomSnoozeOpen] = useState(false)

  useEffect(
    () => {
      // make sure isSnoozeOpen is updated on open prop update
      setIsSnoozeOpen(open)
    },
    [open]
  )

  useEffect(
    () => {
      if (!isSnoozeOpen && !isCustomSnoozeOpen) {
        // only send onClose after both panels are closed
        // otherwise onClose event is sent from snooze options dropdown when switching to custom snooze modal
        onClose()
      }
    },
    [isSnoozeOpen, isCustomSnoozeOpen, onClose]
  )

  const onSnoozeIconClick = useCallback(
    () => {
      setIsSnoozeOpen(true)
      setIsCustomSnoozeOpen(false)
    },
    [setIsSnoozeOpen, setIsCustomSnoozeOpen]
  )

  const onCustomSnoozeCancel = useCallback(
    () => {
      setIsSnoozeOpen(true)
      setIsCustomSnoozeOpen(false)
    },
    [setIsSnoozeOpen, setIsCustomSnoozeOpen]
  )

  const onCustomSnoozeOptionClick = useCallback(
    () => {
      setIsCustomSnoozeOpen(true)
    },
    [setIsCustomSnoozeOpen]
  )

  const onCustomSnoozeClose = useCallback(
    () => {
      setIsCustomSnoozeOpen(false)
    },
    [setIsCustomSnoozeOpen]
  )

  const onSnoozeClose = useCallback(
    () => {
      setIsSnoozeOpen(false)
    },
    [setIsSnoozeOpen]
  )

  return (
    <Fragment>
      {iconTrigger && (
        <div className="hack-tooltip-wrapper">
          <IconButton
            name="snooze"
            svg
            size={size}
            iconSize={iconSize}
            tooltip={!isSnoozeOpen && !isCustomSnoozeOpen && tooltip}
            className={iconButtonClassName}
            // Remove pointer-event to make sure click the button will close SnoozeDropdown when it's open,
            // otherwise isSnoozeOpen will be set to true in onSnoozeIconClick
            css={[isSnoozeOpen && iconButtonUnclickable, iconButtonCss]}
            onClick={onSnoozeIconClick}
            disabled={disabled}
          />
        </div>
      )}

      <SUIWrapper condition={suiWrap}>
        {isSnoozeOpen && (
          <SnoozeDropdown
            css={suiWrap && SnoozeDropdownStyle}
            onClose={onSnoozeClose}
            onBlur={onSnoozeClose}
            open
            onOptionClick={onOptionClick}
            onCustomSnoozeOptionClick={onCustomSnoozeOptionClick}
            hotkey="s"
            upward={upward}
            disabled={disabled}
            expiresAt={expiresAt}
            {...rest}
          />
        )}
        {isCustomSnoozeOpen &&
          spoofDropdownButton && (
            <div
              role="listbox"
              aria-disabled="false"
              aria-expanded="true"
              css={SpoofDropdownButtonStyle}
              className={cn(
                'ui',
                'active',
                'visible',
                'button',
                'floating',
                'dropdown',
                { upward }
              )}
              tabIndex="0"
            >
              <div />
              <i aria-hidden="true" className="dropdown icon" />
            </div>
          )}
        {isCustomSnoozeOpen && (
          <CustomSnoozeDropdown
            upward={upward}
            direction={customSnoozeModalDirection}
            open
            onCancel={onCustomSnoozeCancel}
            onClose={onCustomSnoozeClose}
            onSnooze={onOptionClick}
            snoozeButtonLabel={snoozeButtonLabel}
            expiresAt={expiresAt}
            snoozeDropdownNeedOverflow={snoozeDropdownNeedOverflow}
          />
        )}
      </SUIWrapper>
    </Fragment>
  )
}

DropdownSwitcher.propTypes = {
  iconTrigger: PropTypes.bool,
  open: PropTypes.bool,
  suiWrap: PropTypes.bool,
  upward: PropTypes.bool,
  spoofDropdownButton: PropTypes.bool,
  tooltip: PropTypes.string,
  customSnoozeModalDirection: PropTypes.string,
  iconSize: PropTypes.number,
  size: PropTypes.string,
  iconButtonClassName: PropTypes.string,
  onClose: PropTypes.func,
  onOptionClick: PropTypes.func,
}

DropdownSwitcher.defaultProps = {
  iconTrigger: true,
  open: false,
  suiWrap: true,
  upward: false,
  spoofDropdownButton: false,
  tooltip: 'Snooze',
  customSnoozeModalDirection: 'right',
  iconSize: null,
  size: 'medium',
  iconButtonClassName: null,
  onClose: () => {},
  onOptionClick: notImplemented('onClose'),
  disabled: false,
}

export default DropdownSwitcher
