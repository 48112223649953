import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import {
  selectAgentsForViewingCollisionByConversationId,
  selectAgentsForReplyingCollisionByConversationId,
  selectAgentsForNotingCollisionByConversationId,
  selectAgentsForTypingReplyCollisionByConversationId,
  selectAgentsForTypingNoteCollisionByConversationId,
} from 'ducks/collisions/selectors'

import AgentsChip from './AgentsChip'
import { styles } from './styles'

const AgentCollision = ({ className }) => {
  const currentTicketId = useSelector(selectCurrentTicketId)
  const viewingAgents = useSelector(state =>
    selectAgentsForViewingCollisionByConversationId(state, currentTicketId)
  )

  const replyingAgents = useSelector(state =>
    selectAgentsForReplyingCollisionByConversationId(state, currentTicketId)
  )
  const notingAgents = useSelector(state =>
    selectAgentsForNotingCollisionByConversationId(state, currentTicketId)
  )
  const typingReplyAgents = useSelector(state =>
    selectAgentsForTypingReplyCollisionByConversationId(state, currentTicketId)
  )
  const typingNoteAgents = useSelector(state =>
    selectAgentsForTypingNoteCollisionByConversationId(state, currentTicketId)
  )

  return (
    <div css={styles.container} className={className}>
      {replyingAgents.length > 0 && (
        <AgentsChip users={replyingAgents} type="replying" />
      )}
      {typingReplyAgents.length > 0 && (
        <AgentsChip users={typingReplyAgents} type="typingReply" />
      )}
      {notingAgents.length > 0 && (
        <AgentsChip users={notingAgents} type="noting" />
      )}
      {typingNoteAgents.length > 0 && (
        <AgentsChip users={typingNoteAgents} type="typingNote" />
      )}
      {viewingAgents.length > 0 && (
        <AgentsChip users={viewingAgents} type="viewing" />
      )}
    </div>
  )
}

export default AgentCollision
