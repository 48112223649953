import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectValidOperators } from 'ducks/searches/selectors/selectValidOperators'
import { indicator } from '@groovehq/internal-design-system/lib/styles/elements'
import cn from 'classnames'
import SUI from 'shared/components/ui/SUI'
import KeyboardNavigator from 'components/KeyboardNavigator'
import InvalidSearchMessage from 'components/App/Pages/SearchPage/InvalidSearchMessage'
import Scroller from 'components/Scroller'
import isSearchValid from 'util/search/isSearchValid'
import isQueryStringReady from 'util/search/isQueryStringReady'
import isQueryStringNeedingValue from 'util/search/isQueryStringNeedingValue'
import { propFunc } from 'util/functions'
import { ENTER, TAB } from 'constants/keys'
import { selectIsCustomFieldsEnabled } from 'selectors/app/selectIsCustomFieldsEnabled'
import Suggestion from './Suggestion'
import RecentSearches from './RecentSearches'
import styles from './styles.css'
import SearchFor from './SearchFor'

const KEY_TITLE_MAP = {
  narrow: 'Suggested filters',
  modifiers: 'Modifiers',
  keyword: 'Keywords',
  is: 'State',
  my: 'Assigned to me',
  assignee: `Assigned to ${app.t('Agent')}`,
  drafts: `Drafts by ${app.t('Agent')}`,
  group: `Assigned to ${app.t('Group')}`,
  mailbox: app.t('Mailboxes'),
  label: 'Tags',
  folder: 'Folders',
  from: 'From',
  after: 'Created after',
  before: 'Created before',
  between: 'Between two specific dates',
  customField: 'Custom field',
}

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER, TAB] }

export default function SearchSuggestions({
  focusElement,
  isSuggestions,
  onSelect,
  onChange,
  onSubmit,
  onMouseDown,
  searchQuery,
  recentSearchQueries,
  suggestions,
  className,
  currentSubmittedTicketSearchFilterMatches,
  shouldShowAllSuggestions,
  currentCommittedTicketSearchQueryCurrentPart,
  setIsSuggestionActive,
  children,
}) {
  const validOperators = useSelector(selectValidOperators)
  const isCustomFieldsEnabled = useSelector(selectIsCustomFieldsEnabled)
  const isNeedingValue = isQueryStringNeedingValue(searchQuery)
  const isReady = searchQuery?.trim()
    ? isQueryStringReady(searchQuery, validOperators)
    : currentSubmittedTicketSearchFilterMatches?.length > 0
  const isInvalidSearch =
    !isSearchValid(searchQuery, validOperators) && !isSuggestions

  const handleSubmitBySearchFor = useCallback(
    () => {
      onSubmit(searchQuery, {
        shouldIncludePreviousSubmittedFilters: true,
      })
    },
    [onSubmit, searchQuery]
  )

  const updatedSuggestions = useMemo(
    () => {
      return Object.keys(suggestions).reduce((acc, key) => {
        if (!shouldShowAllSuggestions && key === 'narrow') {
          // eslint-disable-next-line no-param-reassign
          acc[key] = suggestions[key].slice(0, 3)
        } else if (shouldShowAllSuggestions && key === 'narrow') {
          // eslint-disable-next-line no-param-reassign
          acc[key] = suggestions[key].filter(s => !s.suggestion.startsWith('#'))
          if (isCustomFieldsEnabled) {
            acc[key].unshift({
              suggestion: '#',
              hint: 'Use # to find custom fields.',
              searchQuery: '#',
            })
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          acc[key] = suggestions[key]
        }
        return acc
      }, {})
    },
    [shouldShowAllSuggestions, suggestions, isCustomFieldsEnabled]
  )

  const handleActiveIndexChange = useCallback(
    index => {
      const isSuggestionActive = (index === 0 && !isReady) || index > 0
      setIsSuggestionActive(isSuggestionActive)
    },
    [isReady, setIsSuggestionActive]
  )

  return (
    <KeyboardNavigator
      className={cn(styles.SearchSuggestions, className)}
      focusElement={focusElement}
      onMouseDown={onMouseDown}
      activeKey={searchQuery}
      keys={KEYBOARD_NAVIGATOR_KEYS}
      supressDuplicateActivate
      onActiveIndexChange={handleActiveIndexChange}
    >
      <SUI>
        <Scroller className={styles.SearchSuggestions_scroller}>
          {isInvalidSearch && (
            <InvalidSearchMessage
              searchQuery={searchQuery}
              className="grui pb-10"
            />
          )}
          {isReady && (
            <SearchFor
              onSubmit={handleSubmitBySearchFor}
              searchQuery={searchQuery}
              currentSubmittedTicketSearchFilterMatches={
                currentSubmittedTicketSearchFilterMatches
              }
              currentPart={currentCommittedTicketSearchQueryCurrentPart}
              className={cn({
                'grui mb-7': !isSuggestions && recentSearchQueries.length === 0,
              })}
            />
          )}
          {recentSearchQueries.length > 0 && (
            <RecentSearches
              list={recentSearchQueries}
              onSubmit={onSubmit}
              className={cn(!isSuggestions && 'grui mb-7')}
            />
          )}
          {Object.keys(updatedSuggestions).map((key, groupIndex) => {
            const groupSuggestions = updatedSuggestions[key]
            if (!groupSuggestions || groupSuggestions.length < 1) return null
            return (
              <div
                key={`group-${key}`}
                className={styles.SearchSuggestions_group}
              >
                <div className={styles.SearchSuggestions_title}>
                  {KEY_TITLE_MAP[key] || key}
                </div>
                <div className={styles.SearchSuggestions_suggestions}>
                  {groupSuggestions.map((suggestion, index) => {
                    if (suggestion.isLoading) {
                      return (
                        <div
                          // It's ok to use index for loading list
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          className={styles.SearchSuggestions_loading}
                        >
                          <div className={styles.SearchSuggestions_suggestion}>
                            <div
                              className={styles.suggestion}
                              css={indicator.styles.accentNeutral}
                            >
                              tag:
                            </div>
                            <div className={styles.hint}>loading</div>
                          </div>
                        </div>
                      )
                    }

                    return (
                      <KeyboardNavigator.Item
                        key={[suggestion?.suggestion, suggestion?.id]
                          .filter(Boolean)
                          .join('-')}
                        active={
                          isNeedingValue && index === 0 && groupIndex === 0
                        }
                        activeClassName={
                          styles.SearchSuggestions_activeSuggestion
                        }
                        onSelect={propFunc(
                          onSelect,
                          suggestion.searchQuery,
                          suggestion.id
                        )}
                        onActivate={propFunc(onChange, suggestion.searchQuery)}
                      >
                        <Suggestion suggestion={suggestion} />
                      </KeyboardNavigator.Item>
                    )
                  })}
                </div>
              </div>
            )
          })}
          {children}
        </Scroller>
      </SUI>
    </KeyboardNavigator>
  )
}
