import React, { useMemo } from 'react'

import { css, ClassNames, useTheme } from '@emotion/react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import { withDeferedRender } from 'util/hoc'
import { selectCurrentContactIsMissing } from 'ducks/crm/contacts'

import Loader from '../Loader'
import {
  selectContactDetailsColumnWidgetCardsByKey,
  selectContactDetailsContactInformationCard,
} from './selectors'

import WidgetCard from './WidgetCard'
import MissingContact from './MissingContact'
import ConversationCustomField from './cards/ConversationCustomField'

const helperClass = theme => css`
  z-index: 10000000;
  pointer-events: auto !important;

  && {
    .CollapsableCard-text {
      padding-right: 0;
    }
    .DragHandle::before {
      opacity: 1;
      transform: scale(1);
      transition: none !important;
    }
    .CollapsableCard {
      background: white;
      border-color: ${theme.color.monochrome.medium};
    }
    .SortableVisibleControlsWithTitle-hideButton {
      display: none;
    }
  }

  &&.isInEditMode {
    .CollapsableCard-title {
      padding-right: 11px;
    }
    .SortableVisibleControlsWithTitle-hideButton {
      display: inline-flex;
    }
    .CollapsableCard-icon {
      display: none;
    }
    .CollapsableCard-contentInternal {
      opacity: 0.5;
    }
  }

  &&.isLoading {
    display: none;
  }
`
const SortableContainer = sortableContainer('div')
const SortableElement = sortableElement('div')

export default React.memo(withDeferedRender(WidgetCards, Loader))

const Card = ({ cards, cardKey, visible, index, doHideCard }) => {
  const shouldHideDefaultCustomFieldsCategoryWidget = useMemo(
    () => {
      const cardKeyPatterns = [
        /^customFieldCategory.shopify_/,
        /^customFieldCategory.recharge_/,
      ]

      return cardKey && cardKeyPatterns.some(pattern => cardKey.match(pattern))
    },
    [cardKey]
  )

  if (visible === false) return null
  const card = cards[cardKey]
  if (!card) return null
  if (cardKey === 'customFieldCategory.contact_information') return null
  // The conversation custom fields will automatically be added at the top
  if (cardKey.startsWith('customFieldCategory.cfm_')) return null
  if (shouldHideDefaultCustomFieldsCategoryWidget) return null

  return (
    // eslint-disable-next-line react/no-array-index-key
    <SortableElement
      className="SortableCategory WidgetCard"
      collection="widget_cards"
      key={card.id}
      index={index}
    >
      <WidgetCard doHideCard={doHideCard} widgetCard={card} />
    </SortableElement>
  )
}

const isLoadingCss = css`
  display: none;
`
function WidgetCards({
  cardOrder,
  doHideCard,
  doReorderItems,
  isInEditMode,
  isLoading,
}) {
  const isMissing = useSelector(selectCurrentContactIsMissing)
  const cards = useSelector(selectContactDetailsColumnWidgetCardsByKey)
  const contactInformationCard = useSelector(
    selectContactDetailsContactInformationCard
  )

  const theme = useTheme()
  if (isMissing) return <MissingContact />
  if (!cards || !cardOrder) return null

  return (
    <>
      {isLoading && <Loader />}
      <div css={isLoading && isLoadingCss}>
        <ClassNames>
          {/* we cannot use emotion css prop here because helperClass needs to be
      sent to react-sortable-hoc as a string and default return of emotion
      object */}
          {({ css: cssObjToStr }) => (
            <SortableContainer
              helperClass={cn(
                cssObjToStr`${helperClass(theme)}`,
                'EditModeHelper',
                {
                  isInEditMode,
                }
              )}
              onSortEnd={doReorderItems}
              useDragHandle
            >
              {contactInformationCard && (
                <WidgetCard
                  reorderable={false}
                  widgetCard={contactInformationCard}
                />
              )}
              <ConversationCustomField />
              {cardOrder &&
                cardOrder.map(({ key, visible }, index) => (
                  <Card
                    cardKey={key}
                    key={key}
                    visible={visible}
                    index={index}
                    cards={cards}
                    doHideCard={doHideCard}
                  />
                ))}
            </SortableContainer>
          )}
        </ClassNames>
      </div>
    </>
  )
}
