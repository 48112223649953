/* eslint-disable no-underscore-dangle */
// HERE BE DRAGONS
// Somewhat unsafe way of rendering users' custom profiles (and executing their scripts)
import React from 'react'
import Frame from 'react-iframe-resizer-super'
import { sanitize } from 'dompurify'
import themeVars from 'ui_theme/site/globals/site.variables'
import debug from 'util/debug'
import { getRawId } from 'util/globalId'

// Because we render the custom profile inside a sandboxed iframe, we need to
// inject styles.  This is at best an approximation of the basic groove styles
// for the kind of content we expect to see in a custom profile (headers, lists,
// links etc)
// This is intentionally not 100% perfect, since only a handful of customers
// will ever see this iframe this when they enable 'unsafe' custom profiles.
const basicCss = `
  *,
  *:before,
  *:after {
    box-sizing: inherit;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    color: var(--color-monochrome-dark);
    font-family: ${themeVars.graphik};
    font-size: ${themeVars.fontSize};
    font-weight: ${themeVars.graphikWeightRegular};
    line-height: 1.4285em;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  h1, h2, h3, h4, h5 {
    padding: 0;
    line-height: ${themeVars.lineHeight};
  }

  a {
    line-height: ${themeVars.lineHeight};
    color: ${themeVars.linkColor};
  }
`

const defaultIframeResizerOptions = {
  checkOrigin: false,
  heightCalculationMethod: 'max',
}

// sanitize input and escape any single quote for output inside an object literal.
const clean = str => sanitize(str).replace(/'/g, "\\'")

// wraps given string in single quotes
const quote = str => `'${str}'`

// Makes whitelisted data available inside the custom profile iframe
//
// Returns a string containing valid Javascript. to initialize the global
// 'grooveProfileData' object.
function initProfileScript(ticket) {
  if (!ticket) {
    debug('ERROR: profileData: ticket argument is required')
    return ''
  }

  // Builds an object containing all the whitelisted fields we expose to the
  // iframe. Sanitizes user data for good measure.
  return `{
    function initProfile() {
      window.grooveProfileData = {
        ticket: {
          attachmentCount: ${ticket?.counts?.attachments || 0},
          mailboxId: ${quote(getRawId(ticket.channel))},
          messageCount: ${ticket?.counts?.messages || 0},
          state: ${quote(ticket.state)},
          title: ${quote(clean(ticket.subject))},
          type: ${quote(ticket.__typename)},
        }
      };

      return true;
    };

    initProfile();
  }`
}

export default function SandboxedIframe({
  html,
  iframeResizerOptions = defaultIframeResizerOptions,
  rawCss,
  ticket,
}) {
  const dirty = `data:text/html,<!DOCTYPE html><html>
    <head>
      <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.8/iframeResizer.contentWindow.min.js"></script>
      <script type="text/javascript">${encodeURIComponent(
        initProfileScript(ticket)
      )}</script>
      <style>${encodeURIComponent(basicCss)}</style>
      <style>${encodeURIComponent(rawCss)}</style>
    </head>
    <body>${encodeURIComponent(html)}</body>
  </html>`

  return (
    <Frame
      iframeResizerOptions={iframeResizerOptions}
      sandbox="allow-scripts"
      src={dirty}
    />
  )
}
