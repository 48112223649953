import { memo, useMemo } from 'react'

import SmileyIcon from '@groovehq/internal-design-system/lib/assets/icons/Smiley'
import EmojiPickerButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/EmojiPickerButton'
import { selectReactionsByReactionForEventGroupId } from 'ducks/tickets/selectors/selectReactionsByReactionForEventGroupId'
import { useSelector } from 'react-redux'
import Reaction from './Reaction'

import { styles } from './styles'

const ReactionList = ({ eventGroupId, onClick }) => {
  const reactions = useSelector(state =>
    selectReactionsByReactionForEventGroupId(state, eventGroupId)
  )
  const reactionsList = useMemo(() => Object.keys(reactions), [reactions])

  if (!reactionsList.length) {
    return null
  }

  return (
    <div css={styles.list}>
      {reactionsList.map(reaction => {
        return (
          <Reaction
            key={reaction}
            eventGroupId={eventGroupId}
            reaction={reaction}
            onReactionClick={onClick}
          />
        )
      })}
      {reactionsList.length && (
        <EmojiPickerButton direction="right" onChoose={onClick}>
          <button className="grui" css={[styles.reaction, styles.button]}>
            <SmileyIcon />
          </button>
        </EmojiPickerButton>
      )}
    </div>
  )
}

export default memo(ReactionList)
