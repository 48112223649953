import { useCallback, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { SVGIcon } from 'shared/ui'
import styled from '@emotion/styled'
import { selectBasicLinkUrlForConversationId } from 'ducks/tickets/selectors/selectBasicLinkUrlForConversationId'

const StyledSVGIcon = styled(SVGIcon)`
  vertical-align: bottom;
`

const CopyConversationId = ({ conversationId }) => {
  const basicLink = useSelector(state =>
    selectBasicLinkUrlForConversationId(state, conversationId)
  )
  const [copied, setCopied] = useState(false)

  const handleOnCopy = useCallback(() => {
    setCopied(true)
  }, [])

  useEffect(
    () => {
      const timer = setTimeout(() => setCopied(false), 2000)
      return () => clearTimeout(timer)
    },
    [copied]
  )

  return (
    <CopyToClipboard text={basicLink} onCopy={handleOnCopy}>
      <div key="number-button" className="number" title="Click to copy">
        <span className="label" id="fullstack-conversation-id">
          {copied ? (
            <StyledSVGIcon name="check" size="14" className="link-copied" />
          ) : (
            <>
              <span className="hash-text">#</span>
              <StyledSVGIcon name="copy" size="13" className="external-link" />
            </>
          )}
          {conversationId}
        </span>
      </div>
    </CopyToClipboard>
  )
}

export default CopyConversationId
