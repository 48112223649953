import { connect } from 'react-redux'

import {
  selectDraftAssignmentLabelByTicketId,
  selectDraftByTicketId,
} from 'ducks/drafts2/selectors'
import {
  debouncedHandleDraftChange,
  handleDraftChange,
  doDeleteDraft,
  doSendReplyDraft,
} from 'ducks/drafts2/operations'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'

import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'
import { selectAccountPrefersReplyOpen } from 'selectors/app/selectAccountPreferences'
import { emptyArr } from 'util/arrays'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectIsRequiredConversationFieldsFilled } from 'ducks/crm/contacts/selectors/customFields'

import View from './view'

const DRAFT_TYPE = 'reply'
const select = () => {
  return (state, props) => {
    const { ticketId } = props
    const { isOpen = false, subject = '' } =
      selectCurrentConversationById(state, ticketId) || {}
    const draft = selectDraftByTicketId(state, ticketId, DRAFT_TYPE) || {}
    const {
      id: draftId,
      assigneeGroupId,
      to,
      mailboxId,
      isSendable,
      facebookRecipient: draftFacebookRecipient,
    } = draft

    const facebookRecipient = draftFacebookRecipient || to?.[0] || emptyArr

    const showKbSearch = selectShowKbSearchOnInboxEditor(state)
    const isCustomFieldsValid = selectIsRequiredConversationFieldsFilled(state)

    return {
      draftId,
      syncable: !!draftId,
      isOpen,
      assignmentLabel: selectDraftAssignmentLabelByTicketId(
        state,
        ticketId,
        DRAFT_TYPE
      ),
      assigneeGroupId,
      mailboxId,
      subject: subject || '',
      isSendable: isSendable && isCustomFieldsValid,
      facebookRecipient,
      showKbSearch,
      prefersOpen: selectAccountPrefersReplyOpen(state),
    }
  }
}

const perform = dispatch => {
  return {
    deleteDraft: (ticketId, draftId) => {
      if (draftId) {
        const msg = 'Are you sure you want to delete this draft?'
        if (!window.confirm(msg)) return // eslint-disable-line no-alert
        dispatch(doDeleteDraft(ticketId, draftId))
      } else {
        dispatch(doUpdateEditorVisibility(false))
      }
    },
    debouncedHandleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      debouncedHandleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    handleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    handleDraftMultiChange: (ticketId, fields, opts) => {
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    sendDraft: draftId => {
      dispatch(doSendReplyDraft(draftId))
    },
  }
}

export default connect(select, perform)(View)
