import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const big = css`
  max-width: ${rem(437)};
`

const small = css`
  width: ${rem(314)};
  position: absolute;
  bottom: 0;
  bottom: ${rem(26)};
  left: ${rem(15)};
  z-index: 1;
`

const videoWrapper = css`
  position: relative;
  // Make sure the video height is adaptive to the width
  padding-bottom: 52.19%;
  height: 0;
`

const inlineVideoWrapper = css`
  ${videoWrapper};
  box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;
  border-radius: ${rem(12)};
`

const video = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${rem(4)};
  overflow: hidden;
`

const close = theme => css`
  position: absolute;
  bottom: calc(100% + 3px);
  right: -${rem(18)};
  width: ${rem(24)};
  height: ${rem(24)};
  border: 1px solid ${theme.color.monochrome.medium};
  background-color: ${theme.color.monochrome.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: ${theme.color.monochrome.dark};
  }

  svg {
    width: ${rem(14)};
    height: ${rem(14)};
  }
`

const title = theme => css`
  background: ${theme.color.monochrome.white};
  background: radial-gradient(
    circle,
    ${theme.color.monochrome.white} 0%,
    rgba(${theme.color.monochrome.white_rgba}, 0.6) 80%,
    rgba(${theme.color.monochrome.white_rgba}, 0.2) 100%
  );
`

const titleIcon = css`
  position: absolute;
  right: 100%;
`

const sizes = {
  big,
  small,
}

export const styles = {
  videoWrapper,
  inlineVideoWrapper,
  video,
  close,
  sizes,
  title,
  titleIcon,
}
