import { css } from '@emotion/react'

const item = theme => css`
  font-weight: ${theme.fontWeight.normal};
`

const fixOptionsPosition = css`
  > .grui.truncate {
    margin-left: 2px;
  }
  &:hover {
    [role='presentation'] {
      margin-left: auto;
    }
  }
`

export const styles = {
  item,
  fixOptionsPosition,
}
