import { connect } from 'react-redux'
import { selectDraftByTicketId } from 'ducks/drafts2/selectors'
import { selectIsForwarding } from 'selectors/page'
import { emptyObj } from 'util/objects'
import SwitcherButton from './view'

const select = (state, props) => {
  const { ticketId } = props

  const { isForwarding: isForwardingFromDraft, replyType } =
    selectDraftByTicketId(state, ticketId, 'reply') || emptyObj

  const isForwardingFromQuery = selectIsForwarding(state)

  return {
    replyType: replyType || 'reply-all',
    isForwarding: isForwardingFromDraft || isForwardingFromQuery,
  }
}
export default connect(select)(SwitcherButton)
