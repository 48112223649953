import LoginPage from 'components/Login'
import LogoutPage from 'components/LogoutPage'
import LoginSelectorPage from 'components/LoginSelector'
import LoginMultipleAccountsPage from 'components/LoginMultipleAccounts'
import LoginAgentMissingPage from 'components/LoginAgentMissing'
import ForgotSubdomainPage from 'components/ForgotSubdomain'
import ForgotPasswordPage from 'components/ForgotPassword'
import ChangePasswordPage from 'components/ChangePassword'
import OtpChallengePage from 'components/OtpChallenge'
import OauthErrorPage from 'components/OauthError'
import NotFoundPage from './NotFoundPage'
import RawEmailPage from './RawEmailPage'
import AiAgentPage from './AiAgentPage'

const pages = {
  LoginPage,
  LogoutPage,
  LoginSelectorPage,
  LoginMultipleAccountsPage,
  LoginAgentMissingPage,
  OtpChallengePage,
  OauthErrorPage,
  ForgotSubdomainPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  NotFoundPage,
  RawEmailPage,
  AiAgentPage,
}

export default pages
