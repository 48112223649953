import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Mark from 'mark.js'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'

// This component is used to highlight the search keywords in the ticket body
const BodySearchMarker = ({ node }) => {
  const { search = '' } = useSelector(selectCurrentQuery)

  useEffect(
    () => {
      if (!node || !search.length) return
      const markInstance = new Mark(node)
      markInstance.unmark({
        done: () => {
          markInstance.mark(search.split(' '), {
            accuracy: 'exactly',
          })
        },
      })
    },
    [node, search]
  )

  return null
}

export default BodySearchMarker
