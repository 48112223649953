import ListenToKeyboard from 'components/ListenToKeyboard'
import { connect } from 'react-redux'
import { doOpenNewTicketPage } from 'actions/pages'
import { doShowHotkeysModal } from 'ducks/modals/hotkeys'
import { doNavigateToPinnedSearchForNumKey } from 'ducks/pinnedSearch/actions'
import { doBulkAssignCurrentOrSelectedToCurrentUser } from 'actions/tickets/bulkSelection'

const select = () => ({
  preventDefault: true,
  disableForInput: true,
})
const perform = {
  onShiftForwardSlash: doShowHotkeysModal,
  onShiftN: doOpenNewTicketPage,
  onG: doBulkAssignCurrentOrSelectedToCurrentUser,
  onNum1: doNavigateToPinnedSearchForNumKey,
  onNum2: doNavigateToPinnedSearchForNumKey,
  onNum3: doNavigateToPinnedSearchForNumKey,
  onNum4: doNavigateToPinnedSearchForNumKey,
  onNum5: doNavigateToPinnedSearchForNumKey,
  onNum6: doNavigateToPinnedSearchForNumKey,
  onNum7: doNavigateToPinnedSearchForNumKey,
  onNum8: doNavigateToPinnedSearchForNumKey,
  onNum9: doNavigateToPinnedSearchForNumKey,
  onNum0: doNavigateToPinnedSearchForNumKey,
}

export default connect(select, perform)(ListenToKeyboard)
