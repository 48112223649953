import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { Tooltip } from 'shared/ui'
import { detect } from 'util/browser'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { CONVERSATION_TYPE_WIDGET } from 'ducks/tickets/constants'
import { emptyObj } from 'util/objects'

import styles from './styles.css'

const availableIcons = [
  'chrome',
  'edge',
  'firefox',
  'internet-explorer',
  'opera',
  'safari',
]

const iconName = (browser, platform) => {
  const {
    browser: { name: browserName },
  } = detect(`${browser} (${platform})`)
  return availableIcons.includes(browserName) ? browserName : 'unknown'
}

const TrackingDetails = ({ ticketId }) => {
  const { browser, platform, pageUrl, __typename: type } = useSelector(
    state => selectCurrentConversationById(state, ticketId) || emptyObj
  )

  const tooltip = useMemo(
    () => {
      if (platform || browser) {
        return `${platform || ''} ${browser || ''}`
      }
      return null
    },
    [platform, browser]
  )

  const getIconName = useCallback(
    () => {
      return iconName(browser, platform)
    },
    [browser, platform]
  )

  if (type !== CONVERSATION_TYPE_WIDGET || !pageUrl) return null

  const icon = (
    <div
      className={cn(`Icon Icon-browser-${getIconName()}`, styles.browserIcon)}
    />
  )

  return (
    <div className={styles.TrackingDetails}>
      {tooltip ? (
        <Tooltip tooltip={tooltip} className={styles.tooltip} position="right">
          {icon}
        </Tooltip>
      ) : (
        <>{icon}&nbsp;</>
      )}
      <div className={styles.label}>
        Sent from your widget
        {pageUrl && (
          <>
            &nbsp;at&nbsp;
            <a
              className={styles.pageTitle}
              rel="noopener noreferrer"
              href={pageUrl}
              target="_blank"
            >
              {pageUrl}
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default TrackingDetails
