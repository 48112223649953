import React, { useCallback } from 'react'
import cn from 'classnames'
import { css } from '@emotion/react'
import { IconButton } from 'shared/ui'
import { useDispatch, useSelector } from 'react-redux'
import { selectAreAllSelectedStarred } from 'selectors/ticket_list'
import { doBulkStarSelectedTickets } from 'actions/tickets'

const StarStyle = () => theme => css`
  &&:not(.disabled).star:hover svg path {
    fill: ${theme.color.primary.warning};
  }

  &&:not(.disabled).starred svg path,
  &&:not(.disabled).starred:hover svg path {
    fill: ${theme.color.primary.warning};
  }
`

const BulkStarIcon = ({ iconSize }) => {
  const dispatch = useDispatch()
  const areAllSelectedStarred = useSelector(selectAreAllSelectedStarred)

  const handleOnClick = useCallback(
    () => {
      dispatch(doBulkStarSelectedTickets())
    },
    [dispatch]
  )

  return (
    <IconButton
      tooltip={`${
        areAllSelectedStarred ? 'Unstar' : 'Star'
      } all selected items`}
      name={areAllSelectedStarred ? 'starSelected' : 'star'}
      onClick={handleOnClick}
      size="medium"
      iconSize={iconSize}
      active={areAllSelectedStarred}
      css={StarStyle}
      className={cn('star', { starred: areAllSelectedStarred })}
      svg
    />
  )
}
export default BulkStarIcon
