import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { capture } from 'ducks/tracking/actions'
import { getEditor, COMPOSER_ID, isTextSelected } from 'shared/editor/utils'
import Like from '@groovehq/internal-design-system/lib/assets/icons/facebook-messenger/Like'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Negative from '@groovehq/internal-design-system/lib/assets/icons/Negative'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import {
  button,
  text as textStyle,
} from '@groovehq/internal-design-system/lib/styles/elements'
import EditorButton from 'components/EditorButton'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import { dumbLinkify } from 'util/strings'
import {
  useAi,
  useChargeCreditFromAiDraftWallet,
  useFetchAiConversationSources,
} from 'ducks/ai/hooks'
import { getRpcMethodFromActionId } from 'ducks/ai/operations'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectPrefersAiEnabled } from 'selectors/app/selectAccountPreferences'
import { buildId } from 'util/globalId'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'
import { selectIsEditorVisible } from 'ducks/editor'
import { selectIsAddingNote } from 'selectors/page'
import { doUpsertReplyDraft } from 'ducks/drafts2/operations/doUpsertReplyDraft'
import { selectDraftIdByTicketId } from 'ducks/drafts2/selectors'
import {
  CONVERSATION_TYPE,
  TEXT_REFINER_SERVICE,
  CREATE_REPLY_ACTION,
  SPELLING_ACTION,
  SHORTEN_ACTION,
  LENGTHEN_ACTION,
  FORMAL_ACTION,
  CASUAL_ACTION,
  CLARIFY_ACTION,
  AI_MODELS,
} from 'ducks/ai/types'
import AiButton, {
  aiWritingTools,
} from 'components/App/DesktopView/CommunicationForms/Reply/shared/AiButton'
import {
  threeStars as ThreeStars,
  starsCircle as StarsCircle,
} from 'assets/icons/groove/v2'
import colorfulTop from 'assets/colorful-top.png'
import useIsMounted from 'util/hooks/useIsMounted'
import { selectAccount } from 'selectors/app'
import ResponseWithDocuments from './ResponseWithDocuments'
import StreamingResponse from './StreamingResponse'
import { styles } from './styles'
import UsageCounter from './UsageCounter'
import Warning from './Warning'

export const AI_COMPOSER_ACTIONS = aiWritingTools.map(tool => tool.id)

export const ALLOWED_AI_ACTIONS = [CREATE_REPLY_ACTION, ...AI_COMPOSER_ACTIONS]

const ComposerActionButtons = ({
  handleCopyToComposer,
  handleRemoveAndClose,
  isNote,
  showAiEditButton,
  className,
  lastAiType,
  globalTicketId,
  response,
  rating,
}) => {
  const [showThankyou, setShowThankyou] = useState(false)
  const isMounted = useIsMounted()
  const handleOnRating = useCallback(
    buttonRating => {
      setShowThankyou(true)
      capture(`ai ${lastAiType} rated`, {
        ticket_id: globalTicketId,
        rating: buttonRating,
        content: response,
      })
    },
    [lastAiType, response, globalTicketId]
  )

  const handleGoodRating = useCallback(
    () => {
      handleOnRating(1)
    },
    [handleOnRating]
  )

  const handleBadRating = useCallback(
    () => {
      handleOnRating(-1)
    },
    [handleOnRating]
  )

  useEffect(
    () => {
      if (showThankyou) {
        setTimeout(() => {
          if (isMounted()) setShowThankyou(false)
        }, 5000)
      }
    },
    [showThankyou, isMounted]
  )

  return (
    <div className={cn('grui flex items-center', className)}>
      <EditorButton
        type="tertiary"
        size="small"
        css={styles.tertiaryBtn}
        onClick={handleCopyToComposer}
      >
        {`Add to ${isNote ? 'note' : 'reply'}`}
      </EditorButton>
      {showAiEditButton && <AiButton />}
      {!showAiEditButton && (
        <button
          css={[button.styles.tertiaryLink, styles.btnLink]}
          className="grui mr-5"
          onClick={handleRemoveAndClose}
        >
          Discard
        </button>
      )}
      {!rating &&
        !showThankyou && (
          <div css={styles.aiRatingButtons}>
            <Tooltip title="Good draft" strategy="fixed">
              <Button type="icon" onClick={handleGoodRating} size="small">
                <Like />
              </Button>
            </Tooltip>
            <Tooltip title="Bad draft" strategy="fixed">
              <Button type="icon" onClick={handleBadRating} size="small">
                <Like />
              </Button>
            </Tooltip>
          </div>
        )}
      {showThankyou && (
        <div css={styles.aiRatingDescription} className="grui ml-5">
          Thanks! Your ratings make will make Groove AI smarter.
        </div>
      )}
      {showAiEditButton && (
        <button
          css={[button.styles.tertiaryLink, styles.btnLink]}
          className="grui ml-auto"
          onClick={handleRemoveAndClose}
        >
          Discard
        </button>
      )}
    </div>
  )
}

const MessageView = ({
  canShowConversationTypeView,
  clearConversationState,
  conversationRequestState,
  requestType,
  className,
  onLoading,
  setRequestType,
}) => {
  const {
    requestAndWait,
    clearState,
    cancelRequest,
    requestState: {
      response,
      loaded,
      error,
      aiType,
      lastAiType,
      loading,
      rating,
    },
  } = useAi(requestType)
  useFetchAiConversationSources(aiType, lastAiType)
  const ticketId = useSelector(selectCurrentTicketId)
  const { id: accountHashedId } = useSelector(selectAccount)
  const accountId = buildId('Account', accountHashedId)
  useChargeCreditFromAiDraftWallet(aiType, lastAiType, accountId)
  const isAddingNote = useSelector(selectIsAddingNote)
  const isAddingReply = useSelector(selectIsEditorVisible)
  const prefersAiEnabled = useSelector(selectPrefersAiEnabled)
  const globalTicketId = buildId('Conversation', ticketId)
  const dispatch = useDispatch()
  const backgroundImageRef = useRef()
  const containerRef = useRef()
  const [modelIndexForReplyCreation, setModelIndexForReplyCreation] = useState(
    0
  )

  const canShowUsageCounter = !loading && canShowConversationTypeView

  const editOrGeneration =
    aiType === CREATE_REPLY_ACTION ? 'Generation' : 'Edit'

  const conversationAnswer = conversationRequestState.response?.answer
  const canShowFooter =
    AI_COMPOSER_ACTIONS.includes(lastAiType) && !canShowConversationTypeView

  const handleStopGenerating = useCallback(
    () => {
      capture(`ai ${aiType} canceled`, {
        ticket_id: globalTicketId,
      })
      cancelRequest()
      if (canShowConversationTypeView && requestType !== CONVERSATION_TYPE) {
        setRequestType(CONVERSATION_TYPE)
      }
    },
    [
      cancelRequest,
      aiType,
      globalTicketId,
      canShowConversationTypeView,
      setRequestType,
      requestType,
    ]
  )
  const draftId = useSelector(state =>
    selectDraftIdByTicketId(state, ticketId, isAddingNote ? 'note' : 'reply')
  )

  const handleCopyToComposer = useCallback(
    async () => {
      if (!conversationAnswer) {
        return
      }

      if (isAddingNote) {
        dispatch(
          doUpsertReplyDraft(draftId, 'note', ticketId, null, {
            body: conversationAnswer,
          })
        )
      } else {
        if (isAddingReply) {
          const tinyEditor = getEditor(COMPOSER_ID)
          tinyEditor.insertContent(conversationAnswer)
        }

        dispatch(
          doUpsertReplyDraft(draftId, 'reply', ticketId, null, {
            body: conversationAnswer,
          })
        )
      }

      await dispatch(doUpdateEditorVisibility(true))

      clearState()
      if (canShowConversationTypeView) {
        clearConversationState()
      }

      capture(`ai ${CREATE_REPLY_ACTION} copied`, {
        ticket_id: globalTicketId,
      })
    },
    [
      dispatch,
      ticketId,
      globalTicketId,
      conversationAnswer,
      draftId,
      isAddingReply,
      isAddingNote,
      clearState,
      canShowConversationTypeView,
      clearConversationState,
    ]
  )

  const updateEditorFromResponse = useCallback(
    async () => {
      const editor = getEditor(COMPOSER_ID)
      if (!response || !editor) {
        return
      }
      editor.undoManager.transact(() => {
        if (isTextSelected(editor)) {
          editor.selection.setContent(response)
        } else {
          editor.setContent(response)
        }
      })
      clearState()
    },
    [response, clearState]
  )

  const handleRemoveAndClose = useCallback(
    () => {
      capture(`ai ${lastAiType} discarded`, {
        ticket_id: globalTicketId,
      })
      clearState()
      if (canShowConversationTypeView) {
        clearConversationState()
      }
    },
    [
      clearState,
      canShowConversationTypeView,
      clearConversationState,
      globalTicketId,
      lastAiType,
    ]
  )

  const prepareParams = useCallback(
    (id, model) => {
      switch (id) {
        case CREATE_REPLY_ACTION:
          return { conversationId: globalTicketId, model }
        default:
          return {}
      }
    },
    [globalTicketId]
  )

  const prepareParamsForComposerType = useCallback(
    id => {
      const editor = getEditor(COMPOSER_ID)
      let text = ''
      if (!editor && !conversationAnswer) {
        cancelRequest()
        return false
      }
      if (!conversationAnswer) {
        text = isTextSelected(editor)
          ? editor.selection.getContent()
          : editor.getContent()
      } else {
        text = conversationAnswer
      }

      switch (id) {
        case SPELLING_ACTION:
          return { text }
        case SHORTEN_ACTION:
          return { length: 'concise', text }
        case LENGTHEN_ACTION:
          return { length: 'verbose', text }
        case FORMAL_ACTION:
          return { tone: 'formal', text }
        case CASUAL_ACTION:
          return { tone: 'casual', text }
        case CLARIFY_ACTION:
          return { text }
        default:
          return {}
      }
    },
    [cancelRequest, conversationAnswer]
  )

  const toggleReplyModelForTryAgain = useCallback(
    (isComposerType, isTryAgain) => {
      if (!isComposerType && isTryAgain) {
        const nextModelIndex =
          (modelIndexForReplyCreation + 1) % AI_MODELS.length
        setModelIndexForReplyCreation(nextModelIndex)
        return AI_MODELS[nextModelIndex]
      }
      setModelIndexForReplyCreation(0)
      return AI_MODELS[0]
    },
    [modelIndexForReplyCreation]
  )

  const doAiRequest = useCallback(
    async isTryAgain => {
      const isComposerType = AI_COMPOSER_ACTIONS.includes(aiType)
      const model = toggleReplyModelForTryAgain(isComposerType, isTryAgain)
      const params = isComposerType
        ? prepareParamsForComposerType(aiType)
        : prepareParams(aiType, model)
      const method = getRpcMethodFromActionId(aiType)
      capture(`ai ${aiType} request`, {
        ticket_id: globalTicketId,
      })
      await requestAndWait({
        service: TEXT_REFINER_SERVICE,
        method,
        params,
      })
    },
    [
      requestAndWait,
      aiType,
      prepareParams,
      prepareParamsForComposerType,
      globalTicketId,
      toggleReplyModelForTryAgain,
    ]
  )

  const handleOnTryAgain = useCallback(
    () => {
      doAiRequest(true)
    },
    [doAiRequest]
  )

  const doAiRequestRef = useRef(doAiRequest)
  useEffect(
    () => {
      doAiRequestRef.current = doAiRequest
    },
    [doAiRequest]
  )

  // doAiRequestRef.current: Prevent doAiRequest changes make the useEffect to run again
  useEffect(
    () => {
      if (aiType && ALLOWED_AI_ACTIONS.includes(aiType)) {
        doAiRequestRef.current()
      }
    },
    [aiType]
  )

  useEffect(
    () => {
      if (loading && onLoading) {
        onLoading()
      }
    },
    [loading, onLoading]
  )

  useEffect(
    () => {
      if (!backgroundImageRef.current && loading) {
        backgroundImageRef.current = colorfulTop
        // Preload the bg
        const img = new Image()
        img.src = colorfulTop
      }
    },
    [loading]
  )

  useEffect(
    () => {
      if (loaded) {
        containerRef.current.scrollIntoView(true)
      }
    },
    [loaded]
  )

  if (!loading && !loaded && !error && !response) return null
  return (
    <div className="grui -mt-19 pt-19" ref={containerRef}>
      <div
        css={[styles.aiContainer, error && styles.error]}
        className={className}
      >
        {loading &&
          aiType !== CREATE_REPLY_ACTION && (
            <div className="grui flex-center flex-col p-15">
              <StarsCircle width="32" height="32" />
              <div css={styles.loadingCopy}>
                AI {editOrGeneration} in progress<AnimatedEllipsis />
              </div>
              <EditorButton
                type="tertiary"
                size="small"
                onClick={handleStopGenerating}
                css={styles.tertiarySm}
              >
                Cancel
              </EditorButton>
            </div>
          )}
        {error && (
          <div className="grui flex-center flex-col p-10 text-center">
            <Negative width="32" height="32" />
            <div className="grui mt-5 mb-10" css={textStyle.styles.fontMedium}>
              Oh no, something went wrong!
            </div>
            <div className="errorMessage">{error.message}</div>
            <div className="grui mt-10">
              <EditorButton
                type="tertiary"
                size="small"
                onClick={handleOnTryAgain}
                css={styles.tertiaryBtn}
              >
                Try again
              </EditorButton>
              <button
                css={[button.styles.tertiaryLink, styles.btnLink]}
                onClick={handleStopGenerating}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {((loaded && response) ||
          (loading && aiType === CREATE_REPLY_ACTION)) && (
          <div>
            <div className="grui flex" css={[styles.header, styles.hasBg]}>
              <ThreeStars />
              <span className="grui ml-5">{app.t('AI_Draft')}</span>
            </div>
            <div
              css={styles.body}
              className={cn(
                'grui flex flex-col',
                !canShowConversationTypeView && 'pb-6'
              )}
            >
              {AI_COMPOSER_ACTIONS.includes(lastAiType) &&
                !canShowConversationTypeView && (
                  <SanitizedHTML
                    className="body"
                    html={dumbLinkify(response, { showFullUrl: true })}
                  />
                )}
              {canShowConversationTypeView && (
                <ResponseWithDocuments
                  response={conversationRequestState.response}
                >
                  <ComposerActionButtons
                    handleCopyToComposer={handleCopyToComposer}
                    handleRemoveAndClose={handleRemoveAndClose}
                    showAiEditButton={prefersAiEnabled}
                    className="grui mt-auto"
                    lastAiType={lastAiType}
                    globalTicketId={globalTicketId}
                    response={conversationRequestState.response}
                    rating={rating}
                  />
                </ResponseWithDocuments>
              )}
              {aiType === CREATE_REPLY_ACTION && <StreamingResponse />}
            </div>
            {canShowFooter && (
              <div css={styles.footer}>
                <ComposerActionButtons
                  isNote={isAddingNote}
                  handleCopyToComposer={updateEditorFromResponse}
                  handleRemoveAndClose={handleRemoveAndClose}
                  lastAiType={lastAiType}
                  globalTicketId={globalTicketId}
                  response={response}
                  rating={rating}
                />
                <Warning />
              </div>
            )}
          </div>
        )}
      </div>
      {canShowUsageCounter && <UsageCounter />}
    </div>
  )
}
export default MessageView
