import { useSelector } from 'react-redux'
import { selectCurrentChannelById } from 'ducks/channels/selectors'

const ChannelLabel = ({ channelId }) => {
  const channel = useSelector(state =>
    selectCurrentChannelById(state, channelId)
  )

  return channel?.name || null
}
export default ChannelLabel
