import React from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { fist as Fist, hands as Hands } from 'assets/icons/groove'
import avatar from 'assets/alex-small-alt.png'
import signature from 'assets/alex-signature.png'
import { styles } from './styles'

const Letter = ({ children }) => {
  return (
    <div css={styles.container}>
      <div css={styles.letter}>
        <img
          css={styles.avatar}
          width={80}
          height={80}
          src={avatar}
          alt="Alex Turnbull"
        />
        <Fist css={styles.fist} />
        <Hands css={styles.hands} />
        <div className={styles.content}>{children}</div>
        <div>
          <img
            src={signature}
            width={64}
            height={32}
            alt="Alex Turnbull"
            className="grui mb-4 mt-12 block"
          />
          <span>
            CEO & Founder, Groove and{' '}
            <a
              css={button.styles.link}
              href="https://helply.com"
              target="_blank"
              rel="noopener noreferrer"
              className="grui underline"
            >
              Helply
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Letter
