import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const item = theme => css`
  color: rgba(${theme.color.monochrome.white_rgba}, 0.8);
  transition: 0.2s color;
  padding: ${theme.spacing[3]};

  &.active,
  &:hover {
    color: ${theme.color.monochrome.white};
  }
`

const list = theme => css`
  gap: 42px;

  @media screen and (max-width: 1160px) {
    gap: ${theme.spacing[14]};
  }

  @media screen and (max-width: 900px) {
    gap: ${theme.spacing[8]};
  }
`

const upgrade = theme => css`
  color: ${theme.color.primary.warningText};
`

const dot = css`
  z-index: 1;
  top: 0;
  right: ${rem(-8)};
`

export const styles = {
  item,
  list,
  upgrade,
  dot,
}
