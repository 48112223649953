import { useEffect } from 'react'
import metrics from 'util/metrics'

const ListLoaded = ({ queryId, onListLoaded }) => {
  useEffect(
    () => {
      metrics.stopTimer(`search-load-${queryId}`)
      const timer = setTimeout(onListLoaded, 500)

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timer)
    },
    [queryId, onListLoaded]
  )

  return null
}

export default ListLoaded
