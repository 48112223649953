import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'core/subapps/Loading'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { selectShowContinueForwardingSetupPane } from 'selectors/layout'
import MailboxContinueForwardingSetup from 'components/App/DesktopView/Layout/MailboxContinueForwardingSetup'
import { selectIsRightSidebarCollapsed } from 'selectors/app'
import {
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { doToggleRightSidebar } from 'actions/app'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import { selectIsBootstrapped } from 'ducks/bootstrap/selectors/selectIsBootstrapped'
import ClassicView from './ClassicView'
import ModernView from './ModernView'

export default function LayoutSwitcher() {
  const dispatch = useDispatch()
  const ticketId = useSelector(selectCurrentTicketId)
  const isBootstrapped = useSelector(selectIsBootstrapped)
  const isNewConversation = useSelector(selectIsLoggingNewConversation)
  const isNewEmail = useSelector(selectIsCreatingNewEmail)
  const prefersClassicView = useSelector(selectPrefersClassicView)
  const isRightSidebarCollapsed = useSelector(selectIsRightSidebarCollapsed)
  const toggleRightSidebar = useCallback(
    () => {
      dispatch(doToggleRightSidebar())
    },
    [dispatch]
  )
  const isBulkSelectionMode = useSelector(selectIsTicketSelectionMode)

  const mailboxId = useSelector(selectCurrentMailboxId)
  const showContinueForwardingSetupPane = useSelector(
    selectShowContinueForwardingSetupPane
  )

  const isNewConvo = isNewConversation || isNewEmail

  useEffect(
    () => {
      if (prefersClassicView) {
        document.body.classList.toggle('classic-inbox-view', true)
        document.body.classList.toggle('modern-inbox-view', false)
      } else {
        document.body.classList.toggle('classic-inbox-view', false)
        document.body.classList.toggle('modern-inbox-view', true)
      }
    },
    [prefersClassicView]
  )

  if (!isBootstrapped) return <Loading />

  if (!isNewConvo) {
    if (showContinueForwardingSetupPane) {
      return <MailboxContinueForwardingSetup mailboxId={mailboxId} />
    }
  }

  const props = {
    isBootstrapped,
    isNewConversation,
    isNewEmail,
    prefersClassicView,
    isRightSidebarCollapsed,
    ticketId,
    isBulkSelectionMode,
    toggleRightSidebar,
  }

  if (prefersClassicView) {
    return <ClassicView {...props} />
  }

  return <ModernView {...props} />
}
