import React, { useMemo } from 'react'
import cn from 'classnames'
import WithGlobalNav from '@groovehq/internal-design-system/lib/components/GlobalNav/WithGlobalNav/WithGlobalNav'
import MailtoHandler from 'components/MailtoHandler'
import AppNotices from 'components/AppNotices'
import GlobalNav from 'components/App/DesktopView/Layout/GlobalNav'
import Loading from 'core/subapps/Loading'

import HotkeysModal from './HotkeysModal'
import KeyboardHandler from './KeyboardHandler'
import Leftnav from './Leftnav'
import SUIModalWrapper from './SUIModalWrapper'

import styles from './styles.less'
import TopNav from './TopNav'
import Inbox from './Inbox'
import EmptyPage from './EmptyPage'
import NewFeaturesAnnouncement from './NewFeaturesAnnouncement'

const Layout = React.memo(
  ({
    hasMailboxAccess,
    showEmptyInboxPage,
    isInboxBootstrapped,
    isBootstrapped,
    shouldHideAnnouncement,
  }) => {
    const inboxPage = useMemo(
      () => {
        if (!isInboxBootstrapped) return null
        return showEmptyInboxPage ? <EmptyPage /> : <Inbox />
      },
      [showEmptyInboxPage, isInboxBootstrapped]
    )

    if (!isBootstrapped) return <Loading />

    const showLeftNav =
      isInboxBootstrapped && hasMailboxAccess && !showEmptyInboxPage

    return (
      <div className={cn(styles.app, 'grui flex-col')}>
        <KeyboardHandler />
        <TopNav active="Conversations" />
        <div className={cn(styles.mainApp)}>
          <WithGlobalNav
            globalNav={<GlobalNav active="Inbox" />}
            topContent={<AppNotices />}
            leftSidebar={showLeftNav && <Leftnav />}
          >
            {inboxPage}
          </WithGlobalNav>
        </div>
        <HotkeysModal />
        <SUIModalWrapper />
        <MailtoHandler />
        {!shouldHideAnnouncement && <NewFeaturesAnnouncement />}
      </div>
    )
  }
)

export default Layout
