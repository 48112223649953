import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { useLink } from 'ducks/location/hooks'
import { queryIdToQuery } from 'ducks/searches/utils/query'
import Link from 'redux-first-router-link'
import { Label } from 'shared/components/ui/Typography'

const linkStyle = ({ option }) => theme => css`
  padding: ${theme.spacing['5']} ${theme.spacing['3']};
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: ${option.current
    ? theme.color.primary.brand
    : theme.color.monochrome.mediumDark} !important;
  box-shadow: inset 0 -3px ${option.current ? 'currentColor' : 'transparent'};

  &:hover {
    color: ${theme.color.primary.brand} !important;
  }
`

export default function Option({ option }) {
  const linkQuery = useMemo(
    () => queryIdToQuery(option.queryId, { parseNull: true }),
    [option.queryId]
  )
  const linkTo = useLink(
    { meta: { query: { ...linkQuery, orderBy: null } } },
    { preserveQuery: true, addDefaultOrderBy: true }
  )
  if (option.queryId.length < 1) return null
  return (
    <Link to={linkTo} css={linkStyle({ option })}>
      <Label size="small" color="inherit">
        {option.label}
      </Label>
    </Link>
  )
}
