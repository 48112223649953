import React from 'react'
import cn from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import TrashIcon from '@groovehq/internal-design-system/lib/assets/icons/Trash'
import FormatIcon from '@groovehq/internal-design-system/lib/assets/icons/Format'

// global
import { Flex } from 'shared/ui'
import { propFunc } from 'util/functions'
import { isCmdEnter } from 'util/keys'
import { ENTER, TAB } from 'constants/keys'
import { updateToWithRecipientName } from 'ducks/drafts2/util'

// re-used
import NavBar from 'components/App/DesktopView/shared/Composer/Email/Header/NavBar'
import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker'
import ReplyStateIndicator from 'components/ReplyStateIndicator'
import Attachments from 'components/Attachments'
import ExpandedHeader from 'components/App/DesktopView/shared/Composer/Header/Expanded'
import EditorDropzoneIndicator from 'components/App/DesktopView/shared/Composer/shared/EditorDropzoneIndicator'
import withDropzone from 'components/Dropzone'
import SearchKb from 'subapps/ticketing/components/TicketSearchKb'
import AssignmentBadge from 'components/App/DesktopView/shared/AssignmentDropdown/Badge'
import { useEditorInstance } from 'ducks/editor/hooks'

// styles
import styles from 'components/App/DesktopView/CommunicationForms/Reply/shared/styles.less'

// shared
import SendButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/SendButton'
import AttachmentButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AttachmentButton'
import { handleDrop } from 'components/App/DesktopView/CommunicationForms/shared/dragAndDrop'
import AssignmentDropdown from 'components/App/DesktopView/CommunicationForms/Reply/shared/AssignmentDropdown'
import MentionSelector from 'components/MentionSelector'
import AttachmentUpload from 'components/AttachmentUpload'
import AiButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AiButton'
import storage from 'util/storage'
import { CLOSED, OPENED, SNOOZED } from 'ducks/tickets/constants'

// local
import Editor from './EditorView'
import Sizer from '../../shared/Sizer'

const FlexWithDropzone = withDropzone(Flex, {
  indicatorComponent: EditorDropzoneIndicator,
  className: cn(styles.EditorDropzone, 'Dropzone'),
})

const FooterWithDropzone = withDropzone('div', {
  skipIndicator: true,
})

class LogConversationForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      kbDropdownVisible: false,
      word: null,
      formattingVisible: !!storage.get('showFormattingToolbar'),
    }
    this.footerRef = React.createRef()
    this.aiOverlayTopRef = React.createRef()
    this.aiOverlayWidthRef = React.createRef()
    this.aiOverlayBottomRef = React.createRef()
  }

  onEditorContentChange = (text, oldBody) => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props
    if (!draftId && !text) return

    debouncedHandleDraftChange(ticketId, 'body', text, {
      draftId,
      oldBody,
    })
  }

  onAgentChange = (_, groupId, agentId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeId', agentId, {
      draftId,
    })
  }

  onGroupChange = (_, groupId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeGroupId', groupId, {
      draftId,
    })
  }

  onSubjectChange = value => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props

    debouncedHandleDraftChange(ticketId, 'title', value, {
      draftId,
    })
  }

  onPhoneChange = value => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props

    debouncedHandleDraftChange(ticketId, 'phoneNumber', value, {
      draftId,
    })
  }

  onNameChange = value => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props
    const { to } = this.props

    debouncedHandleDraftChange(
      ticketId,
      'to',
      updateToWithRecipientName(to, value),
      {
        draftId,
      }
    )
  }

  onMailboxClick = mailboxId => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'mailboxId', mailboxId, {
      draftId,
    })
  }

  onSend = () => {
    const { sendDraft, draftId } = this.props
    sendDraft(draftId, this.state.ticketFollow)
  }

  onSendAndOpen = () => {
    const { handleDraftMultiChange, sendDraft, draftId, ticketId } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: OPENED, isForwarding: false },
      { draftId }
    )
    sendDraft(draftId)
  }

  onSendAndClose = () => {
    const { handleDraftMultiChange, sendDraft, draftId, ticketId } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: CLOSED, isForwarding: false },
      { draftId }
    )
    sendDraft(draftId)
  }

  onSendAndSnooze = snoozeUntil => {
    const { handleDraftMultiChange, sendDraft, draftId, ticketId } = this.props

    handleDraftMultiChange(
      ticketId,
      { state: SNOOZED, snoozeUntil, isForwarding: false },
      { draftId }
    )
    sendDraft(draftId)
  }

  onDeleteClick = () => {
    const { deleteDraft, ticketId, draftId } = this.props
    deleteDraft(ticketId, draftId)
  }

  onAttachmentClick = () => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  onUploadComplete = () => {
    const { handleDraftChange, ticketId, draftId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  afterAttachmentDelete = () => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  toggleFormatting = () => {
    const { formattingVisible } = this.state
    this.setState({
      formattingVisible: !formattingVisible,
    })
    storage.set('showFormattingToolbar', !formattingVisible)
  }

  handleDropOnEditor = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()
    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      handleDrop(accepted, updatedDraftId, uploadFiles)
    }, 100)
  }

  handleDropOnFooter = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()
    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      handleDrop(accepted, updatedDraftId, uploadFiles)
    }, 100)
  }

  handleKbDropdownVisible = visible =>
    this.setState({ kbDropdownVisible: visible })

  uploadAttachmentsFromEditorPaste = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()

    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      uploadFiles(accepted, false, updatedDraftId)
    }, 100)
  }

  startTyping = () => {
    const { ticketId, realtimeAgentStartTicketTypingNotification } = this.props
    realtimeAgentStartTicketTypingNotification(ticketId)
  }

  handleOnKeyDownEvent = e => {
    // do not re-do the whole mention UI, we're going to dispatch a keydown event
    // so that the Mentions dropdown will capture it and behave like it did before
    // it's needed because tinymce events happen within an iframe
    const event = document.createEvent('Event')
    const type = e.type

    // can bubble, and is cancellable
    event.initEvent(`editor-${type}`, true, true)
    event.forwarded = e
    document.dispatchEvent(event)

    if ((e.keyCode === TAB || e.keyCode === ENTER) && this.state.word) {
      const {
        doMentionAgent,
        selectedMentionSelectorAgentId,
        suppressMentionSelector,
        editorInstanceData: { editorInstance },
      } = this.props

      if (!suppressMentionSelector) {
        doMentionAgent(selectedMentionSelectorAgentId, editorInstance)
        e.preventDefault()
        e.stopPropagation()
      }
    }
    if (isCmdEnter(e)) {
      e.preventDefault()
      e.stopPropagation()
      return this.onSend()
    }
    this.startTyping()

    return true
  }

  handleInput = mention => {
    const { doUpdateAgentMention } = this.props
    const found = mention

    if (!found) {
      this.setState({ word: null })
      return this.closeMentionModalUnlessClosed()
    }

    const word = found.word
    this.setState({ word: found })

    if (word.charAt(0) === '@') return doUpdateAgentMention(found)

    return this.closeMentionModalUnlessClosed()
  }

  closeMentionModalUnlessClosed = () => {
    if (!this.props.isMentioning) return
    this.props.doUpdateAgentMention(null)
  }

  render() {
    const { kbDropdownVisible, formattingVisible } = this.state
    const {
      ticketId,
      draftId,
      mailboxId,
      subject,
      phone,
      to,
      isSendable,
      notSendableReason,
      onEditorFormFocus,
      onEditorFormBlur,
      onEditorInit,
      showKbSearch,
      assignmentLabel,
      assigneeGroupId,
      attachments,
      editorInstanceData: { editorWrapperRef: composerRef, editorInstance },
      isNewTicket,
      prefersAiEnabled,
    } = this.props
    return (
      <div
        id="composer"
        className={styles.form}
        data-test-id="log-conversation-editor-email"
        ref={composerRef}
      >
        <Sizer
          className={cn('log-conversation', { 'new-email': isNewTicket })}
          footerRef={this.footerRef}
        >
          <div
            className={cn(
              'form-header-wrapper',
              kbDropdownVisible && 'kbDropdownVisible'
            )}
          >
            <AnimatePresence initial={false}>
              {showKbSearch && (
                <motion.div
                  layout
                  initial={{ height: 0, overflow: 'hidden' }}
                  animate={{
                    height: 'auto',
                    transitionEnd: { overflow: 'visible' },
                  }}
                  exit={{ height: 0, overflow: 'hidden' }}
                  transition={{ ease: 'easeInOut', duration: 0.2 }}
                >
                  <SearchKb
                    className="grui mb-5 search-kb"
                    onFocus={propFunc(this.handleKbDropdownVisible, true)}
                    onBlur={propFunc(this.handleKbDropdownVisible, false)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <div className="form-header" ref={this.aiOverlayTopRef}>
              <div className="envelope-section sui sui-transparent">
                <div className="sui-inner sui-inner-transparent">
                  <ExpandedHeader
                    className="expanded-header"
                    recipientsTo={to}
                    name={to?.[0]?.name}
                    subject={subject}
                    phone={phone}
                    onClick={this.onClick}
                    onSubjectChange={this.onSubjectChange}
                    onPhoneChange={this.onPhoneChange}
                    onNameChange={this.onNameChange}
                    mailboxId={mailboxId}
                    ticketId={ticketId}
                    onMailboxClick={this.onMailboxClick}
                    recipientsOpenUpward
                    isNote
                  />
                </div>
              </div>
              <NavBar
                commenting
                ticketId={ticketId}
                className="extras-section"
              />
            </div>
          </div>
          <div className="form-editor-wrapper" ref={this.aiOverlayWidthRef}>
            <FlexWithDropzone
              className={cn('editorAndAttachments grui flex-col flex-2-1-auto')}
              onDropCustom={this.handleDropOnEditor}
            >
              <MentionSelector
                legacy={false}
                scrollParentRef={this.editorScrollElement}
              />
              <Editor
                id="email-editor"
                className="toolbar-bottom"
                ticketId={ticketId}
                draftId={draftId}
                onChange={this.onEditorContentChange}
                onKeyDown={this.handleOnKeyDownEvent}
                onMention={this.handleInput}
                onFocus={onEditorFormFocus}
                onBlur={onEditorFormBlur}
                onInit={onEditorInit}
                uploadAttachmentsFromEditorPaste={
                  this.uploadAttachmentsFromEditorPaste
                }
                focusWhenInitialized={!!to?.length}
              />

              <div className="attachments">
                <Attachments
                  forDraft
                  draftId={draftId}
                  ticketId={ticketId}
                  afterDelete={this.afterAttachmentDelete}
                />
              </div>
            </FlexWithDropzone>
          </div>
          <div className="form-footer-wrapper" ref={this.footerRef}>
            <FooterWithDropzone
              className="form-footer"
              onDropCustom={this.handleDropOnFooter}
            >
              <div
                className={cn({
                  'formatting-visible': formattingVisible,
                })}
              >
                <div id="email-editor-toolbar" />
              </div>
              <div
                className="form-footer-content"
                ref={this.aiOverlayBottomRef}
              >
                <div className="sendButtonContainer">
                  <SendButton
                    className="note-button"
                    actionLabel="Add note"
                    disabled={!isSendable}
                    ticketId={ticketId}
                    draftId={draftId}
                    isNote
                    isOpen
                    onSendClick={this.onSend}
                    onSendOpenClick={this.onSendAndOpen}
                    onSendClosedClick={this.onSendAndClose}
                    onSnoozeOptionClick={this.onSendAndSnooze}
                  />
                  <ReplyStateIndicator
                    className={'collisionIndicator'}
                    hideDraftIndicator
                    conversationId={ticketId}
                  />
                </div>
                {prefersAiEnabled && <AiButton />}
                <div className="grui flex flex-grow">
                  <div className="left-side">
                    {!isSendable &&
                      notSendableReason && (
                        <span className="not-sendable-reason large">
                          {notSendableReason}
                        </span>
                      )}

                    <div className="actions">
                      <Tooltip title="Toggle formatting" position="top">
                        <Button
                          type="icon"
                          size="small"
                          onClick={this.toggleFormatting}
                        >
                          <FormatIcon />
                        </Button>
                      </Tooltip>
                      <div className="attachments">
                        <AttachmentButton
                          triggerId="attachmentUploadTrigger"
                          attachments={attachments}
                          onClick={this.onAttachmentClick}
                          onUploadComplete={this.onUploadComplete}
                        >
                          <AttachmentUpload
                            hideIcon
                            triggerId="attachmentUploadTrigger"
                            draftId={draftId}
                            onUploadComplete={this.onUploadComplete}
                          />
                        </AttachmentButton>
                      </div>
                      <EmojiPicker editor={editorInstance} />
                      <AssignmentDropdown
                        direction="left"
                        drilledDownGroupId={assigneeGroupId}
                        key="assignment"
                        onGroupSelect={this.onGroupChange}
                        onAgentSelect={this.onAgentChange}
                        trigger={
                          <div className="left-divider">
                            <AssignmentBadge
                              assignmentLabel={assignmentLabel}
                              size="small"
                              iconSize={24}
                              tooltipPosition="top"
                            />
                          </div>
                        }
                        upward
                      />
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="actions">
                      <Tooltip
                        title="Delete note"
                        position="top"
                        strategy="fixed"
                      >
                        <Button
                          type="icon"
                          size="small"
                          onClick={this.onDeleteClick}
                          className="deleteButton"
                          data-test-id="DraftDeleteButton"
                        >
                          <TrashIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </FooterWithDropzone>
          </div>
          {!isSendable &&
            notSendableReason && (
              <span className="not-sendable-reason small">
                {notSendableReason}
              </span>
            )}
        </Sizer>
      </div>
    )
  }
}

const withHookHOC = Component => {
  return props => {
    const editorInstanceData = useEditorInstance()

    return <Component editorInstanceData={editorInstanceData} {...props} />
  }
}

export default withHookHOC(LogConversationForm)
