import { Fragment, useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useForm, useController } from 'react-hook-form'
import doCancelOrder from 'ducks/integrations/shopify/operations/doCancelOrder'
import doCalculateCancelOrderRefund from 'ducks/integrations/shopify/operations/doCalculateCancelOrderRefund'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { capture } from 'ducks/tracking/actions'
import { useShopifyOrder } from 'ducks/integrations/shopify/hooks'
import { selectRequestByType } from 'ducks/requests/selectors'
import { ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST } from 'ducks/integrations/shopify/types'
import { shopifyMoneyString } from 'util/currency'

import RefundTypeSelection from './RefundTypeSelection'
import CancelReasonDropdown from './CancelReasonDropdown'
import TransactionRow from './TransactionRow'
import {
  FORM_KEY_RESTOCK,
  FORM_KEY_SEND_NOTIFICATION,
  FORM_KEY_SELECTED_REASON,
  FORM_KEY_TYPE,
  FORM_KEY_MAXIMUM_REFUNDABLE_SET,
  FORM_KEY_SUGGESTED_REFUND_TRANSACTIONS,
  FORM_KEY_VOIDABLE_TRANSACTIONS,
} from './constants'
import { styles } from './styles'

const ShopifyCancelOrderModal = ({
  onClose,
  onExit,
  open = false,
  drawerIntegrationId,
  drawerResourceId: orderId,
}) => {
  const [isSending, setSending] = useState(false)
  const dispatch = useDispatch()

  const { order, integration } = useShopifyOrder({
    integrationId: drawerIntegrationId,
    orderId,
  })

  const { refundable, restockable, isCancellable, displayFinancialStatus } =
    order || {}

  const conversationId = useSelector(selectCurrentTicketId)
  const {
    loading: suggestedRefundLoading,
    error: suggestedRefundError,
  } = useSelector(state =>
    selectRequestByType(state, ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST)
  )

  const { handleSubmit, control, register } = useForm({
    mode: 'all',
    defaultValues: {
      [FORM_KEY_RESTOCK]: true,
      [FORM_KEY_SEND_NOTIFICATION]: true,
      [FORM_KEY_SELECTED_REASON]: 'CUSTOMER',
      [FORM_KEY_TYPE]: 'refund-now',
      [FORM_KEY_MAXIMUM_REFUNDABLE_SET]: null,
      [FORM_KEY_SUGGESTED_REFUND_TRANSACTIONS]: [],
      [FORM_KEY_VOIDABLE_TRANSACTIONS]: [],
    },
  })

  const {
    field: { onChange: onMaximumRefundableSetChange },
  } = useController({
    name: FORM_KEY_MAXIMUM_REFUNDABLE_SET,
    control,
  })

  const {
    field: { onChange: onSuggestedRefundTransactionsChange },
  } = useController({
    name: FORM_KEY_SUGGESTED_REFUND_TRANSACTIONS,
    control,
  })

  const {
    field: {
      value: voidableTransactions,
      onChange: onVoidableTransactionsChange,
    },
  } = useController({
    name: FORM_KEY_VOIDABLE_TRANSACTIONS,
    control,
  })

  useEffect(
    () => {
      if (!order?.id) return
      dispatch(
        doCalculateCancelOrderRefund(drawerIntegrationId, order?.id)
      ).then(data => {
        const {
          suggestedRefund: {
            maximumRefundableSet,
            suggestedTransactions = [],
          } = {},
          transactions = [],
        } =
          data?.order || {}

        onMaximumRefundableSetChange(maximumRefundableSet?.shopMoney)
        onSuggestedRefundTransactionsChange(suggestedTransactions)

        let voidable = []

        if (displayFinancialStatus === 'PENDING') {
          // since shopify does not expose transactions[].voidable publicly, we'll try and calculate it ourselves
          // it appears it's all order transactions that are not included in suggestedRefund transactions + payment pending
          const refundableTransactionIds = suggestedTransactions
            .map(tr => tr?.parentTransaction?.id)
            .filter(Boolean)

          voidable = transactions.filter(
            tr => !refundableTransactionIds.includes(tr.id)
          )
        }

        onVoidableTransactionsChange(voidable)
      })
    },
    [
      dispatch,
      drawerIntegrationId,
      order?.id,
      onMaximumRefundableSetChange,
      onSuggestedRefundTransactionsChange,
      onVoidableTransactionsChange,
      displayFinancialStatus,
    ]
  )

  const isVoidable =
    displayFinancialStatus === 'PENDING' && voidableTransactions.length > 0

  const onSubmit = useCallback(
    async data => {
      const payload = {
        notifyCustomer: data[FORM_KEY_SEND_NOTIFICATION],
        reason: data[FORM_KEY_SELECTED_REASON],
        refund: refundable && data[FORM_KEY_TYPE] === 'refund-now',
        restock: restockable ? data[FORM_KEY_RESTOCK] : false,
      }

      setSending(true)
      await dispatch(
        doCancelOrder(integration.id, orderId, conversationId, payload)
      )
      capture('Shopify Order Canceled')
      setSending(false)
      if (onClose) onClose()
    },
    [
      integration,
      orderId,
      dispatch,
      onClose,
      setSending,
      conversationId,
      refundable,
      restockable,
    ]
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  const isLoading = suggestedRefundLoading || !order?.id

  const footer = useMemo(
    () => {
      return (
        <ModalBtns
          warningBtnText={isSending ? 'Cancelling...' : 'Cancel order'}
          warningBtnHtmlType="submit"
          warningBtnDisabled={isSending || isLoading}
          tertiaryBtnText="Keep order"
          onClickTertiaryBtn={onClose}
        />
      )
    },
    [onClose, isSending, isLoading]
  )

  if (!isCancellable) {
    onClose()
  }

  return (
    <Drawer
      title="Cancel order"
      footer={footer}
      onClose={onExit}
      open={open}
      css={text.styles.textDark}
      container={DrawerForm}
      isLoading={isLoading}
      isError={!!suggestedRefundError}
    >
      <div className="grui pt-10" css={styles.content}>
        {isVoidable && (
          <div>
            <div>
              <div css={fieldStyles.labelBox}>Void transaction</div>
            </div>
            {voidableTransactions.map(transaction => {
              const {
                id,
                paymentIcon,
                formattedGateway,
                accountNumber,
                amountSet: { shopMoney } = {},
              } = transaction

              return (
                <Fragment key={id}>
                  <TransactionRow
                    paymentIcon={paymentIcon}
                    formattedGateway={formattedGateway}
                    accountNumber={accountNumber}
                  />
                  <div css={text.styles.textMediumDark} className="grui pl-13">
                    {`${shopifyMoneyString(shopMoney)} was authorized`}
                  </div>
                </Fragment>
              )
            })}
          </div>
        )}

        {refundable && (
          <div>
            <div css={fieldStyles.labelBox}>Refund payment</div>
            <RefundTypeSelection control={control} />
          </div>
        )}
        {restockable && (
          <div>
            <div css={fieldStyles.labelBox}>Inventory</div>
            <Checkbox id="restockItems" {...register(FORM_KEY_RESTOCK)}>
              Restock items
            </Checkbox>
          </div>
        )}
        <div>
          <div
            css={fieldStyles.labelBox}
            className={cn({ 'grui mt-12': refundable || restockable })}
          >
            Reason for cancelation
          </div>
          <div css={styles.dropdownContainer}>
            <CancelReasonDropdown
              control={control}
              name={FORM_KEY_SELECTED_REASON}
            />
          </div>
        </div>
        <div>
          <div css={fieldStyles.labelBox}>Notification</div>
          <Checkbox
            id="sendNotification"
            {...register(FORM_KEY_SEND_NOTIFICATION)}
          >
            Send a notification to the customer
          </Checkbox>
        </div>
      </div>
    </Drawer>
  )
}

export default ShopifyCancelOrderModal
