import { useMemo } from 'react'
import { getInitialsFromName } from 'util/actors'
import { isAdminRole } from 'util/agents'
import { emptyObj } from 'util/objects'
import AgentAvatar from './AgentAvatar'

export default function Avatar({ id, role, name, avatarUrl }) {
  const initials = useMemo(() => getInitialsFromName(name), [name])

  // if possible, use the avatar provided, otherwise, use the store
  if (isAdminRole(role) && !avatarUrl) {
    return <AgentAvatar agentId={id} />
  }
  const styles = avatarUrl ? { backgroundImage: `url(${avatarUrl})` } : emptyObj
  return (
    <div className="avatar" style={styles}>
      {!avatarUrl && initials}
    </div>
  )
}
