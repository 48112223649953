import { useMemo } from 'react'
import { css } from '@emotion/react'
import cn from 'classnames'
import Link from 'redux-first-router-link'
import { styles as buttonStyles } from '@groovehq/internal-design-system/lib/styles/elements/Button/button.styles'
import { toInboxesPage } from 'subapps/settings/actions/redirect'
import { getRawId } from 'util/globalId'
import Label from '../Label'
import Row from '../IndentedRow'
import Dropdown from './Dropdown'

const flexStyle = css`
  flex: 1 1 100%;

  & > * {
    align-items: baseline;
  }
`

const FromRow = ({
  className,
  isLogging,
  mailboxId,
  mailboxes,
  onMailboxClick,
  children,
  isNewTicket,
}) => {
  const mailboxesForFrom = useMemo(
    () => {
      return isNewTicket
        ? mailboxes.filter(
            m => !['disconnected', 'unconfirmed'].includes(m.state)
          )
        : mailboxes
    },
    [isNewTicket, mailboxes]
  )
  const currentMailbox = useMemo(
    () => {
      return (
        mailboxesForFrom.find(m => getRawId(m.id) === getRawId(mailboxId)) ||
        mailboxesForFrom.first
      )
    },
    [mailboxesForFrom, mailboxId]
  )

  return (
    <div className={cn('grui flex', className)}>
      {children}
      <Row css={flexStyle}>
        <Label>
          {isLogging ? app.t('Mailbox') : 'From'}
          :
        </Label>
        {mailboxesForFrom.length === 0 ? (
          <Link
            className="grui ml-3"
            as="button"
            type="button"
            css={buttonStyles.link}
            to={toInboxesPage}
            shouldDispatch
          >
            Please add {app.t('mailbox_with_article')} first
          </Link>
        ) : (
          <Dropdown
            mailbox={currentMailbox}
            mailboxes={mailboxesForFrom}
            onClick={onMailboxClick}
            className="grui ml-3"
          />
        )}
      </Row>
    </div>
  )
}

export default FromRow
