import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import CollapsableCard from 'components/CollapsableCard'
import SortableVisibleControlsWithTitle from '../../../SortableVisibleControlsWithTitle'
import ConversationAttachments from './ConversationAttachments'

export default function Attachments({ doHideCard }) {
  const [currentVisible, setCurrentVisible] = useState(false)

  const ticketId = useSelector(selectCurrentTicketId)

  const onToggleCurrentVisible = useCallback(() => {
    setCurrentVisible(true)
  }, [])

  return (
    <CollapsableCard
      persistenceKey={'customWidget.attachments'}
      title={
        <SortableVisibleControlsWithTitle
          title="Attachments"
          onHide={doHideCard}
        />
      }
      hidePadding
    >
      <ConversationAttachments
        ticketId={ticketId}
        allVisible={currentVisible}
        onToggleAllVisible={onToggleCurrentVisible}
        emptyText="No attachments in this conversation."
      />
    </CollapsableCard>
  )
}
