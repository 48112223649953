import React from 'react'
import LoginLayoutV2 from 'components/LoginLayoutV2'

import LoginForm from './Form'
import { styles } from './styles'

export default function LoginPage() {
  return (
    <div className="grui flex" css={styles.loginPage}>
      <LoginLayoutV2>
        <LoginForm />
      </LoginLayoutV2>
    </div>
  )
}
