import React from 'react'
import cn from 'classnames'

import PanelGroup from 'components/PaneGroup'
import RightSidebar from 'components/App/DesktopView/Layout/RightSidebar'
import MainCell from 'components/App/DesktopView/Layout/MainCell'
import withPanelWidthManager from 'components/App/DesktopView/Layout/withPanelWidthManager'
import TicketList from 'subapps/ticketing/components/TicketList'
import { emptyObj } from 'util/objects'

import RightSidebarToggler from '../../RightSidebar/Toggler'
import useRightSidebar from '../../RightSidebar/useRightSidebar'

import styles from '../styles.css'
import { styles as emotionStyles } from './styles'

import { DEFAULT_WIDTHS, DEFAULT_BREAKPOINTS, STORAGE_KEY } from './defaults'

class ClassicView extends React.PureComponent {
  // Length has to match panes count
  componentWrappers = [
    null,
    React.forwardRef((props, ref) => {
      const { isRightSidebarCollapsed } = this.props
      const { onTransitionEnd, finalStyle } = useRightSidebar({
        isRightSidebarCollapsed,
        style: props.style,
      })
      return (
        <div
          {...props}
          ref={ref}
          onTransitionEnd={onTransitionEnd}
          style={finalStyle}
          className={cn(
            props.className,
            styles.rightSidebarWrapper,
            'hidden-in-print'
          )}
        />
      )
    }),
  ]

  render() {
    const {
      isNewConversation,
      isNewEmail,
      ticketId,
      panelWidths = [],
      panelUpdateCallback,
    } = this.props

    const shouldDisplayList = !isNewEmail && !isNewConversation && !ticketId

    if (shouldDisplayList) {
      return <TicketList channelType="mailbox" />
    }

    return (
      <React.Fragment>
        <RightSidebarToggler />
        <PanelGroup
          css={emotionStyles.paneGroup}
          spacing={0}
          panelWidths={panelWidths}
          onUpdate={panelUpdateCallback}
          ComponentWrappers={this.componentWrappers}
          breakpoints={DEFAULT_BREAKPOINTS}
          className="three-column-view"
        >
          <MainCell />
          <RightSidebar panelWidth={(panelWidths[2] || emptyObj).size} />
        </PanelGroup>
      </React.Fragment>
    )
  }
}

export default withPanelWidthManager(DEFAULT_WIDTHS, STORAGE_KEY)(ClassicView)
