import React from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { Question } from '@groovehq/internal-design-system/lib/assets/icons'
import { styles } from './styles'

const variants = {
  v1: (
    <span
      css={styles.questionIcon}
      className="grui whitespace-nowrap inline-block mt-1"
    >
      {/* Make sure the icon wrap with children */}
      &nbsp;
    </span>
  ),
  v2: <Question />,
}

const QuestionTooltip = ({ tooltip, className, variant = 'v1' }) => {
  return (
    <span className={className}>
      <Tooltip
        title={tooltip}
        position="top"
        strategy="absolute"
        portal="#overlays"
      >
        {variants[variant]}
      </Tooltip>
    </span>
  )
}

export default QuestionTooltip
